import React, { FC, useState, Fragment, useEffect, useContext, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import ActionSnackbar from 'components/ActionSnackBar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles, Theme, Paper } from '@material-ui/core';
import { COMMISSION_SERVICE_BASE_URL } from 'constants/url';

import CommissionTable from './components/CommissionTable';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    margin: 'auto'
  }
}));

interface Props {
  employee: EmployeeModel;
}

const CommissionContent: FC<Props> = props => {
  useCurrentPageTitleUpdater('Pengaturan Komisi');
  const { employee } = props;
  const classes = useStyles();

  const [categories, setCategories] = useState<Select[]>([]);
  const [isSearchingCommission, setSearchingCommission] = useState<boolean>(false);
  const [commissions, setCommissions] = useState<CommissionModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const [filterBy, setFilterBy] = useState<string>('');
  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);
  const [openEditCommission, setOpenEditCommission] = useState<boolean>(false);
  const [currentEditingCommissionIndex, setCurrentEditingCommissionIndex] = useState<number>(0);
  const [currentEditingMultipleCommissionIndex, setCurrentEditingMultipleCommissionIndex] = useState<number[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [isSearchCommissionError, setSearchCommissionError] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [checked, setChecked] = useState<number[]>([]);
  const { currentPageTitle } = useContext(CurrentPageContext);
 
  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleCancelCreateCommission = () => {
    
  };

  const addNewCommission = (commission: CommissionModel) => {
    commission.new = true;
    commissions.unshift(commission);
    setCommissions([...commissions]);
    setCount(c => c + 1);
  };

  const deleteIndividualCommission = (commissionIndex: number) => {
    commissions.splice(commissionIndex, 1);
    setCommissions([...commissions]);
    setCount(c => c - 1);
  };

  const handleOpenEditCommission = (commissionIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingCommissionIndex(commissionIndex);
    setOpenEditCommission(true);
  };

  const handleCancelEditCommission = () => {
    setOpenEditCommission(false);
  };

  const updateIndividualCommission = (updatedCommissionProperties: Partial<CommissionModel>, commissionIndex?: number) => {
    let currentEditingIndex: number;
    if (commissionIndex === undefined) {
      currentEditingIndex = currentEditingCommissionIndex;
    } else {
      currentEditingIndex = commissionIndex;
    }
    setCommissions(
      commissions!.map((commission, index) => {
        if (index !== currentEditingIndex) {
          return commission;
        }

        return Object.assign({}, commission, updatedCommissionProperties);
      })
    );
  };

  const updateMultipleCommission = (updatedCommissionProperties: Partial<CommissionModel[]>, commissionIndex?: number[]) => {
    let currentEditingIndex: number[];
    if (commissionIndex === undefined) {
      currentEditingIndex = currentEditingMultipleCommissionIndex;
    } else {
      currentEditingIndex = commissionIndex;
    }
    let count = 0;
    setCommissions(
      commissions!.map((commission, index) => {
        if (!currentEditingIndex.includes(commission.id)) {
          return commission;
        }
        count++;
        return Object.assign({}, commission, updatedCommissionProperties[count-1]);
      })
    );
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const handleOpenCreateCommission = () => {
    setOpenEditCommission(false);
  }

  // Search Commission whenever rowsPerPage, currentPage, queryString, contract, and filterby changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      if (filterBy) {
        params.append('fb', filterBy.toString());
      }

      if (columnFilter.length !== 0) {
        columnFilter.map(value => {
          return params.append('ei', value.columnValue.toString());
        });
      }

      params.append('ei', employee.id.toString());
      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchCommission = async () => {
      setSearchingCommission(true);
      setSearchCommissionError(false);

      try {
        const url = `${COMMISSION_SERVICE_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        
        setCount(data.count);
        setCommissions(data.Commissions);
      } catch (err) {
        setSearchCommissionError(true);
      }

      setSearchingCommission(false);
      setChecked([]);
      setDelete(false);
    };

    searchCommission();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString, filterBy, columnFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Paper className={classes.paper}>
      <CommissionTable
        isLoadingData={isSearchingCommission}
        employee={employee}
        commissions={commissions}
        count={count}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        query={query}
        setQuery={setQuery}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        setColumnFilter={setColumnFilter}
        columnFilter={columnFilter}
        handleCancelCreateCommission={handleCancelCreateCommission}
        addNewCommission={addNewCommission}
        deleteIndividualCommission={deleteIndividualCommission}
        openEditCommission={openEditCommission}
        commission={commissions[currentEditingCommissionIndex]}
        currentEditingCommissionIndex={currentEditingCommissionIndex}
        handleOpenEditCommission={handleOpenEditCommission}
        setCurrentEditingMultipleCommissionIndex={setCurrentEditingMultipleCommissionIndex}
        handleCancelEditCommission={handleCancelEditCommission}
        updateIndividualCommission={updateIndividualCommission}
        
        updateMultipleCommission={updateMultipleCommission}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
        handleOpenCreateCommission={handleOpenCreateCommission}
        checked={checked}
        setChecked={setChecked}
        setDelete={setDelete}
        
      />
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
    </Paper>
  );
};

export default CommissionContent;
