import React, { FC, Fragment, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import NumberFormat from 'react-number-format';
import axios, { CancelTokenSource } from 'axios';
import Skeleton from 'react-loading-skeleton';
import { Button, Tooltip, IconButton, makeStyles, TableRow, Theme, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import { green } from '@material-ui/core/colors';
import { StandardConfirmationDialog } from 'components/AppDialog';
import BodyCell from 'components/BodyCell';
import { format } from 'date-fns';
import Locale from 'date-fns/esm/locale/id';
import CalculatePayrollModal from './CalculatePayrollModal';
import { GET_DETAIL_PAYROLL_BY_ID } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  payroll: PayrollModel;
  employee: EmployeeModel;
  addNewPayroll(payroll: PayrollModel[]): void;
  updatePayroll: (updatedPayrollProperties: Partial<PayrollModel>, payrollIndex?: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageError: (message: string) => void;
  index: number;
  deleteIndividualPayroll: (payrollIndex: number) => void;
  onEditPayroll: React.MouseEventHandler;
  handleSetMessageSuccess: (message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 64
  },
  tableRowChecked: {
    height: 64,
    backgroundColor: '#F4F4F4'
  },
  actionIcon: {
    fontSize: 20
  },
  newIcon: {
    color: green[500],
    fontSize: 30,
    marginTop: theme.spacing(0.8)
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderRadius: 7
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  editIconForEmptyInvoice: {
    marginRight: theme.spacing(5.5)
  },
  wrapper: {
    position: 'relative'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  icon: {
    fontSize: 20
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column'
  },
  menuAvatar: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px',
    color: '#707070'
  },
  menuList: {
    minHeight: 30
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { isLoadingData, onEditPayroll, updatePayroll, payroll, setOpenSnackbar, setSnackbarVarient, employee, addNewPayroll } = props;

  const {
    id,
    idPayroll,
    monthYear,
    totalCommission,
    totalCommissionTemp,
    totalDeduction,
    totalDeductionTemp,
    basicSalary,
    netSalary,
    basicSalaryTemp,
    isMonthEnd,
    notes,
    noteReCalculation
  } = payroll;

  const dummyPayroll: PayrollModel = {
    id: 0,
    idPayroll: '',
    monthYear: '',
    totalCommission: 0,
    totalCommissionTemp: 0,
    totalDeduction: 0,
    totalDeductionTemp: 0,
    basicSalary: 0,
    basicSalaryTemp: 0,
    netSalary: 0,
    netSalaryTemp: 0,
    isMonthEnd: false,
    allowancePosition: 0,
    allowanceTransport: 0,
    allowanceCraft: 0,
    allowanceMealt: 0,
    allowanceHealth: 0,
    allowanceHoliday: 0,
    reCalculation: 0,
    noteReCalculation: '',
    notes: '',
    createdAt: new Date(),
    updatedAt: new Date()
  };
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openPrintPayslip, setOpenPrintPayslip] = useState<boolean>(false);
  const [reCalculation, setReCalculation] = useState<boolean>(false);
  const [buttonPrintPayslip, setButtonPrintPayslip] = useState<boolean>(false);
  const [openCalculationPayrollModal, setOpenCalculationPayrollModal] = useState<boolean>(false);
  const [oldPayroll, setOldPayroll] = useState<PayrollModel>(dummyPayroll);
  const [historyTransactions, setHistoryTransactions] = useState<HistoryTransactionModel[]>([]);
  const [employees, setEmployees] = useState<EmployeeModel[]>([]);
  const [oldHistoryTransactions, setOldHistoryTransactions] = useState<HistoryTransactionModel[]>([]);

  const handleOpenCalculationPayroll = () => {
    setOpenCalculationPayrollModal(true);
    setOpenPrintPayslip(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenPayslipPayroll = async (id: number) => {
    setOpenCalculationPayrollModal(true);
    setOpenPrintPayslip(true);
    setButtonPrintPayslip(true);
    try {
      const cancelTokenSource = axios.CancelToken.source();

      const url = `${GET_DETAIL_PAYROLL_BY_ID(id)}`;
      const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

      let payroll: PayrollModel = data.payroll;
      let transactionsData: HistoryTransactionModel[] = data.transactions;
      setOldPayroll(payroll);
      setOldHistoryTransactions(transactionsData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancelCalculationPayroll = () => {
    setOpenCalculationPayrollModal(false);
    setOpenPrintPayslip(false);
  };

  const recalculation: React.MouseEventHandler<HTMLButtonElement> = async event => {
    handleCloseDialog();
    setOpenCalculationPayrollModal(true);
    setReCalculation(true);
    onEditPayroll(event);
  };

  return (
    <Fragment>
      <TableRow className={classes.tableRow}>
        <BodyCell cellWidth='10%' pL='5px'>
          {isLoadingData ? <Skeleton width={80} /> : idPayroll}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            format(new Date(monthYear === undefined ? new Date() : monthYear), 'MMMM yyyy', { locale: Locale })
          )}
        </BodyCell>

        <BodyCell cellWidth={'10%'} pL='10px' pR='10px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <NumberFormat value={!isMonthEnd ? basicSalaryTemp : basicSalary} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
          )}
        </BodyCell>

        <BodyCell cellWidth='10%' pR='5px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <NumberFormat value={!isMonthEnd ? totalCommissionTemp : totalCommission} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
          )}
        </BodyCell>

        <BodyCell cellWidth={'11%'} pL='10px' pR='10px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <NumberFormat value={totalDeduction} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
          )}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <NumberFormat
              value={!isMonthEnd ? basicSalaryTemp + totalCommissionTemp - totalDeductionTemp : netSalary}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'Rp'}
            />
          )}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : notes}
        </BodyCell>

        <BodyCell cellWidth='25%' pL='10px' isComponent={true}>
          {isLoadingData ? null : !isMonthEnd ? (
            <Fragment>
              <Tooltip title={'Kalkulasi Gaji'} placement='top'>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => {
                    handleOpenCalculationPayroll();
                  }}
                >
                  Kalkulasi
                </Button>
              </Tooltip>
            </Fragment>
          ) : (
            <Fragment>
              <Tooltip title={'Print'} placement='top'>
                <IconButton size='small'>
                  <PrintIcon
                    className={classes.actionIcon}
                    onClick={() => {
                      handleOpenPayslipPayroll(id);
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Kalkulasi Ulang'} placement='top'>
                <IconButton size='small' onClick={event => setOpenDialog(true)}>
                  <EditIcon className={classes.actionIcon}  />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>
      <StandardConfirmationDialog
        variant={'warningCalculation'}
        message={'Anda yakin mau kalkulasi ulang?'}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={recalculation}
      />
      <CalculatePayrollModal
        open={openCalculationPayrollModal}
        handleCancel={handleCancelCalculationPayroll}
        addNewPayroll={addNewPayroll}
        payroll={payroll}
        setOpenSnackbar={setOpenSnackbar}
        employee={employee}
        setSnackbarVarient={setSnackbarVarient}
        openPrintPayslip={openPrintPayslip}
        setOpenPrintPayslip={setOpenPrintPayslip}
        oldPayroll={oldPayroll}
        setOldPayroll={setOldPayroll}
        historyTransactions={historyTransactions}
        setHistoryTransactions={setHistoryTransactions}
        employees={employees}
        setEmployees={setEmployees}
        buttonPrintPayslip={buttonPrintPayslip}
        setButtonPrintPayslip={setButtonPrintPayslip}
        oldHistoryTransactions={oldHistoryTransactions}
        reCalculation={reCalculation}
        updatePayroll={updatePayroll}
      />
    </Fragment>
  );
};

export default BodyRow;
