import 'date-fns';
import React from 'react';
import { Button, makeStyles, MenuItem, TableRow, TextField, Theme, Tooltip, Switch, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import NumberFormat from 'react-number-format';
import BodyCell from 'components/BodyCell';
import clsx from 'clsx';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  categories: CategoriesModel[];
  CategoryId: number;
  name: string;
  sellingPrice: number;
  barcode: string;
  notes: string;

  setCategoryId: React.Dispatch<React.SetStateAction<number>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setSellingPrice: React.Dispatch<React.SetStateAction<number>>;
  setBarcode: React.Dispatch<React.SetStateAction<string>>;
  setNotes: React.Dispatch<React.SetStateAction<string>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;

  primaryButtonLabel: string;
  customBackground?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  serviceItemTemplateForm: {
    height: 64
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: props.customBackground
  }),
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  buttonContainer: {
    display: 'flex'
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}));

const CreateEditProductServiceForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { CategoryId, setCategoryId } = props;
  const { name, setName } = props;
  const { sellingPrice, setSellingPrice } = props;
  const { barcode, setBarcode } = props;
  const { notes, setNotes } = props;

  const { categories, primaryButtonLabel, customBackground, isSubmitting, onSubmit, onCancel } = props;

  return (
    <TableRow className={classes.serviceItemTemplateForm}>
        <BodyCell cellWidth='5%' pL='10px' pR='10px' isComponent={true}></BodyCell>
      <BodyCell cellWidth='20%' pL='10px' pR='10px' isComponent={true}>
        <TextField
          select
          margin='dense'
          fullWidth
          className={clsx({ [classes.textFieldRoot]: customBackground })}
          id='CategoryId'
          label='Kategori'
          value={CategoryId}
          onChange={event => setCategoryId(+event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont,
            shrink: CategoryId === 0 ? false : true
          }}
        >

          {categories.map(category => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
      </BodyCell>
      <BodyCell cellWidth='15%' pL='10px' pR='10px' isComponent={true}>
        <TextField
          margin='dense'
          fullWidth
          className={clsx({ [classes.textFieldRoot]: customBackground })}
          id='name'
          label='Nama Produk'
          value={name}
          onChange={event => setName(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
          multiline
          rowsMax='4'
        />
      </BodyCell>
      <BodyCell cellWidth='15%' pL='10px' pR='10px' isComponent={true}>
        <TextField
          margin='dense'
          fullWidth
          className={clsx({ [classes.textFieldRoot]: customBackground })}
          id='barcode'
          label='Barcode'
          value={barcode}
          onChange={event => setBarcode(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
          multiline
          rowsMax='4'
        />
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <TextField
            margin='dense'
            fullWidth
            className={clsx({ [classes.textFieldRoot]: customBackground })}
            id='sellingPrice'
            label='Selling Price'
            value={sellingPrice === 0 ? null : sellingPrice}
            onChange={event => setSellingPrice(+event.target.value)}
            variant='outlined'
            autoComplete='off'
            InputProps={{
                inputComponent: NumberFormatCustom as any ,
                inputProps: {
                   thousandSeparator: true,
                   prefix: 'Rp'
               }
            }}
          />
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <TextField
            margin='dense'
            fullWidth
            className={clsx({ [classes.textFieldRoot]: customBackground })}
            id='notes'
            label='Catatan'
            value={notes}
            onChange={event => setNotes(event.target.value)}
            variant='outlined'
            autoComplete='off'
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont
            }}
            multiline
            rowsMax='4'
          />
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <div className={classes.buttonContainer}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={onSubmit} variant='contained' color='secondary' disabled={isSubmitting}>
            {primaryButtonLabel}
          </Button>
        </div>
      </BodyCell>
    </TableRow>
  );
};

export default CreateEditProductServiceForm;
