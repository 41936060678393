import React, { FC } from 'react';
import clsx from 'clsx';
import { Button, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';

interface Props {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  count: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    color: '#0B3469',
    fontSize: 13,
    width: 30,
    height: 30,
    padding: theme.spacing(1)
  },
  activePage: {
    backgroundColor: '#E3E3E3'
  }
}));

const PaginationSection: FC<Props> = props => {
  const classes = useStyles();
  const { count } = props;
  const { currentPage, setCurrentPage } = props;
  const { rowsPerPage, setRowsPerPage } = props;
  const totalPage = Math.ceil(count / rowsPerPage);

  const handleChangePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    setRowsPerPage(rowsPerPage);
  };

  const renderPagination = () => {
    let page: any = [];
    for (let i = 1; i <= totalPage; i++) {
      page.push(
        <IconButton
          key={i}
          className={clsx(classes.iconButton, { [classes.activePage]: currentPage === i - 1 })}
          onClick={event => handleChangePage(i - 1)}
        >
          {i}
        </IconButton>
      );
    }
    return page;
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} container alignItems='center' direction='row' justify='center'>
        <Button color='primary' disabled={currentPage === 0 || count === 0 ? true : false} onClick={event => handleChangePage(currentPage - 1)}>
          PREVIOUS
        </Button>
        {renderPagination()}
        <Button
          color='primary'
          disabled={currentPage === totalPage - 1 || count === 0 ? true : false}
          onClick={event => handleChangePage(currentPage + 1)}
        >
          NEXT
        </Button>
      </Grid>
    </Grid>
  );
};

export default PaginationSection;
