import React, { FC, useState, useContext, useEffect, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import useDebounce from 'hooks/useDebounce';
import { Container, Divider, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { TRANSACTION_BASE_URL, TRANSACTION_BASE_INFO_URL } from 'constants/url';
import Locale from 'date-fns/esm/locale/id';
import useRouter from 'hooks/useRouter';
import TransactionPageTable from './components/TransactionPageTable';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import InformationContent from '../../components/InformationContent';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },

  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  spacing: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  paper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: 'auto'
  }
}));

const TransactionPage: FC = () => {
  useCurrentPageTitleUpdater('TransactionList');

  const classes = useStyles();
  const { history } = useRouter();

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');

  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [isSearchingTransaction, setSearchingTransaction] = useState<boolean>(false);
  const [isSearchJobTransaction, setSearchTransactionError] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<TransactionDetailModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [totalOmsetThisMonth, setTotalOmsetThisMonth] = useState<number>(0);
  const [transactionToday, setTransactionToday] = useState<number>(0);
  const [totalOmsetToday, setTotalOmsetToday] = useState<number>(0);
  const [newTransaction, setNewTransaction] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [filterBy, setFilterBy] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [employees, setEmployes] = useState<Select[]>([]);
  const [jobEmployees, setJobEmployes] = useState<EmployeeModel[]>([]);
  const [categories, setCategories] = useState<Select[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<Select[]>([]);
  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);
  const [checked, setChecked] = useState<number[]>([]);
  const [isDelete, setDelete] = useState<boolean>(false);

  // Search Job whenever rowsPerPage, currentPage, queryString, job, and filterby changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      if (columnFilter.length !== 0) {
        columnFilter.map(value => {
          if (value.columnName === 'employeeId') {
            return params.append('ei', value.columnValue.toString());
          } else if(value.columnName === 'paymentMethodId') {
            return params.append('pm', value.columnValue.toString());
          } else {
            return params.append('ci', value.columnValue.toString());
          }
        });
      }

      params.append('fb', 'createdAt');
      params.append('sd', startDate !== null ? format(new Date(startDate), 'yyyy-MM-dd').toString() : '');
      params.append('ed', endDate !== null ? format(new Date(endDate), 'yyyy-MM-dd').toString() : '');

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchTransaction = async () => {
      setSearchingTransaction(true);
      setSearchTransactionError(false);

      try {
        const url = `${TRANSACTION_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        
        let transactionsData: TransactionDetailModel[] = [...data.transactions];
        let employeesData: EmployeeModel[] = [...data.employees];
        let categoriesData: CategoriesModel[] = [...data.categories];
        let paymentsMethodData: PaymentMethodModel[] = [...data.paymentMethod];
        
        setCount(data.count);
        setTransactions(transactionsData);
        setEmployes(employeesData);
        setJobEmployes(employeesData);
        setCategories(categoriesData);
        setPaymentMethod(paymentsMethodData);
      } catch (err) {
        setSearchTransactionError(true);
      }

      setSearchingTransaction(false);
      setDelete(false);
    };

    const getTransactionInformation = async () => {
      try {
        const url = `${TRANSACTION_BASE_INFO_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        let transactionInfosData: TransactionInformationModel = data.row;
        setTotalOmsetThisMonth(transactionInfosData.totalMonthTransaction);
        setTransactionToday(transactionInfosData.countDayTransaction);
        setTotalOmsetToday(transactionInfosData.totalDayTransaction);
      } catch (err) {
        console.log(err);
      }
    };

    searchTransaction();
    getTransactionInformation();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString, columnFilter, filterBy, startDate, endDate]);


  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  // Load Job data if search not empty and populate on search list
  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);
  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  // Load transaction data if transaction data has been deleted
  useEffect(() => {
    if (isDelete) {
      fetchData();
    }
  }, [isDelete, fetchData]);

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };
  const handleOnEdit = (jobId: number): React.MouseEventHandler => () => {
    history.push({ pathname: `${jobId}`, state: { selectedTab: 0 } });
  };

  const handleViewJob = (clientId: number): React.MouseEventHandler => () => {
    history.push({ pathname: `${clientId}`, state: { selectedTab: 2 } });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addNewTransaction = (transactionData: TransactionDetailModel) => {
    let newTotalOmsetThisMonth = totalOmsetThisMonth;
    let newTotalOmsetToday = totalOmsetToday;
    
    transactionData.new = true;
    newTotalOmsetToday += transactionData.totalTransaction;
    newTotalOmsetThisMonth += transactionData.totalTransaction;
    transactions.unshift(transactionData);
   
    setTransactions([...transactions]);
    setCount(c => c + 1);

    setTotalOmsetThisMonth(newTotalOmsetThisMonth);
    setTransactionToday((Number(transactionToday) + 1));
    setTotalOmsetToday(newTotalOmsetToday);
  };

  const detailDate = format(new Date(), 'cccc, dd MMMM yyyy', { locale: Locale }).toString();
  const renderGreeting = () => {
    const now = format(new Date(), 'aa').toString();
    if (now === 'AM') {
      return 'Selamat Pagi,';
    } else if (now === 'PM') {
      return 'Selamat Sore,';
    }
  };



  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Typography variant='h4' color='primary' display='inline'>
        {renderGreeting()} Admin
      </Typography>
      <Typography variant='body1' color='primary' display='inline' className={classes.spacing}>
        {detailDate}
      </Typography>
      <InformationContent
        addNewTransaction={addNewTransaction}
        totalOmsetThisMonth={totalOmsetThisMonth}
        transactionToday={transactionToday}
        totalOmsetToday={totalOmsetToday}
        newTransaction={newTransaction}
        employeeJob={jobEmployees}
      />
      <Divider className={classes.divider} />

      <Paper className={classes.paper}>
        <TransactionPageTable
          isLoadingData={isSearchingTransaction}
          transactions={transactions}
          count={count}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          handleChangePage={(event, page) => setCurrentPage(page)}
          handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
          categories={categories}
          employees={employees}
          paymentMethod={paymentMethod}
          setDelete={setDelete}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarVarient={setSnackbarVarient}
          handleSetMessageSuccess={handleSetMessageSuccess}
          handleSetMessageError={handleSetMessageError}
          handleOnEdit={handleOnEdit}
          query={query}
          setQuery={setQuery}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          checked={checked}
          setChecked={setChecked}
          handleViewJob={handleViewJob}
          addNewTransaction={addNewTransaction}
          handleOnSearch={event => performActionAndRevertPage(setQueryString, event)}
        />
      </Paper>
    </Container>
  );
};

export default TransactionPage;
