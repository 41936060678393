import React, { FC, Fragment, useState,useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';
import { Grid, IconButton, makeStyles, Theme, Tooltip, Typography, Button } from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';
import CalendarIcon from '@material-ui/icons/EventNote';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import FilterIcon from '@material-ui/icons/FilterListRounded';
import PositionedPopper from 'components/PositionedPopper';
import SearchInput from 'components/SearchInput';
import DownloadIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv';
import useDebounce from 'hooks/useDebounce';
import Locale from 'date-fns/esm/locale/id';

interface Props {
  isProcessing: boolean;
  query: string;
  employee: EmployeeModel;
  payrolls: PayrollModel[];
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterBy: string;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  columnFilter: ColumnFilter[];
  handleOnSearch: (actionParam: any) => void;
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerFlex: {
    display: 'flex'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1.5)
  },
  toolbarTable: {
    backgroundColor: '#E7F4F8',
    paddingBottom: theme.spacing(0.9)
  },
  headerColor: {
    color: '#53A0BE'
  },
  icon: {
    fontSize: 20
  },
  calendarIcon: (props: Props) => ({
    fontSize: 20,
    color: props.filterBy ? theme.palette.primary.main : ''
  }),
  filterIcon: (props: Props) => ({
    fontSize: 20,
    color: props.columnFilter.length > 0 ? theme.palette.primary.main : ''
  }),
  leftHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  rightHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1.5)
  },
  exportToCsvButton: {
    textDecoration: 'none'
  }
}));
const ToolBar: FC<Props> = props => {
  const classes = useStyles(props);

  const { employee, payrolls , handleOnSearch} = props;
  const [openCalendarPopper, setOpenCalendarPopper] = useState(false);
  const [anchorElCalendarPopper, setAnchorElCalendarPopper] = useState<HTMLElement | null>(null);
  const [placementCalendarPopper, setPlacementCalendarPopper] = useState<PopperPlacementType>();
  const [exportPaySlip, setExportPaySlip] = useState<ExportPayslipCSV[]>([]);

  const [openCheckboxMenusPopper, setOpenCheckboxMenusPopper] = useState(false);
  const [anchorElCheckboxMenusPopper, setAnchorElCheckboxMenusPopper] = useState<HTMLElement | null>(null);
  const [placementCheckboxMenusPopper, setPlacementCheckboxMenusPopper] = useState<PopperPlacementType>();

  const { isProcessing, setOpenDialog, setMessage } = props;
  const { query, setQuery } = props;
  const { filterBy, setFilterBy } = props;
  const { startDate, setStartDate } = props;
  const { endDate, setEndDate } = props;
  const { columnFilter, setColumnFilter } = props;

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCheckboxMenusPopper(false);
    setOpenCalendarPopper(!openCalendarPopper);
    setAnchorElCalendarPopper(event.currentTarget);
    setPlacementCalendarPopper('bottom-end');
  };

  const handleMenuListClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarPopper(false);
    setOpenCheckboxMenusPopper(!openCheckboxMenusPopper);
    setAnchorElCheckboxMenusPopper(event.currentTarget);
    setPlacementCheckboxMenusPopper('bottom-end');
  };

  const renderHeaderLabel = () => {
    if (filterBy) {
      if (filterBy === 'termStart' && startDate && endDate) {
        return (
          <Typography variant='h6' color='primary'>
            (by start date {format(new Date(startDate), 'dd/MM/yyyy')} - {format(new Date(endDate), 'dd/MM/yyyy')})
          </Typography>
        );
      } else if (filterBy === 'termEnd' && startDate && endDate) {
        return (
          <Typography variant='h6' color='primary'>
            (by end date {format(new Date(startDate), 'dd/MM/yyyy')} - {format(new Date(endDate), 'dd/MM/yyyy')})
          </Typography>
        );
      }
    }
  };
  const handleSearch = useCallback((searchQuery: string) => {
    handleOnSearch(searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    if(payrolls.length === 0) {
      return;
    }

    const newPaySlip: ExportPayslipCSV[] = [...exportPaySlip];

    if (exportPaySlip.length === 0) {
      payrolls.map((payroll, index) => {
        newPaySlip.push({
          Id: payroll.idPayroll,
          IdKaryawan: employee.employeeNumber,
          BulanTahun: format(new Date(payroll.monthYear === undefined ? new Date() : payroll.monthYear), 'MMMM yyyy', { locale: Locale }),
          GajiTunjangan: !payroll.isMonthEnd ? payroll.basicSalaryTemp : payroll.basicSalary,
          Komisi: !payroll.isMonthEnd ? payroll.totalCommissionTemp : payroll.totalCommission,
          Potongan: payroll.totalDeduction,
          TotalGaji: !payroll.isMonthEnd ? payroll.basicSalaryTemp + payroll.totalCommissionTemp - payroll.totalDeductionTemp : payroll.netSalary,
          Catatan: payroll.notes,
          CatatanPotongan: payroll.noteReCalculation
        });
      });
    }
    
    setExportPaySlip(newPaySlip);
  }, [payrolls]);

  const renderLeftHeader = () => {
    return (
      <div className={classes.headerFlex}>
        <div className={classes.otherTextCell}>
          <div>
            <Typography variant='h5' color='primary'>
              Payroll & Gaji - {employee.name}
            </Typography>
            {renderHeaderLabel()}
          </div>
        </div>
      </div>
    );
  };

  const renderRightHeader = () => {
    return (
      <Fragment>
        <Tooltip title='Export' placement='top'>
          <CSVLink data={exportPaySlip} separator={','} filename={'Payroll.csv'} className={classes.exportToCsvButton}>
            <Button variant='outlined' color='primary'>
              <DownloadIcon /> EXPORT .CSV
            </Button>
          </CSVLink>
        </Tooltip>
      </Fragment>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container direction='row' justify='flex-start' alignItems='center' className={classes.leftHeader}>
          {renderLeftHeader()}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction='row' justify='flex-end' alignItems='center' className={classes.rightHeader}>
          {renderRightHeader()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToolBar;
