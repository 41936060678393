import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@material-ui/core/Modal';
import { IconButton, Theme, makeStyles, Typography, Grid, Dialog } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import CalculateForm from './components/CalculateForm';
import ReportPayslip from './components/ReportPayslip';
import { format, startOfMonth, lastDayOfMonth } from 'date-fns';
import { CREATE_PAYROLL_BASE_URL, GET_DETAIL_BY_EMPLOYEE, ALLOWANCE_DEDUCTION_BASE_URL, GET_EDIT_PAYROLL_URL } from 'constants/url';

interface Props {
  open: boolean;
  employee: EmployeeModel;
  payroll: PayrollModel;
  addNewPayroll(payroll: PayrollModel[]): void;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  openPrintPayslip: boolean;
  setOpenPrintPayslip: React.Dispatch<React.SetStateAction<boolean>>;
  oldPayroll: PayrollModel;
  setOldPayroll: React.Dispatch<React.SetStateAction<PayrollModel>>;
  historyTransactions: HistoryTransactionModel[];
  setHistoryTransactions: React.Dispatch<React.SetStateAction<HistoryTransactionModel[]>>;
  employees: EmployeeModel[];
  setEmployees: React.Dispatch<React.SetStateAction<EmployeeModel[]>>;
  buttonPrintPayslip: boolean;
  setButtonPrintPayslip: React.Dispatch<React.SetStateAction<boolean>>;
  oldHistoryTransactions: HistoryTransactionModel[];
  reCalculation: boolean;
  updatePayroll: (updatedPayrollProperties: Partial<PayrollModel>, payrollIndex?: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    outline: 'none',
    top: '50%',
    left: '50%',
    borderRadius: 4
  },
  GridLogo: {
    position: 'relative',
    left: '40%',
    marginTop: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const CalculatePayrollModal: React.FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [cutting, setCutting] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [noteReCalculation, setNoteReCalculation] = useState<string>('');
  const [allowancePosition, setAllowancePosition] = useState<number>(0);
  const [allowanceTransport, setAllowanceTransport] = useState<number>(0);
  const [allowanceCraft, setAllowanceCraft] = useState<number>(0);
  const [allowanceMealt, setAllowanceMealt] = useState<number>(0);
  const [allowanceHealth, setAllowanceHealth] = useState<number>(0);
  const [allowanceHoliday, setAllowanceHoliday] = useState<number>(0);
  const [generalNote, setGeneralNote] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [filterBy, setFilterBy] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>('');
  const [endDate, setEndDate] = useState<string | null>('');
  const [categoryId, setCategoryId] = useState<string | null>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  
  const [addAllowanceDeduction, setAddAllowanceDeduction] = useState<AllowanceDeductionModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [isSearchAllowanceDeductionError, setSearchAllowanceDeductionError] = useState<boolean>(false);

  const {
    open,
    handleCancel,
    setOpenSnackbar,
    addNewPayroll,
    setSnackbarVarient,
    employee,
    payroll,
    openPrintPayslip,
    setOpenPrintPayslip,
    oldPayroll,
    setOldPayroll,
    historyTransactions,
    setHistoryTransactions,
    employees,
    setEmployees,
    buttonPrintPayslip,
    setButtonPrintPayslip,
    oldHistoryTransactions,
    reCalculation,
    updatePayroll
  } = props;

  const handleClose = () => {
    handleCancel();
    clearFormValue();
    clearFormErrors();
  };

  const clearFormValue = () => {
    setCutting(0);
    setNotes('');
    setNoteReCalculation('');
    setButtonPrintPayslip(false);
  };

  const clearFormErrors = () => {};

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    return ret;
  };

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      if (filterBy) {
        if (startDate || endDate) {
          params.append('fb', filterBy.toString());
          params.append('sd', startDate ? format(new Date(startDate), 'yyyy-MM-dd').toString() : '');
          params.append('ed', endDate ? format(new Date(endDate), 'yyyy-MM-dd').toString() : '');
        }
      }

      if (categoryId) {
        params.append('ci', categoryId);
      }

      params.append('ei', employee.id.toString());
      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchAllowanceDeduction = async () => {
      try {
        const url = `${ALLOWANCE_DEDUCTION_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        let alllowanceDeductionData: AllowanceDeductionModel[] = [...data.AllowanceDeductions];

        setAllowancePosition(+alllowanceDeductionData[0].amount);
        setAllowanceTransport(+alllowanceDeductionData[1].amount);
        setAllowanceCraft(+alllowanceDeductionData[2].amount);
        setAllowanceMealt(+alllowanceDeductionData[3].amount);
        setAllowanceHealth(+alllowanceDeductionData[4].amount);
        setAllowanceHoliday(+alllowanceDeductionData[5].amount);
      } catch (err) {
        setSearchAllowanceDeductionError(true);
      }
    };

    const searchHistoryTransaction = async () => {
      try {
        let monthYear: Date = !payroll ? new Date() : new Date(payroll.monthYear);
        setFilterBy("createdAt");
        setStartDate(startOfMonth(monthYear).toString());
        setEndDate(lastDayOfMonth(monthYear).toString());

        const url = `${GET_DETAIL_BY_EMPLOYEE(employee.id)}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        
        let transactionsData: HistoryTransactionModel[] = [...data.transactions];
        let employeesData: EmployeeModel[] = [...data.employees];
        
        setCount(data.count);
        setHistoryTransactions(transactionsData);
        setEmployees(employeesData);
      } catch (err) {
        console.log(err);
      }
    };
    
    if(employee && !buttonPrintPayslip) {
      searchHistoryTransaction();
      searchAllowanceDeduction();
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [filterBy, buttonPrintPayslip, reCalculation]);

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();
      if(!reCalculation) {
        const response = await axios.post(
          `${CREATE_PAYROLL_BASE_URL}`,
          {
            cutting,
            notes: notes,
            employee: { EmployeeId: employee.id },
            payroll: payroll,
            allowances: [allowancePosition, allowanceTransport, allowanceCraft, allowanceMealt, allowanceHealth, allowanceHoliday],
            generalNote: generalNote
          },
          { cancelToken: cancelTokenSource.token }
        );
        let { payrollData, bulkAllowance } = response.data;

        setOpenSnackbar(true);
        setSnackbarVarient('success');
        addNewPayroll(payrollData);
        setOldPayroll(payrollData[0]);
        setAddAllowanceDeduction(bulkAllowance);
        setOpenPrintPayslip(true);
      } else {
        const response = await axios.put(
          `${GET_EDIT_PAYROLL_URL(payroll!.id)}`,
          {
            cutting,
            notes: notes,
            employee: { EmployeeId: employee.id },
            allowances: [allowancePosition, allowanceTransport, allowanceCraft, allowanceMealt, allowanceHealth, allowanceHoliday],
            generalNote: generalNote
          },
          { cancelToken: cancelTokenSource.token }
        );
        setOpenSnackbar(true);
        setSnackbarVarient('success');
        updatePayroll(response.data);
        setOldPayroll(response.data);
        setOpenPrintPayslip(true);
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={'body'} aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction='column' className={classes.paper}>
        {!openPrintPayslip ? (
          <CalculateForm
            okLabel={!reCalculation ? "Kalkulasi" : "Kalkulasi Ulang"}
            reCalculation={reCalculation}
            payroll={payroll}
            employee={employee}
            cutting={cutting}
            setCutting={setCutting}
            notes={notes}
            setNotes={setNotes}
            noteReCalculation={noteReCalculation}
            setNoteReCalculation={setNoteReCalculation}
            allowancePosition={allowancePosition}
            setAllowancePosition={setAllowancePosition}
            allowanceTransport={allowanceTransport}
            setAllowanceTransport={setAllowanceTransport}
            allowanceCraft={allowanceCraft}
            setAllowanceCraft={setAllowanceCraft}
            allowanceMealt={allowanceMealt}
            setAllowanceMealt={setAllowanceMealt}
            allowanceHealth={allowanceHealth}
            setAllowanceHealth={setAllowanceHealth}
            allowanceHoliday={allowanceHoliday}
            setAllowanceHoliday={setAllowanceHoliday}
            generalNote={generalNote}
            setGeneralNote={setGeneralNote}
            isSubmitting={isLoading}
            onSubmit={handleOnSubmit}
            onCancel={handleClose}
          />
        ) : (
          <ReportPayslip
            okLabel='Cetak'
            historyTransactions={!buttonPrintPayslip ? historyTransactions : oldHistoryTransactions}
            employee={employee}
            employees={employees}
            notes={generalNote}
            noteReCalculation={notes}
            oldPayroll={oldPayroll}
            addAllowanceDeduction={addAllowanceDeduction}
            isSubmitting={isLoading}
            onSubmit={handleClose}
            onCancel={handleClose}
          />
        )}
      </Grid>
    </Dialog>
  );
};

export default CalculatePayrollModal;
