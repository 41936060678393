import React, { useState, useEffect, useCallback } from 'react';
import { Theme, makeStyles, Grid, IconButton, Dialog } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import EmployeeForm from './components/EmployeeForm';
import { EMPLOYEE_SERVICE_BASE_URL, GET_EDIT_EMPLOYEE_URL } from 'constants/url';

interface Props {
  open: boolean;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  addNewEmployee(employee: EmployeeModel): void;
  employeeData: EmployeeModel;
  openCreateEmployee: boolean;
  allowancesData: AllowanceDeductionModel[];
  employeeName: string;
  setEmployeeName: React.Dispatch<React.SetStateAction<string>>;
  employeeDateOfBirth: Date;
  setEmployeeDateOfBirth: React.Dispatch<React.SetStateAction<Date>>;
  employeeAddress: string;
  setEmployeeAddress: React.Dispatch<React.SetStateAction<string>>;
  employeeNo: string;
  setEmployeeNumber: React.Dispatch<React.SetStateAction<string>>;
  employeeJoinDate: Date;
  setEmployeeJoinDate: React.Dispatch<React.SetStateAction<Date>>;
  employeeIdentityNumber: string;
  setEmployeeContactNumber: React.Dispatch<React.SetStateAction<string>>;
  employeeContactNameEmergency: string;
  setEmployeeContactNameEmergency: React.Dispatch<React.SetStateAction<string>>;
  employeeContactNumberEmergency: string;
  setEmployeeContactNumberEmergency: React.Dispatch<React.SetStateAction<string>>;
  employeeContactRelationEmergency: string;
  setEmployeeContactRelationEmergency: React.Dispatch<React.SetStateAction<string>>;
  employeeBasicSalary: number;
  setEmployeBasicSalary: React.Dispatch<React.SetStateAction<number>>;

  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4,
    overflow:'scroll',
    height:'100%',
    display:'block'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  divider: {
    marginBottom: theme.spacing(4)
  },
  icon: {
    fontSize: 40
  }
}));

const CreateEmployeeModal: React.FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const {
    open,
    handleCancel,
    openCreateEmployee,
    allowancesData,
    employeeData,
    setOpenSnackbar,
    setSnackbarVarient,
    addNewEmployee,
    handleSetMessageSuccess,
    handleSetMessageError,
    employeeName,
    setEmployeeName,
    employeeDateOfBirth,
    setEmployeeDateOfBirth,
    employeeAddress,
    setEmployeeAddress,
    employeeNo,
    employeeJoinDate,
    setEmployeeJoinDate,
    employeeIdentityNumber,
    setEmployeeContactNumber,
    employeeContactNameEmergency,
    setEmployeeContactNameEmergency,
    employeeContactNumberEmergency,
    setEmployeeContactNumberEmergency,
    employeeContactRelationEmergency,
    setEmployeeContactRelationEmergency,
    employeeBasicSalary,
    setEmployeBasicSalary
  } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  
  const [name, setName] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [address, setAddress] = useState<string>('');
  const [identityNumber, setIdentityNumber] = useState<string>('');
  const [joinDate, setJoinDate] = useState<Date | null>(null);
  const [contactNumber, setContactNumber] = useState<string>('');
  const [allowancePosition, setAllowancePosition] = useState<number>(0);
  const [allowanceTransport, setAllowanceTransporty] = useState<number>(0);
  const [allowanceCraft, setAllowanceCraft] = useState<number>(0);
  const [allowanceMeal, setAllowanceMeal] = useState<number>(0);
  const [allowanceHealth, setAllowanceHealth] = useState<number>(0);
  const [allowanceHoliday, setAllowanceHoliday] = useState<number>(0);
  const [basicSalary, setBasicSalary] = useState<number>(0);

  const [nameError, setNameError] = useState<string>('');
  const [dateOfBirthError, setDateOfBirthError] = useState<string>('');
  const [addressError, setAddressError] = useState<string>('');
  const [identityNumberError, setIdentityNumberError] = useState<string>('');
  const [joinDateError, setJoinDateError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const [contactNameEmergencyError, setContactNameEmergencyError] = useState<string>('');
  const [contactNumberEmergencyError, setContactNumberEmergencyError] = useState<string>('');
  const [contactRelationEmergencyError, setContactRelationEmergencyError] = useState<string>('');

  const [contactNameEmergency, setContactNameEmergency] = useState<string>('');
  const [contactNumberEmergency, setContactNumberEmergency] = useState<string>('');
  const [contactRelationEmergency, setContactRelationEmergency] = useState<string>('');
  
  const handleClose = () => {
    if(openCreateEmployee) {
      handleCancel();
      clearFormValue();
      clearFormErrors();
    } else {
      handleCancel();
    }
  };

  const clearFormValue = () => {
    setName('');
    setDateOfBirth(null);
    setAddress('');
    setIdentityNumber('');
    setJoinDate(null);
    setContactNumber('');
    setAllowancePosition(0);
    setAllowanceTransporty(0);
    setAllowanceCraft(0);
    setAllowanceMeal(0);
    setAllowanceHealth(0);
    setAllowanceHoliday(0);
    setContactNameEmergency('');
    setContactNumberEmergency('');
    setContactRelationEmergency('');
    setContactRelationEmergencyError('');
  };

  const clearFormErrors = () => {
    setNameError('');
    setDateOfBirthError('');
    setAddressError('');
    setIdentityNumberError('');
    setJoinDateError('');
    setContactNumberError('');
    setContactNameEmergencyError('');
    setContactNumberEmergencyError('');
    setContactRelationEmergencyError('');
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!name || !name.trim()) {
      setNameError('Masukkan nama');
      ret = false;
    }
    
    if (!dateOfBirth) {
      setDateOfBirthError('Masukkan tanggal lahir');
      ret = false;
    }

    if (!address || !address.trim()) {
      setAddressError('Masukkan alamat');
      ret = false;
    }

    if (!identityNumber || !identityNumber.trim()) {
      setIdentityNumberError('Masukkan nomor KTP');
      ret = false;
    }

    if (!joinDate) {
      setJoinDateError('Masukkan tanggal bergabung');
      ret = false;
    }

    if (!contactNumber || !contactNumber.trim()) {
      setContactNumberError('Masukkan nomor kontak');
      ret = false;
    }

    if (!contactNameEmergency || !contactNameEmergency.trim()) {
      setContactNameEmergencyError('Masukkan Nama Kontak Darurat');
      ret = false;
    }

    if (!contactNumberEmergency || !contactNumberEmergency.trim()) {
      setContactNumberEmergencyError('Masukkan Nomor Kontak Darurat');
      ret = false;
    }

    if (!contactRelationEmergency || !contactRelationEmergency.trim()) {
      setContactRelationEmergencyError('Masukkan Relasi Kontak Darurat');
      ret = false;
    }

    return ret;
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();

      if(openCreateEmployee) {
        const response = await axios.post(`${EMPLOYEE_SERVICE_BASE_URL}`, {
          name,
          dateOfBirth,
          address,
          identityNumber,
          joinDate,
          contactNumber,
          allowances: [allowancePosition,
          allowanceTransport,
          allowanceCraft,
          allowanceMeal,
          allowanceHealth,
          allowanceHoliday],
          contactNameEmergency,
          contactNumberEmergency,
          contactRelationEmergency
        }, { cancelToken: cancelTokenSource.token });
        addNewEmployee(response.data);
        setOpenSnackbar(true);
        setSnackbarVarient('success');
        handleClose();
        handleSetMessageSuccess('Karyawan berhasil ditambahkan');
      } else {
        await axios.put(`${GET_EDIT_EMPLOYEE_URL(employeeData.id)}`, {
          name,
          dateOfBirth,
          address,
          identityNumber,
          joinDate,
          contactNumber,
          allowances: [allowancePosition,
          allowanceTransport,
          allowanceCraft,
          allowanceMeal,
          allowanceHealth,
          allowanceHoliday],
          contactNameEmergency,
          contactNumberEmergency,
          contactRelationEmergency
        }, { cancelToken: cancelTokenSource.token });
        setEmployeeName(name);
        setEmployeeDateOfBirth(!dateOfBirth ? new Date() : dateOfBirth);
        setEmployeeAddress(address);
        setEmployeeJoinDate(!joinDate ? new Date() : joinDate);
        setEmployeeContactNumber(contactNumber);
        setEmployeeContactNameEmergency(contactNameEmergency);
        setEmployeeContactNumberEmergency(contactNumberEmergency);
        setEmployeeContactRelationEmergency(contactRelationEmergency);

        allowancesData.push({
          id: 1,
          EmployeeId: employeeData.id,
          name: 'Jabatan',
          amount: allowancePosition,
          notes: '',
          isAllowance: true,
          createdAt: new Date()
        });
        allowancesData.push({
          id: 1,
          EmployeeId: employeeData.id,
          name: 'Transport',
          amount: allowanceTransport,
          notes: '',
          isAllowance: true,
          createdAt: new Date()
        });
        allowancesData.push({
          id: 1,
          EmployeeId: employeeData.id,
          name: 'Kerajinan',
          amount: allowanceCraft,
          notes: '',
          isAllowance: true,
          createdAt: new Date()
        });
        allowancesData.push({
          id: 1,
          EmployeeId: employeeData.id,
          name: 'Makan',
          amount: allowanceMeal,
          notes: '',
          isAllowance: true,
          createdAt: new Date()
        });
        allowancesData.push({
          id: 1,
          EmployeeId: employeeData.id,
          name: 'Kesehatan',
          amount: allowanceHealth,
          notes: '',
          isAllowance: true,
          createdAt: new Date()
        });
        allowancesData.push({
          id: 1,
          EmployeeId: employeeData.id,
          name: 'THR',
          amount: allowanceHoliday,
          notes: '',
          isAllowance: true,
          createdAt: new Date()
        });

        setEmployeBasicSalary(allowancePosition + allowanceTransport + allowanceCraft + allowanceMeal + allowanceHealth + allowanceHoliday);
        setOpenSnackbar(true);
        setSnackbarVarient('success');
        handleCancel();
        handleSetMessageSuccess('Karyawan berhasil diubah');
      }
      
    } catch (err) {
      handleSetMessageError('Karyawan Gagal ditambahkan');
    }

    setLoading(false);
  };

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
      setName(employeeName);
      setDateOfBirth(employeeDateOfBirth);
      setAddress(employeeAddress);
      setIdentityNumber(employeeNo);
      setJoinDate(employeeJoinDate);
      setContactNumber(employeeIdentityNumber);
      setAllowancePosition(allowancesData[0] ? allowancesData[0].amount : 0);
      setAllowanceTransporty(allowancesData[1] ? allowancesData[1].amount : 0);
      setAllowanceCraft(allowancesData[2] ? allowancesData[2].amount : 0);
      setAllowanceMeal(allowancesData[3] ? allowancesData[3].amount : 0);
      setAllowanceHealth(allowancesData[4] ? allowancesData[4].amount : 0);
      setAllowanceHoliday(allowancesData[5] ? allowancesData[5].amount : 0);
      setContactNameEmergency(employeeContactNameEmergency);
      setContactNumberEmergency(employeeContactNumberEmergency);
      setContactRelationEmergency(employeeContactRelationEmergency);
      setBasicSalary(employeeBasicSalary);
  }, [
    employeeName,
    employeeDateOfBirth,
    employeeAddress,
    employeeNo,
    employeeJoinDate,
    employeeIdentityNumber,
    allowancesData,
    employeeContactNameEmergency,
    employeeContactNumberEmergency,
    employeeContactRelationEmergency,
    employeeBasicSalary
  ]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  return (
    <Dialog open={open} onClose={handleClose} scroll={'body'} aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
        <IconButton size='small' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <EmployeeForm
          okLabel='Simpan'
          employeeName={name}
          setName={setName}
          nameError={nameError}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          dateOfBirthError={dateOfBirthError}
          address={address}
          setAddress={setAddress}
          addressError={addressError}
          identityNumber={identityNumber}
          setIdentityNumber={setIdentityNumber}
          identityNumberError={identityNumberError}
          joinDate={joinDate}
          setJoinDate={setJoinDate}
          joinDateError={joinDateError}
          contactNumber={contactNumber}
          setContactNumber={setContactNumber}
          contactNumberError={contactNumberError}
          allowancePosition={allowancePosition}
          setAllowancePosition={setAllowancePosition}
          allowanceTransport={allowanceTransport}
          setAllowanceTransporty={setAllowanceTransporty}
          allowanceCraft={allowanceCraft}
          setAllowanceCraft={setAllowanceCraft}
          allowanceMeal={allowanceMeal}
          setAllowanceMeal={setAllowanceMeal}
          allowanceHealth={allowanceHealth}
          setAllowanceHealth={setAllowanceHealth}
          allowanceHoliday={allowanceHoliday}
          setAllowanceHoliday={setAllowanceHoliday}
          contactNameEmergency={contactNameEmergency}
          setContactNameEmergency={setContactNameEmergency}
          contactNameEmergencyError={contactNameEmergencyError}
          contactNumberEmergency={contactNumberEmergency}
          setContactNumberEmergency={setContactNumberEmergency}
          contactNumberEmergencyError={contactNumberEmergencyError}
          contactRelationEmergency={contactRelationEmergency}
          setContactRelationEmergency={setContactRelationEmergency}
          contactRelationEmergencyError={contactRelationEmergencyError}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleClose}
        />
      </Grid>
    </Dialog>
  );
};

export default CreateEmployeeModal;
