import React, { FC, Fragment, useState, useEffect, useContext, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import { Container, Typography, Theme, makeStyles, Table, TableBody, TableHead } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import HeaderRow from 'components/HeaderRow';
import ToolBar from './components/ToolBar';
import StandartToolBar from './components/StandartToolBar';
import TablePagination from 'components/TablePagination';
import CreateEditProductServiceForm from './components/CreateEditProductServiceForm';
import { PRODUCT_SERVICE_BASE_URL, GET_EDIT_PRODUCT_URL, PRODUCT_MERGER_BASE_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  productServices: ProductServicesModel[];
  categories: CategoriesModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterBy: string;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  columnFilter: ColumnFilter[];
  openCreateProductService: boolean;
  handleCancelCreateProductService: () => void;
  openEditProductService: boolean;
  productService?: ProductServicesModel;
  currentEditingProductServiceIndex: number;
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  handleOnSearch: (actionParam: any) => void;
  handleOpenEditProductService: (productServiceIndex: number) => React.MouseEventHandler;
  handleCancelEditProductService: () => void;
  addNewProductService(productService: ProductServicesModel): void;
  updateIndividualProductService: (updatedProductServiceProperties: Partial<ProductServicesModel>, productServiceIndex?: number) => void;
  deleteIndividualProductService: (productServiceIndex: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleOpenCreateProductService: () => void;
  checked:number[];
  setChecked:React.Dispatch<React.SetStateAction<number[]>>  
}

const useStyles = makeStyles((theme: Theme) => ({
    tableWrapper: {
      overflowX: 'auto'
    },
    headerPageTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(0)
    }
  })
);

const ProductsPageTable: FC<Props> = props => {
  const classes = useStyles();
  const { currentPageTitle } = useContext(CurrentPageContext);
  let cancelTokenSource: CancelTokenSource;

  const {
    isLoadingData,
    productServices,
    count,
    categories,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    openCreateProductService,
    handleCancelCreateProductService,
    openEditProductService,
    productService,
    currentEditingProductServiceIndex,
    handleOpenEditProductService,
    handleOpenCreateProductService,
    handleCancelEditProductService,
    updateIndividualProductService,
    deleteIndividualProductService,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    addNewProductService,
    handleOnSearch
  } = props;
  
  const { query, setQuery } = props;
  const { filterBy, setFilterBy } = props;
  const { columnFilter, setColumnFilter } = props;

  const dummyCategory: CategoriesModel = {
    id: 0,
    name: ''
  }

  const dummyProductService: ProductServicesModel = {
    id: 1,
    categoryName: '',
    isProductMerge: false,
    Category: dummyCategory,
    name: '',
    sellingPrice: 0,
    CategoryId: 0,
    barcode: '',
    notes: ''
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  
  const [CategoryId, setCategoryId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [sellingPrice, setSellingPrice] = useState<number>(0);
  const [barcode, setBarcode] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [nameProduct, setNameProduct] = useState<string[]>([]);
  const [CategoryIdProduct, setCategoryIdProduct] = useState<number[]>([]);

  const { checked, setChecked } = props; 
  
  const checkAll = () => {
    const newBulkChecked = [...checked];
    const countChecked = newBulkChecked.length;
    if (count > rowsPerPage) {
      if (countChecked !== rowsPerPage) {
        newBulkChecked.splice(0, countChecked);
        productServices.map(item => newBulkChecked.push(item.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    } else {
      if (countChecked !== count) {
        newBulkChecked.splice(0, countChecked);
        productServices.map(item => newBulkChecked.push(item.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    }

    setChecked(newBulkChecked);
  };

  const individualCheck = (id: number, name: string, CategoryId: number) => {

    const newNameProduct = [...nameProduct];
    const newChecked = [...checked];
    const newCategoryId = [...CategoryIdProduct];

    // count element in object selected filter for check already exist or not
    const countElement = newChecked.filter(newCheckedValue => newCheckedValue === id).length;
    if (countElement === 0) {
      newChecked.push(id);
      newNameProduct.push(name);
      newCategoryId.push(CategoryId);
    } else {
      // check index of element and remove object by index
      const checkedFilterIndex = newChecked.map(newCheckedValue => newCheckedValue).indexOf(id);
      newChecked.splice(checkedFilterIndex, 1);
      newNameProduct.splice(checkedFilterIndex, 1);
    }
    setChecked(newChecked);
    setNameProduct(newNameProduct);
    setCategoryIdProduct(newCategoryId.filter((value, index, self) => self.indexOf(value) === index));
  };

  const resetEditFormValues = useCallback(() => {
    if (!productService) {
      return;
    }

    const { CategoryId, name, sellingPrice, barcode, notes } = productService;

    setName(name);
    setSellingPrice(sellingPrice);
    setBarcode(barcode);
    setNotes(notes);
    setCategoryId(CategoryId === null ? 0 : CategoryId);
  }, [productService]);

  useEffect(() => {
    if (!openEditProductService) {
      let timeout: NodeJS.Timeout;

      if (isLoadingData) {
        timeout = setTimeout(() => {
          setShowSkeleton(true);
        }, 500);
      }

      setShowSkeleton(false);
      resetInputFormValues();
      clearFormErrors();

      return () => {
        clearTimeout(timeout);
      };
    } else {
      resetEditFormValues();
      clearFormErrors();
    }
    
  }, [openEditProductService, isLoadingData, resetEditFormValues]);

  const resetInputFormValues = () => {
    setCategoryId(0);
    setName('');
    setSellingPrice(0);
    setBarcode('');
    setNotes('');
  };

  const validateForm = () => {
    let ret = true;

    return ret;
  };

  const handleCloseCreateProductService = () => {
    handleCancelCreateProductService();
    resetInputFormValues();
    clearFormErrors();
  };

  const handleCloseEditProductService = () => {
    handleCancelEditProductService();
    resetInputFormValues();
    clearFormErrors();
  };

  const clearFormErrors = () => {
    
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();

      if(!openEditProductService){
        const response = await axios.post(
          `${PRODUCT_SERVICE_BASE_URL}`,
          {
            CategoryId,
            name,
            sellingPrice,
            barcode,
            notes,
            isProductMerge: false
          },
          { cancelToken: cancelTokenSource.token }
        );
        
        addNewProductService(response.data);
        handleSetMessageSuccess('Berhasil menambahkan produk & servis!');
      } else {
        const response = await axios.put(
          `${GET_EDIT_PRODUCT_URL(productService!.id)}`,
          {
            CategoryId,
            name,
            sellingPrice,
            barcode,
            notes
          },
          { cancelToken: cancelTokenSource.token }
        );
        updateIndividualProductService(response.data);
        handleSetMessageSuccess('Berhasil mengubah produk & servis!');
      }
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      !openEditProductService ? handleCloseCreateProductService() : handleCloseEditProductService();
    } catch (err) {
      const { errorCode } = err.data;

      handleSetMessageError('Gagal mengubah Produk');
      
      if (errorCode !== 99) {
        setSnackbarVarient('error');
        setOpenSnackbar(true);
        console.log(`err:${err}`);
        console.log(`errorCode:${errorCode}`);
      }
    }

    setLoading(false);
  };


  const handleOnMerge = async () => {
     setLoading(true);

     try {
      cancelTokenSource = axios.CancelToken.source();

      let response = await axios.post(
        `${PRODUCT_SERVICE_BASE_URL}`,
        {
          CategoryId: CategoryIdProduct[0],
          name: nameProduct.join(' + '),
          sellingPrice: 0,
          barcode: '-',
          notes: '-',
          isProductMerge: true
        },
        { cancelToken: cancelTokenSource.token }
      );

      checked.map(item => {
        axios.post(
          `${PRODUCT_MERGER_BASE_URL}`,
          {
            ProductId: response.data.id,
            ProductMergerId: item,
            notes: '-'
          },
          { cancelToken: cancelTokenSource.token }
        );
      });

      setChecked([]);
      setNameProduct([]);
      setCategoryIdProduct([]);
      setLoading(true);
    
      addNewProductService(response.data);
      handleSetMessageSuccess('Berhasil merge produk & servis!');
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      const { errorCode } = err.data;

      setSnackbarVarient('error');
      setOpenSnackbar(true);
      handleSetMessageError('Gagal merge Produk');
    }

    setLoading(false);
  }
  
  return (
    <Fragment>
      <Container className={classes.headerPageTitleContainer}>
        <Typography color='primary' variant='h5'>
          {currentPageTitle}
        </Typography>
      </Container>
      
      <ToolBar
        isProcessing={isProcessing}
        query={query}
        setQuery={setQuery}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        setOpenDialog={setOpenDialog}
        setMessage={setMessage}
        openEditProductService={handleOpenCreateProductService}
        handleOnSearch={handleOnSearch}
      />

      <StandartToolBar
        isProcessing={isProcessing}
        checked={checked}
        setOpenDialog={setOpenDialog}
        setMessage={setMessage}
        onSubmit={handleOnMerge}
        onCancel={() => setChecked([])}
        primaryButtonLabel={'Save'}
        customBackground={'#F4F9FC'}
        isSubmitting={isLoading}
      />
      <div className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <HeaderRow
              headers={[
                { label: 'CheckBox', pL: '10px', pR: '10px', verticalAlign: 'top', isCheckBox: true, checked, rowsPerPage, handleCheckAll:checkAll},
                { label: 'Kategori', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Nama Produk / Service', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'ID / Barcode', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Harga', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Catatan', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Action', pL: '10px', pR: '10px', verticalAlign: 'top' },
              ]}
            />
          </TableHead>
          <TableBody>
            {openCreateProductService && (
              <CreateEditProductServiceForm
                categories={categories}
                CategoryId={CategoryId}
                setCategoryId={setCategoryId}
                name={name}
                setName={setName}
                sellingPrice={sellingPrice}
                setSellingPrice={setSellingPrice}
                barcode={barcode}
                setBarcode={setBarcode}
                notes={notes}
                setNotes={setNotes}
                isSubmitting={isLoading}
                onSubmit={handleOnSubmit}
                onCancel={handleCloseCreateProductService}
                primaryButtonLabel={'Save'}
              />
            )}
            {showSkeleton
            ? [1, 2, 3, 4, 5, 6].map(index => (
                <BodyRow
                  index={index}
                  key={index}
                  productService={dummyProductService}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  handleSetMessageSuccess={handleSetMessageSuccess}
                  handleSetMessageError={handleSetMessageError}
                  deleteIndividualProductService={deleteIndividualProductService}
                  onEditProductService={handleOpenEditProductService(index)}
                  openEditProductService={openEditProductService}
                  updateProductService={updateIndividualProductService}
                  isLoadingData={isLoadingData}
                  checked={checked}
                  handleIndividualCheck={individualCheck}
                />
              ))
            : productServices.map((productService, index) =>
                openEditProductService && currentEditingProductServiceIndex === index ? (
                  <CreateEditProductServiceForm
                    categories={categories}
                    key={productService.id}
                    CategoryId={CategoryId}
                    setCategoryId={setCategoryId}
                    name={name}
                    setName={setName}
                    sellingPrice={sellingPrice}
                    setSellingPrice={setSellingPrice}
                    barcode={barcode}
                    setBarcode={setBarcode}
                    notes={notes}
                    setNotes={setNotes}
                    isSubmitting={isLoading}
                    onSubmit={handleOnSubmit}
                    onCancel={handleCloseEditProductService}
                    primaryButtonLabel={'Save'}
                    customBackground={'#F4F9FC'}
                  />
                ) : (
                  <BodyRow
                    index={index}
                    key={productService.id}
                    productService={productService}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    deleteIndividualProductService={deleteIndividualProductService}
                    onEditProductService={handleOpenEditProductService(index)}
                    openEditProductService={openEditProductService}
                    updateProductService={updateIndividualProductService}
                    isLoadingData={isLoadingData}
                    checked={checked}
                    handleIndividualCheck={individualCheck}
                    
                  />
                )
              )}
          </TableBody>           
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </div>
    </Fragment>
  );
}

export default ProductsPageTable;