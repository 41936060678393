import React, { FC, Fragment, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';
import { Tooltip, IconButton, makeStyles, TableRow, Theme, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { green } from '@material-ui/core/colors';
import BodyCell from 'components/BodyCell';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { format } from 'date-fns';
import Locale from 'date-fns/esm/locale/id';
import axios from 'axios';
import { GET_DELETE_VEHICLE_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  historyTransaction: HistoryTransactionModel;
  openEditHistoryTransaction: boolean;
  updateHistoryTransaction: (updatedHistoryTransactionProperties: Partial<HistoryTransactionModel>, vehicleIndex?: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageError: (message: string) => void;
  index: number;
  deleteIndividualHistoryTransaction: (vehicleIndex: number) => void;
  onEditHistoryTransaction: React.MouseEventHandler;
  handleSetMessageSuccess: (message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 64
  },
  tableRowChecked: {
    height: 64,
    backgroundColor: '#F4F4F4'
  },
  actionIcon: {
    fontSize: 20
  },
  newIcon: {
    color: green[500],
    fontSize: 30,
    marginTop: theme.spacing(0.8)
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderRadius: 7
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  editIconForEmptyInvoice: {
    marginLeft: theme.spacing(5.5)
  },
  wrapper: {
    position: 'relative'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  icon: {
    fontSize: 20
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column'
  },
  menuAvatar: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px',
    color: '#707070'
  },
  menuList: {
    minHeight: 30
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    historyTransaction,
    onEditHistoryTransaction,
    handleSetMessageSuccess,
    handleSetMessageError,
    setOpenSnackbar,
    setSnackbarVarient,
    deleteIndividualHistoryTransaction,
    index
  } = props;

  const { transactionId, productPrice, qty, EmployeeDetails, refId, categoryName, productName, EmployeeName, ProductMergers, paymentMethod, totalTransaction, commission, netCommission, createdAt } = historyTransaction;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>, actionStatus: string) => {
    setProcessing(true);

    try {
      await action();
      handleSetMessageSuccess(`Successfully ${actionStatus} a vehicle`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleSetMessageError(`Failed to ${actionStatus} a vehicle`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setProcessing(false);
  };

  return (
    <Fragment>
      <TableRow className={classes.tableRow}>
        <BodyCell cellWidth='10%' pL='5px'>
          {isLoadingData ? <Skeleton width={80} /> : refId}
        </BodyCell>

        <BodyCell cellWidth='10%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : format(new Date(createdAt), 'cccc, dd/MM/yyyy | hh:mm', {locale: Locale})}
        </BodyCell>

        <BodyCell cellWidth='10%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : categoryName}
        </BodyCell>

        <BodyCell cellWidth={'11%'} pL='10px' pR='10px'>
          {isLoadingData ? <Skeleton width={80} /> : productName}
        </BodyCell>

        <BodyCell cellWidth={'10%'} pL='10px' pR='10px'>
          {isLoadingData ? <Skeleton width={80} /> : paymentMethod}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
        {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <NumberFormat value={productPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
          )}
        </BodyCell>

        <BodyCell cellWidth='7%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : qty}
        </BodyCell>

        <BodyCell cellWidth={'10%'} pL='10px' pR='10px'>
          {isLoadingData ? <Skeleton width={80} /> : <NumberFormat value={commission} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : 
          EmployeeDetails && EmployeeDetails.map((EmployeeDetail, index) => 
          (
            !EmployeeDetails[index + 1] ? EmployeeDetail.employeeNumber : EmployeeDetail.employeeNumber+', '
          ))}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : <NumberFormat value={netCommission} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />}
        </BodyCell>
      </TableRow>
    </Fragment>
  );
};

export default BodyRow;
