import React, { FC, useState, Fragment, useEffect } from 'react';
import { Box, Button, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Transaction from './Transaction';
import { CUSTOMER_BASE_URL } from 'constants/url';
import axios, { CancelTokenSource } from 'axios';
import NumberFormat from 'react-number-format';

interface Props {
  employeeJob: EmployeeModel[];
  totalOmsetThisMonth: number;
  transactionToday: number;
  totalOmsetToday: number;
  newTransaction: string;
  addNewTransaction(transaction: TransactionDetailModel): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  borderColor: {
    borderColor: '#ffffff',
    padding: theme.spacing(2)
  },
  addGrid: {
    textAlign: 'end'
  },
  extendedIcon: {
    paddingRight: theme.spacing(1)
  },
  extendedIconadd: {
    paddingRight: theme.spacing(1),
    textAlign: 'center'
  },
  colorSecondary: {
    backgroundColor: '#53A0BE',
    height: theme.spacing(10)
  },
  colorPrimary: {
    backgroundColor: '#ef965a',
    padding: theme.spacing(2)
  },
  colorWhite: {
    color: '#ffffff'
  },
  addButton: {
    paddingLeft: theme.spacing(17),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(4)
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      width: theme.spacing(30),
      height: theme.spacing(10)
    }
  }
}));

const InformationContent: FC<Props> = props => {
  const classes = useStyles();
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [queryString, setQueryString] = useState<string>();
  const [isSearchingCustomer, setSearchingCustomer] = useState<boolean>(false);
  const [isSearchCustomerError, setSearchCustomerError] = useState<boolean>(false);
  const [customers, setCustomers] = useState<CustomerDetailModel[]>([]);
  const [openPrintInvoce, setOpenInvoice] = useState<boolean>(false);
  const [onEditTransaction, setOnEditTransaction] = useState<boolean>(false);
  const { addNewTransaction, totalOmsetThisMonth, employeeJob, transactionToday, totalOmsetToday } = props;
  
  const dummyTransaction: TransactionDetailModel = {
    id: 0,
    transactionId: 0,
    createdAt: new Date(),
    paymentMethod: '',
    totalTransaction: 0,
    discount: 0,
    discountType: 0,
    productId: 0,
    productName: '',
    categoryId: 0,
    categoryName: '',
    employeeId: 0,
    itemProducts: [{
      id: 0,
      discount: 0,
      discountAmount: 0,
      discountType: 0,
      discountTypeName: '',
      isProductMerge: false,
      ProductId: 0,
      productName: '',
      productPrice: 0,
      CategoryId: 0,
      categoryName: '',
      EmployeeId: [],
      EmployeeNumber: [],
      EmployeeDetails: [],
      EmployeeDetailsOne: [],
      EmployeeDetailsTwo: [],
      EmployeeJob: [],
      employeeName: '',
      productsTemp: [],
      qty: 0,
      isService: false,
      categoryNameError: '',
      employeeNameError: '',
      productNameError: ''
    }],
    employeeName: '',
    tenderAmount: 0,
    notes: '',
    refId: '',
    customerId: 0,
    customerName: ''
  }
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }
      return params.toString();
    };

    const searchCustomer = async () => {
      setSearchingCustomer(true);
      setSearchCustomerError(false);

      try {
        const url = `${CUSTOMER_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setCustomers(data.customers);
      } catch (err) {
        console.log(err);
        setSearchCustomerError(true);
      }

      setSearchingCustomer(false);
    };

    searchCustomer();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [queryString]);

  const handleOpenCreateTransaction = () => {
    setOpenCreateUserModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateUserModal(false);
  };

  return (
    <div className={classes.root}>
      <Fragment>
        <Paper>
          <Box borderRight={40} className={classes.borderColor}>
            <Typography variant='body1' color='textSecondary'>
              TOTAL OMSET BULAN INI
            </Typography>
            <Typography variant='h5' color='secondary'>
              <NumberFormat value={totalOmsetThisMonth} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
            </Typography>
          </Box>
        </Paper>
        <Paper>
          <Box borderRight={40} className={classes.borderColor}>
            <Typography variant='body1' color='textSecondary' display='block'>
              TRANSAKSI HARI INI
            </Typography>
            <Typography variant='h5' color='secondary' display='block'>
              {transactionToday}
            </Typography>
          </Box>
        </Paper>
        <Paper className={classes.colorPrimary}>
          <Box >
            <Typography variant='body1' className={classes.colorWhite}>
              TOTAL OMSET HARI INI
            </Typography>
            <Typography variant='h5' className={classes.colorWhite}>
              <NumberFormat value={totalOmsetToday} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
            </Typography>
          </Box>
        </Paper>
        <Button color='primary' size='large' variant='contained' onClick={handleOpenCreateTransaction}>
          <Typography variant='h5'>Transaksi Baru</Typography>
          <Typography variant='h5' className={classes.extendedIconadd} display='block'>
            <AddCircleOutlineIcon />
          </Typography>
        </Button>
        <Transaction
          open={openCreateUserModal}
          close={handleCloseCreateModal}
          openPrintInvoce={openPrintInvoce}
          setOpenInvoice={setOpenInvoice}
          printInvoice={false}
          onEditTransaction={onEditTransaction}
          addNewTransaction={addNewTransaction}
          invoiceData={dummyTransaction}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarVarient={setSnackbarVarient}
          employeeJob={employeeJob}
        />
      </Fragment>
    </div>
  );
};

export default InformationContent;
