import React, { FC, useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';

import useRouter from 'hooks/useRouter';
import { Button, Container, makeStyles, Theme, Grid } from '@material-ui/core';
import CustomizedTabs from 'components/CustomizedTabs';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HeaderContent from './components/HeaderContent';
import CommissionContent from './components/CommissionContent';
import PayrollContent from './components/PayrollContent';
import HistoryTransactionContent from './components/HistoryTransactionContent';
import { GET_DETAIL_EMPLOYEE } from 'constants/url';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  addGrid: {
    textAlign: 'end'
  },
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  extendedIcon: {
    paddingRight: theme.spacing(1)
  },
}));

const EmployeeDetailPage: FC = () => {
  useCurrentPageTitleUpdater('Employees Detail');
  const classes = useStyles();
  const { match, history } = useRouter();
  
  const params = match.params.id;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const dummyEmployee: EmployeeModel = {
    id: 0,
    PayrollId: 0,
    name: '',
    dateOfBirth: new Date(),
    address: '',
    employeeNumber: '',
    EmployeeNumber: '',
    EmployeeName: '',
    joinDate: new Date(),
    identityNumber: '',
    contactNumber: '',
    contactNameEmergency: '',
    contactNumberEmergency: '',
    contactRelationEmergency: '',
    EmployeeSelectedJob: [],
    basicSalary: 0,
    isCommissionSet: false,
    bankAccount: '',
    createdAt: new Date(),
    updatedAt: new Date()
  };

  const dummyAllowance: AllowanceDeductionModel[] = [{
    id: 0,
    EmployeeId: 0,
    name: '',
    amount: 0,
    notes: '',
    isAllowance: false,
    createdAt: new Date()
  }];

  const [employee, setEmployee] = useState<EmployeeModel>(dummyEmployee);
  const [allowances, setAllowances] = useState<AllowanceDeductionModel[]>(dummyAllowance);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const loadData = async () => {
      try {
        const { data } = await axios.get(`${GET_DETAIL_EMPLOYEE(params)}`, { cancelToken: cancelTokenSource.token });
        let allowancesData = [...data.allowances];

        setEmployee(data.employee);
        setAllowances(allowancesData);
      } catch (err) {
        console.log(err);
      }
    };
    loadData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [params]);
  
  const handleBacktoEmployee = () => {
    history.push({ pathname: `/settings`, state: { selectedTab: 1 } });
  };

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    action(actionParam);
  };

  const SelectedContent: FC<{ page: number }> = props => {
    switch (props.page) {
      case 0:
        return <CommissionContent employee={employee}/>;
      case 1:
        return <HistoryTransactionContent employee={employee}/>;
      case 2:
        return <PayrollContent employee={employee}/>;
      default:
        return <div />;
    }
  };
  
  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <HeaderContent allowances={allowances} employee={employee} />
      <Grid container spacing={2}>
        <Grid item sm={12} className={classes.addGrid}>
          <Button color='primary' size='medium' variant='contained' className={classes.addButton} onClick={handleBacktoEmployee}>
            <PersonAddIcon className={classes.extendedIcon} />
            Lihat Karyawan
          </Button>
        </Grid>
      </Grid>
      <CustomizedTabs
        tabs={[{ id: 0, name: 'Komisi' }, { id: 1, name: 'Riwayat Transaksi' }, { id: 2, name: 'Payroll & Gaji' }]}
        selectedTabId={selectedTab}
        onSelect={(tabId: number) => performActionAndRevertPage(setSelectedTab, tabId)}
      />
      <SelectedContent page={selectedTab} />
    </Container>
  );
};

export default EmployeeDetailPage;
