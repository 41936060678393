import React, { FC, Fragment, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';
import { Tooltip, Checkbox, IconButton, makeStyles, TableRow, Theme, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { green } from '@material-ui/core/colors';
import BodyCell from 'components/BodyCell';
import { StandardConfirmationDialog } from 'components/AppDialog';
import axios from 'axios';
import { GET_DELETE_VEHICLE_URL } from 'constants/url';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import clsx from 'clsx';

interface Props {
  isLoadingData: boolean;
  commission: CommissionModel;
  openEditCommission: boolean;
  updateCommission: (updatedCommissionProperties: Partial<CommissionModel>, vehicleIndex?: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageError: (message: string) => void;
  index: number;
  deleteIndividualCommission: (vehicleIndex: number) => void;
  onEditCommission: React.MouseEventHandler;
  handleSetMessageSuccess: (message: string) => void;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
  checked: number[];
  handleIndividualCheck: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 64
  },
  tableRowChecked: {
    height: 64,
    backgroundColor: '#F4F4F4'
  },
  actionIcon: {
    fontSize: 20
  },
  newIcon: {
    color: green[500],
    fontSize: 30,
    marginTop: theme.spacing(0.8)
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderRadius: 7
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  editIconForEmptyInvoice: {
    marginLeft: theme.spacing(5.5)
  },
  wrapper: {
    position: 'relative'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  icon: {
    fontSize: 20
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column'
  },
  menuAvatar: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px',
    color: '#707070'
  },
  menuList: {
    minHeight: 30
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    commission,
    onEditCommission,
    handleSetMessageSuccess,
    handleSetMessageError,
    setOpenSnackbar,
    setSnackbarVarient,
    deleteIndividualCommission,
    index,
    setSelectedId,
    checked,
    handleIndividualCheck
  } = props;

  const { id, ProductId, commissionPrice, categoryName, productName, barcode, sellingPrice, new: isNew } = commission;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>, actionStatus: string) => {
    setProcessing(true);

    try {
      await action();
      handleSetMessageSuccess(`Successfully ${actionStatus} a vehicle`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleSetMessageError(`Failed to ${actionStatus} a vehicle`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setProcessing(false);
  };

  const deleteCommission: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_VEHICLE_URL(id));
    }, 'delete');
    deleteIndividualCommission(index);
  };
  const checkedCheckbox = checked.filter(checkedValue => checkedValue === id).length;

  let isChecked;

  if (checkedCheckbox === 0) {
    isChecked = false;
  } else {
    isChecked = true;
  }

  return (
    <Fragment>
      <TableRow className={clsx({ [classes.tableRow]: !isChecked, [classes.tableRowChecked]: isChecked })}>
        <BodyCell cellWidth='5%' pR='10px' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={15} />
          ) : (
            <Checkbox
              key={id}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
              checkedIcon={<CheckBoxIcon className={classes.checkBoxSize} />}
              edge='start'
              color='primary'
              className={classes.checkBox}
              checked={isChecked}
              tabIndex={-1}
              disableRipple
              onChange={event => handleIndividualCheck && handleIndividualCheck(id)}
            />
          )}
        </BodyCell>

        <BodyCell cellWidth='15%' pL='10px'>
          {isLoadingData ? <Skeleton width={80} /> : categoryName}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : productName}
        </BodyCell>

        <BodyCell cellWidth={'11%'} pL='10px' pR='10px'>
          {isLoadingData ? <Skeleton width={80} /> : barcode}
        </BodyCell>

        <BodyCell cellWidth={'9%'} pL='10px' pR='10px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
          )}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <NumberFormat value={commissionPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
          )}
        </BodyCell>

        <BodyCell cellWidth='15%' pL='40px' isComponent={true}>
          {isLoadingData ? null : (
            <Fragment>
              <Tooltip title={'Edit'} placement='top'>
                <IconButton size='small' onClick={onEditCommission}>
                  <EditIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>
    </Fragment>
  );
};

export default BodyRow;
