import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { IconButton, Theme, makeStyles, Typography, Grid } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import { CUSTOMER_BASE_URL } from '../../../../constants/url';
import CreateCustomerForm from './CreateCustomerForm';

interface Props {
  open: boolean;
  addNewCustomer(customer: CustomerDetailModel): void;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const CreateCustomer: React.FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const [isLoading, setLoading] = useState<boolean>(false);


  const [name, setName] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
   const [nameError, setNameError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const { open, addNewCustomer, handleCancel, setOpenSnackbar, setSnackbarVarient } = props;

  const handleClose = () => {
    handleCancel();
    clearFormValue();
    clearFormErrors();
  };

  const clearFormValue = () => {
    setName('');
    setContactNumber('');
  };

  const clearFormErrors = () => {
    setNameError('');
    setContactNumberError('');
   };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();
  
    if (!name || !name.trim()) {
      setNameError('Masukkan nama pelanggan');
      ret = false;
    }

    if (!contactNumber || !contactNumber.trim()) {
      setContactNumberError('Masukkan nomor hp pelanggan');
      ret = false;
    }
    
    return ret;
  };

  const handleOnSubmitCustomer: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      const response = await axios.post(
        `${CUSTOMER_BASE_URL}`,
        {
          displayName: name,
          contactNumber
        },
        { cancelToken: cancelTokenSource.token }
      );
      addNewCustomer(response.data);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleClose();
      
    } catch (err) {
      console.log(err);
      const { errorCode } = err.data;
    }

    setLoading(false);
  };

  return (
    <Modal aria-labelledby='modal-title' open={open} disableBackdropClick={true}>
      <Grid container item xs={8} sm={8} md={8} lg={5} xl={5} spacing={3} direction='column' className={classes.paper}>
        <Typography variant='h4' id='modal-title' color='primary'>
          Masukkan Info Pelanggan
        </Typography>
        <IconButton size='small' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <CreateCustomerForm
          okLabel='Add'
          name={name}
          setName={setName}
          nameError={nameError}
          contactNumber={contactNumber}
          setContactNumber={setContactNumber}
          contactNumberError={contactNumberError}
          isSubmitting={isLoading}
          onSubmitCustomer={handleOnSubmitCustomer}
          onCancel={handleClose}
        />
      </Grid>
    </Modal>
  );
};

export default CreateCustomer;
