import React, { FC, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';
import useRouter from 'hooks/useRouter';
import { format } from 'date-fns';
import { StandardConfirmationDialog } from 'components/AppDialog';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { green, orange } from '@material-ui/core/colors';
import { GET_DELETE_EMPLOYEE } from 'constants/url';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  TableCell
} from '@material-ui/core';

interface Props {
  isLoadingData: boolean;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  employee: EmployeeModel;
  index: number;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  handleSetMessageSuccess: (message: string) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageError: (message: string) => void;
  deleteIndividualEmployee: (employeeId: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    padding: theme.spacing(2)
  },
  customTypography: {
    marginTop: theme.spacing(0.5),
    textAlign: 'right'
  },
  customTypographyLeft: {
    marginTop: theme.spacing(0.5)
  },
  labelPadding: {
    marginRight: theme.spacing(1)
  },
  tagUserIsGood: {
    backgroundColor: green[500],
    borderRadius: 3,
    color: '#FFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2)
  },
  contentTypography: {
    fontWeight: 500
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  isCommissionReady: {
    border: '1px solid #FF0000',
    borderRadius: 3,
    color: '#FF0000',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  status: {
    backgroundColor: '#7A8DA5',
    borderRadius: 3,
    color: '#FFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  contactEmegercy: {
    paddingBottom: theme.spacing(2)
  },
  lineHeight: {
    lineHeight: 2
  },
  qualificationLineHeight: {
    lineHeight: 1.7
  },
  avatar: {
    backgroundColor: '#0B3469'
  },
  colorText: {
    color: '#000000'
  },
  button: {
    borderStyle: 'solid',
    borderWidth: 1,
    position: 'relative',
    left: '30%'
  },
  icon: {
    fontSize: 16.3,
    position: 'relative',
    left: 100
  }
}));

const EmployeeGrid: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  const { isLoadingData, setIsLoadingData, employee, index, setMessage, deleteIndividualEmployee, handleSetMessageSuccess, setSnackbarVarient, setOpenSnackbar, handleSetMessageError } = props;
  const [img, setImg] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [employeeName, setEmployeeName] = useState<string>('');

  const handleViewDetailClick = (employeeId: number) => {
    history.push({ pathname: `/employee/${employeeId}`});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

 
  const actionWrapper = async (action: () => Promise<void>, actionStatus: string) => {
    setProcessing(true);

    try {
      await action();
      handleSetMessageSuccess(`Berhasil ${actionStatus} karyawan!`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleSetMessageError(`Gagal menghapus ${actionStatus}`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setProcessing(false);
  };

  const deleteEmployee: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_EMPLOYEE(employee.id));
    }, 'hapus');
    deleteIndividualEmployee(index);
  };

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const loadData = async () => {
      setIsLoadingData(true);
      try {
        setEmployeeName(employee.name);
      } catch (err) {
        console.log(err);
      }
      setIsLoadingData(false);
    };
    loadData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [employee.name]);

  let initialName: any = [];
  if (employeeName) {
    const splitedNames = employeeName.split(' ');
    splitedNames.map((splitedName, index) => {
      if (index === 0 || index === splitedNames.length - 1) {
        if (splitedName[0]) {
          initialName.push(splitedName[0].toUpperCase());
        } else {
          initialName.push(splitedNames[index - 1][0] ? splitedNames[index - 1][0].toUpperCase() : '');
        }
      }

      return initialName;
    });
  }

  const renderAvatar = () => {
    if (isLoadingData) {
      return <Avatar className={classes.avatar} alt={employee.name} src={img} />;
    } else {
      return (
        <Avatar className={classes.avatar} aria-label='employeeInitial'>
          {initialName.join('')}
        </Avatar>
      );
    }
  };
 
  return (    
    <Grid item sm={4} key={index}>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={renderAvatar()}
          action={
            <div className={classes.customTypography}>
              <Typography variant='body1' align='right' display='inline' className={classes.labelPadding}>
                {isLoadingData ? <Skeleton width={40} /> : <span></span>}
              </Typography>
              <Typography className={classes.colorText} variant='h6'>
                {isLoadingData ? <Skeleton width={90} /> : `${employee.employeeNumber}`}
              </Typography>
              <Typography color='primary' variant='body1' display='block'>
                {isLoadingData ? <Skeleton width={150} /> : `No KTP`}
              </Typography>
            </div>
          }
          title={
            <div className={classes.customTypographyLeft}>
              <Typography align='left' display='inline'>
                {isLoadingData ? <Skeleton width={90} /> : `${employee.name}`}
              </Typography>
              {!employee.isCommissionSet && (
                <Typography variant='body1' align='right' display='inline' className={classes.lineHeight}>
                  <span className={classes.isCommissionReady}>Tambah Komisi</span>
                </Typography>
              )}
            </div>
          }
          subheader={
            <Typography color='primary' variant='body1'>
              {isLoadingData ? <Skeleton width={150} /> : `ID. ${employee.identityNumber}`}
            </Typography>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='h6' align='left'>
                {isLoadingData ? (
                  <Skeleton width={60} />
                ) : employee.basicSalary ? (
                  <NumberFormat value={employee.basicSalary} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                ) : (
                  'Rp -'
                )}
              </Typography>
              <Typography variant='body1' color='primary' align='left'>
                {isLoadingData ? <Skeleton width={60} /> : 'Gaji/Tunjangan'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6' align='left'>
                {isLoadingData ? <Skeleton width={60} /> : employee.basicSalary ? employee.contactNumber : '-'}
              </Typography>
              <Typography variant='body1' color='primary' align='left'>
                {isLoadingData ? <Skeleton width={60} /> : 'Nomor HP'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6' align='right'>
                {format(new Date(employee.joinDate === undefined ? new Date() : employee.joinDate), 'dd/MM/yyyy')}
              </Typography>
              <Typography variant='body1' color='primary' align='right'>
                {isLoadingData ? <Skeleton width={60} /> : 'Tgl Masuk'}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {isLoadingData ? (
                <Skeleton width={40} />
              ) : (
                <Typography variant='subtitle1' color='primary'>
                  Alamat :
                </Typography>
              )}
            </Grid>
            <Grid item xs={8}>
              {isLoadingData ? (
                <Skeleton width={10} />
              ) : (
                <Typography variant='body1' align='right' display='inline' className={classes.qualificationLineHeight}>
                  {employee.address}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              {isLoadingData ? (
                <Skeleton width={10} />
              ) : (
                <Typography variant='subtitle1' color='primary'>
                  Tgl Lahir :
                </Typography>
              )}
            </Grid>
            <Grid item xs={5}>
              {isLoadingData ? (
                <Skeleton width={30} />
              ) : (
                <Typography variant='body1' align='right' display='inline' className={classes.qualificationLineHeight}>
                  {format(new Date(employee.dateOfBirth === undefined ? new Date() : employee.dateOfBirth), 'dd MMMM yyyy')}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Typography align='center' color='primary' variant='h6' className={classes.contactEmegercy}>
            Kontak Darurat
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='h6' align='left'>
                {isLoadingData ? <Skeleton width={60} /> : employee.contactNameEmergency}
              </Typography>
              <Typography variant='body1' color='primary' align='left'>
                {isLoadingData ? <Skeleton width={60} /> : 'Nama'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6' align='left'>
                {isLoadingData ? <Skeleton width={60} /> : employee.contactNumberEmergency}
              </Typography>
              <Typography variant='body1' color='primary' align='left'>
                {isLoadingData ? <Skeleton width={60} /> : 'Nomor HP'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6' align='right'>
                {isLoadingData ? <Skeleton width={50} /> : employee.contactRelationEmergency}
              </Typography>
              <Typography variant='body1' color='primary' align='right'>
                {isLoadingData ? <Skeleton width={60} /> : 'Relasi'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            className={classes.button}
            color='primary'
            disabled={isLoadingData ? true : false}
            onClick={event => handleViewDetailClick(employee.id)}
          >
            {isLoadingData ? <Skeleton width={100} /> : 'Lihat Detail'}
          </Button>
          <IconButton className={classes.icon} size='small' onClick={event => setOpenDialog(true)}>
            <DeleteOutlineIcon />
          </IconButton>
        </CardActions>
      </Card>
      <StandardConfirmationDialog
        variant={'warning'}
        message={'Anda yakin mau menghapus ini?'}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={deleteEmployee}
      />
    </Grid>
  );
};

export default EmployeeGrid;
