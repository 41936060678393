import React, { FC, Fragment, useState, useEffect } from 'react';
import { createStyles, makeStyles, Table, TableBody, TableHead } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import ToolBar from './components/ToolBar';

interface Props {
  isLoadingData: boolean;
  transactions: TransactionDetailModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterBy: string;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  categories: Select[];
  employees: Select[];
  paymentMethod: Select[];
  columnFilter: ColumnFilter[];
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  checked: number[];
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleOnEdit: (jobIndex: number) => React.MouseEventHandler;
  handleViewJob: (jobIndex: number) => React.MouseEventHandler;
  addNewTransaction(transaction: TransactionDetailModel): void;
  handleOnSearch: (actionParam: any) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    tableWrapper: {
      overflowX: 'auto'
    }
  })
);

const TransactionPageTable: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    transactions,
    count,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    categories,
    employees,
    paymentMethod,
    setDelete,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    handleOnEdit,
    handleViewJob,
    handleOnSearch
  } = props;

  const { checked, setChecked } = props;
  const { query, setQuery } = props;
  const { filterBy, setFilterBy } = props;
  const { startDate, setStartDate } = props;
  const { endDate, setEndDate } = props;
  const { columnFilter, setColumnFilter } = props;
  const dummyTransaction: TransactionDetailModel = {
    id: 0,
    transactionId: 0,
    createdAt: new Date(),
    paymentMethod: '',
    totalTransaction: 0,
    categoryId: 0,
    categoryName: '',
    productId: 0,
    tenderAmount: 0,
    notes: '',
    discount: 0,
    discountType: 0,
    productName: '',
    employeeId: 0,
    itemProducts: [],
    employeeName: '',
    refId: '',
    customerId: 0,
    customerName: ''
  };

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const { addNewTransaction } = props;

  useEffect(() => {
 
    let timeout: NodeJS.Timeout;
    
   if (isLoadingData) {
      timeout = setTimeout(() => {
    
      setShowSkeleton(true);
      }, 500);
    }
  
    setShowSkeleton(false);
    return () => {
      clearTimeout(timeout);
    };
  }, [isLoadingData]);
  
  return (
    <Fragment>
      <ToolBar
        isProcessing={isProcessing}
        transactions={transactions}
        query={query}
        setQuery={setQuery}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        categories={categories}
        employees={employees}
        paymentMethod={paymentMethod}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        checked={checked}
        setOpenDialog={setOpenDialog}
        setMessage={setMessage}
        handleOnSearch={handleOnSearch}
        count={count}
      />
      <div className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <HeaderRow
              headers={[
                { label: 'Trans ID', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Hari & Tanggal', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Kategory', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Item', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Karyawan', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Metode Pembayaran', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Jumlah', pL: '1px', pR: '10px', verticalAlign: 'top' },
                { label: 'Action', pL: '15px', verticalAlign: 'top' }
              ]}
            />
          </TableHead>
          
          <TableBody>
            {showSkeleton
              ? [1, 2, 3, 4, 5].map((value, index) => (
                  <BodyRow
                    index={index}
                    key={index}
                    isLoadingData={isLoadingData}
                    setSelectedId={setSelectedId}
                    transaction={dummyTransaction}
                    setOpenDialog={setOpenDialog}
                    setMessage={setMessage}
                    onEditJob={handleOnEdit(index)}
                    onViewJob={handleViewJob(index)}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    setDelete={setDelete}
                    addNewTransaction={addNewTransaction}
                  />
                ))
              : transactions.map((transaction, index) => (
                  <BodyRow
                    index={index}
                    key={transaction.id}
                    isLoadingData={isLoadingData}
                    setSelectedId={setSelectedId}
                    transaction={transaction}
                    setOpenDialog={setOpenDialog}
                    setMessage={setMessage}
                    onEditJob={handleOnEdit(index)}
                    onViewJob={handleViewJob(transaction.transactionId)}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    setDelete={setDelete}
                    addNewTransaction={addNewTransaction}
                  />
                ))}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[20]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </div>
    </Fragment>
  );
};

export default TransactionPageTable;
