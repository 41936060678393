import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { format, startOfMonth, lastDayOfMonth } from 'date-fns';
import { Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';
import CalendarIcon from '@material-ui/icons/EventNote';
import FilterIcon from '@material-ui/icons/FilterListRounded';
import PositionedPopper from 'components/PositionedPopper';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';

interface Props {
  isProcessing: boolean;
  query: string;
  employee: EmployeeModel;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterBy: string;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  columnFilter: ColumnFilter[];
  categories: Select[];
  paymentMethod: Select[];
  employees: Select[];
  handleOnSearch: (actionParam: any) => void;
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerFlex: {
    display: 'flex'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1.5)
  },
  toolbarTable: {
    backgroundColor: '#E7F4F8',
    paddingBottom: theme.spacing(0.9)
  },
  headerColor: {
    color: '#53A0BE'
  },
  icon: {
    fontSize: 20
  },
  calendarIcon: (props: Props) => ({
    fontSize: 20,
    color: props.filterBy ? theme.palette.primary.main : ''
  }),
  filterIcon: (props: Props) => ({
    fontSize: 20,
    color: props.columnFilter.length > 0 ? theme.palette.primary.main : ''
  }),
  leftHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  rightHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1.5)
  }
}));
const ToolBar: FC<Props> = props => {
  const classes = useStyles(props);

  const { employee } = props;
  const [openCalendarPopper, setOpenCalendarPopper] = useState(false);
  const [anchorElCalendarPopper, setAnchorElCalendarPopper] = useState<HTMLElement | null>(null);
  const [placementCalendarPopper, setPlacementCalendarPopper] = useState<PopperPlacementType>();
  const { categories, paymentMethod, employees} = props;

  const [openCheckboxMenusPopper, setOpenCheckboxMenusPopper] = useState(false);
  const [anchorElCheckboxMenusPopper, setAnchorElCheckboxMenusPopper] = useState<HTMLElement | null>(null);
  const [placementCheckboxMenusPopper, setPlacementCheckboxMenusPopper] = useState<PopperPlacementType>();

  const { isProcessing, setOpenDialog, setMessage, handleOnSearch } = props;
  const { query, setQuery } = props;
  const { filterBy, setFilterBy } = props;
  const { startDate, setStartDate } = props;
  const { endDate, setEndDate } = props;
  const { columnFilter, setColumnFilter } = props;

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCheckboxMenusPopper(false);
    setOpenCalendarPopper(!openCalendarPopper);
    setAnchorElCalendarPopper(event.currentTarget);
    setPlacementCalendarPopper('bottom-end');
  };

  const handleMenuListClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarPopper(false);
    setOpenCheckboxMenusPopper(!openCheckboxMenusPopper);
    setAnchorElCheckboxMenusPopper(event.currentTarget);
    setPlacementCheckboxMenusPopper('bottom-end');
  };
  const handleSearch = useCallback((searchQuery: string) => {
    handleOnSearch(searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  const renderHeaderLabel = () => {
    if (filterBy) {
      if (startDate && endDate) {
        return (
          <Typography variant='h6' color='primary'>
            (dari tanggal {format(new Date(startDate), 'dd/MM/yyyy')} - {format(new Date(endDate), 'dd/MM/yyyy')})
          </Typography>
        );
      }
    } else {
      return (
        <Typography variant='h6' color='primary'>
          (dari tanggal {format(new Date(startOfMonth(new Date())), 'dd/MM/yyyy')} - {format(new Date(lastDayOfMonth(new Date())), 'dd/MM/yyyy')})
        </Typography>
      );
    }
  };

  const renderLeftHeader = () => {
    return (
      <div className={classes.headerFlex}>
        <div className={classes.otherTextCell}>
          <div>
            <Typography variant='h5' color='primary'>
              Riwayat Transaksi - {employee.name}
            </Typography>
            {renderHeaderLabel()}
          </div>
        </div>
      </div>
    );
  };

  const renderRightHeader = () => {
      return (
        <Fragment>
          <PositionedPopper
            openPopper={openCalendarPopper}
            setOpenPopper={setOpenCalendarPopper}
            anchorEl={anchorElCalendarPopper}
            placement={placementCalendarPopper}
            containerWidth={200}
            fadeTransition={350}
            popperComponent='dateRangePicker'
            options={[
              { key: '1', label: 'Hari ini' },
              { key: '2', label: 'Minggu ini' },
              { key: '3', label: 'Bulan ini' },
              { key: '4', label: 'Pilih Tanggal' }
            ]}
            filterBy={filterBy ? filterBy : '3'}
            setFilterBy={setFilterBy}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <PositionedPopper
            openPopper={openCheckboxMenusPopper}
            setOpenPopper={setOpenCheckboxMenusPopper}
            anchorEl={anchorElCheckboxMenusPopper}
            placement={placementCheckboxMenusPopper}
            containerWidth={300}
            fadeTransition={350}
            popperComponent='checkBoxMenus'
            options={[
              { key: 'categoryId', label: 'Kategory', checkBoxList: categories },
              { key: 'paymentMethodId', label: 'Metode Pembayaran', checkBoxList: paymentMethod },
              { key: 'netCommissionId', label: 'Bagi Komisi', checkBoxList: employees.filter(value => value.id !== employee.id) }
            ]}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
          />
          <Tooltip title='Filter Kalender ' placement='top'>
            <IconButton size='small' onClick={event => handleCalendarFilterClick(event)}>
              <CalendarIcon className={classes.calendarIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Column filter' placement='top'>
            <IconButton size='small' onClick={event => handleMenuListClick(event)}>
              <FilterIcon className={classes.filterIcon} />
            </IconButton>
          </Tooltip>
        </Fragment>
      );
  };

  return (
    <Grid container spacing={2} >
      <Grid item xs={6}>
        <Grid container direction='row' justify='flex-start' alignItems='center' className={classes.leftHeader}>
          {renderLeftHeader()}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction='row' justify='flex-end' alignItems='center' className={classes.rightHeader}>
          {renderRightHeader()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToolBar;
