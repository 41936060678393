import React, { FC, useState, Fragment, useEffect, useContext, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import { Button, Grid, makeStyles, Theme, Container, Paper, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { GET_DETAIL_BY_EMPLOYEE } from 'constants/url';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import HistoryTransactionTable from './components/HistoryTransactionTable';
import { startOfMonth, lastDayOfMonth, startOfWeek, endOfWeek } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    margin: 'auto'
  }
}));

interface Props {
  employee: EmployeeModel;
}

const HistoryTransactionContent: FC<Props> = props => {
  useCurrentPageTitleUpdater('');
  const { employee } = props;
  const classes = useStyles();

  
  const [isSearchingHistoryTransaction, setSearchingHistoryTransaction] = useState<boolean>(false);
  const [historyTransactions, setHistoryTransactions] = useState<HistoryTransactionModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const [filterBy, setFilterBy] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);
  const [openCreateHistoryTransaction, setOpenCreateHistoryTransaction] = useState<boolean>(false);
  const [openEditHistoryTransaction, setOpenEditHistoryTransaction] = useState<boolean>(false);
  const [currentEditingHistoryTransactionIndex, setCurrentEditingHistoryTransactionIndex] = useState<number>(0);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [isSearchHistoryTransactionError, setSearchHistoryTransactionError] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [categories, setCategories] = useState<Select[]>([]);
  const [employees, setEmployes] = useState<Select[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<Select[]>([]);
 

  const { currentPageTitle } = useContext(CurrentPageContext);

  

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleCancelCreateHistoryTransaction = () => {
    setOpenCreateHistoryTransaction(false);
  };

  const addNewHistoryTransaction = (historyTransaction: HistoryTransactionModel) => {
    historyTransactions.unshift(historyTransaction);
    setHistoryTransactions([...historyTransactions]);
    setCount(c => c + 1);
  };

  const deleteIndividualHistoryTransaction = (historyTransactionIndex: number) => {
    historyTransactions.splice(historyTransactionIndex, 1);
    setHistoryTransactions([...historyTransactions]);
    setCount(c => c - 1);
  };

  const handleOpenEditHistoryTransaction = (historyTransactionIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingHistoryTransactionIndex(historyTransactionIndex);
    setOpenCreateHistoryTransaction(false);
    setOpenEditHistoryTransaction(true);
  };

  const handleCancelEditHistoryTransaction = () => {
    setOpenEditHistoryTransaction(false);
  };

  const updateIndividualHistoryTransaction = (updatedHistoryTransactionProperties: Partial<HistoryTransactionModel>, historyTransactionIndex?: number) => {
    let currentEditingIndex: number;
    if (historyTransactionIndex === undefined) {
      currentEditingIndex = currentEditingHistoryTransactionIndex;
    } else {
      currentEditingIndex = historyTransactionIndex;
    }
    setHistoryTransactions(
      historyTransactions!.map((historyTransaction, index) => {
        if (index !== currentEditingIndex) {
          return historyTransaction;
        }

        return Object.assign({}, historyTransaction, updatedHistoryTransactionProperties);
      })
    );
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const handleOpenCreateHistoryTransaction = () => {
    setOpenEditHistoryTransaction(false);
    setOpenCreateHistoryTransaction(true);
  }
  
  // Search HistoryTransaction whenever rowsPerPage, currentPage, queryString, contract, and filterby changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }
      
      if (columnFilter.length !== 0) {
        columnFilter.map(value => {
          if (value.columnName === 'netCommissionId') {
            return params.append('nc', value.columnValue.toString());
          } else if(value.columnName === 'paymentMethodId') {
            return params.append('pm', value.columnValue.toString());
          } else {
            return params.append('ci', value.columnValue.toString());
          }
        });
      }
      params.append('fb', 'createdAt');
      params.append('sd', startDate !== null ? format(new Date(startOfMonth(startDate)), 'yyyy-MM-dd').toString() : '');
      params.append('ed', endDate !== null ? format(new Date(lastDayOfMonth(endDate)), 'yyyy-MM-dd').toString() : '');

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchHistoryTransaction = async () => {
      setSearchingHistoryTransaction(true);
      setSearchHistoryTransactionError(false);

      try {
        const url = `${GET_DETAIL_BY_EMPLOYEE(employee.id)}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
       
        let categoriesData: CategoriesModel[] = [...data.categories];
        let paymentsMethodData: PaymentMethodModel[] = [...data.paymentMethod];
        let transactionsData: HistoryTransactionModel[] = [...data.transactions];
        let employeesData: EmployeeModel[] = [...data.employees];
        
        setCount(data.count);
        setHistoryTransactions(transactionsData);
        setCategories(categoriesData);
        setEmployes(employeesData);
        setPaymentMethod(paymentsMethodData);
      } catch (err) {
        setSearchHistoryTransactionError(true);
      }

      setSearchingHistoryTransaction(false);
      setDelete(false);
    };

    searchHistoryTransaction();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString, filterBy, columnFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Paper className={classes.paper}>
      <HistoryTransactionTable
        isLoadingData={isSearchingHistoryTransaction}
        employee={employee}
        historyTransactions={historyTransactions}
        count={count}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        query={query}
        setQuery={setQuery}
        categories={categories}
        paymentMethod={paymentMethod}
        employees={employees}
        handleOnSearch={event => performActionAndRevertPage(setQueryString, event)}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setColumnFilter={setColumnFilter}
        columnFilter={columnFilter}
        openCreateHistoryTransaction={openCreateHistoryTransaction}
        handleCancelCreateHistoryTransaction={handleCancelCreateHistoryTransaction}
        addNewHistoryTransaction={addNewHistoryTransaction}
        deleteIndividualHistoryTransaction={deleteIndividualHistoryTransaction}
        openEditHistoryTransaction={openEditHistoryTransaction}
        historyTransaction={historyTransactions[currentEditingHistoryTransactionIndex]}
        currentEditingHistoryTransactionIndex={currentEditingHistoryTransactionIndex}
        handleOpenEditHistoryTransaction={handleOpenEditHistoryTransaction}
        handleCancelEditHistoryTransaction={handleCancelEditHistoryTransaction}
        updateIndividualHistoryTransaction={updateIndividualHistoryTransaction}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
        handleOpenCreateHistoryTransaction={handleOpenCreateHistoryTransaction}
      />
    </Paper>
  );
};

export default HistoryTransactionContent;
