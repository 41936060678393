import React, { FC, Fragment, useState, useEffect, useContext, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import { Container, Typography, Theme, makeStyles, Table, TableBody, TableHead } from '@material-ui/core';
import TablePagination from 'components/TablePagination';
import ToolBar from './components/ToolBar';
import { PRODUCT_SERVICE_BASE_URL, GET_EDIT_PRODUCT_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  employee: EmployeeModel;
  payrolls: PayrollModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  handleOnSearch: (actionParam: any) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterBy: string;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  columnFilter: ColumnFilter[];
  openCreatePayroll: boolean;
  handleCancelCreatePayroll: () => void;
  openEditPayroll: boolean;
  payroll?: PayrollModel;
  currentEditingPayrollIndex: number;
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  handleOpenEditPayroll: (payrollIndex: number) => React.MouseEventHandler;
  handleCancelEditPayroll: () => void;
  addNewPayroll(payroll: PayrollModel[]): void;
  updateIndividualPayroll: (updatedPayrollProperties: Partial<PayrollModel>, payrollIndex?: number) => void;
  deleteIndividualPayroll: (payrollIndex: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleOpenCreatePayroll: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    tableWrapper: {
      overflowX: 'auto'
    },
    headerPageTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(0)
    }
  })
);

const PayrollTable: FC<Props> = props => {
  const classes = useStyles();
  
  const { employee } = props;

  const {
    isLoadingData,
    payrolls,
    count,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    openEditPayroll,
    payroll,
    handleOpenEditPayroll,
    updateIndividualPayroll,
    deleteIndividualPayroll,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    addNewPayroll,
    handleOnSearch
  } = props;
  
  const { query, setQuery } = props;
  const { filterBy, setFilterBy } = props;
  const { startDate, setStartDate } = props;
  const { endDate, setEndDate } = props;
  const { columnFilter, setColumnFilter } = props;

  const dummyPayroll: PayrollModel = {
    id: 0,
    idPayroll: '',
    monthYear: '',
    totalCommission: 0,
    totalCommissionTemp: 0,
    totalDeduction: 0,
    totalDeductionTemp: 0,
    basicSalary: 0,
    basicSalaryTemp: 0,
    netSalary: 0,
    netSalaryTemp: 0,
    isMonthEnd: false,
    allowancePosition: 0,
    allowanceTransport: 0,
    allowanceCraft: 0,
    allowanceMealt: 0,
    allowanceHealth: 0,
    allowanceHoliday: 0,
    reCalculation: 0,
    noteReCalculation: '',
    notes: '',
    createdAt: new Date(),
    updatedAt: new Date()
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  
  const [CategoryId, setCategoryId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [totalTransaction, setSellingPrice] = useState<number>(0);
  const [barcode, setBarcode] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  
  const resetEditFormValues = useCallback(() => {
    if (!payroll) {
      return;
    }
    
  }, [payroll]);

  useEffect(() => {
    if (!openEditPayroll) {
      let timeout: NodeJS.Timeout;

      if (isLoadingData) {
        timeout = setTimeout(() => {
          setShowSkeleton(true);
        }, 500);
      }

      setShowSkeleton(false);
      resetInputFormValues();

      return () => {
        clearTimeout(timeout);
      };
    } else {
      resetEditFormValues();
    }
    
  }, [openEditPayroll, isLoadingData, resetEditFormValues]);

  const resetInputFormValues = () => {
    setCategoryId(0);
    setName('');
    setSellingPrice(0);
    setBarcode('');
    setNotes('');
  };
  
  return (
    <Fragment>
      <Container className={classes.headerPageTitleContainer}>
        <ToolBar
          isProcessing={isProcessing}
          employee={employee}
          payrolls={payrolls}
          query={query}
          setQuery={setQuery}
          filterBy={filterBy}
          handleOnSearch={handleOnSearch}
          setFilterBy={setFilterBy}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          setOpenDialog={setOpenDialog}
          setMessage={setMessage}
        />
      </Container>
      <div className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <HeaderRow
              headers={[
                { label: 'ID', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Bulan & Tahun', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Gaji/Tunjangan', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Komisi', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Potongan', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Total Gaji', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Catatan', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Action', pL: '10px', pR: '10px', verticalAlign: 'top' },
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton
            ? [1, 2, 3, 4, 5, 6].map(index => (
                <BodyRow
                  index={index}
                  key={index}
                  payroll={dummyPayroll}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  handleSetMessageSuccess={handleSetMessageSuccess}
                  handleSetMessageError={handleSetMessageError}
                  deleteIndividualPayroll={deleteIndividualPayroll}
                  addNewPayroll={addNewPayroll}
                  onEditPayroll={handleOpenEditPayroll(index)}
                  employee={employee}
                  updatePayroll={updateIndividualPayroll}
                  isLoadingData={isLoadingData}
                />
              ))
            : payrolls.map((payroll, index) =>
                  <BodyRow
                    index={index}
                    key={payroll.id}
                    payroll={payroll}
                    setOpenSnackbar={setOpenSnackbar}
                    addNewPayroll={addNewPayroll}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    deleteIndividualPayroll={deleteIndividualPayroll}
                    onEditPayroll={handleOpenEditPayroll(index)}
                    employee={employee}
                    updatePayroll={updateIndividualPayroll}
                    isLoadingData={isLoadingData}
                  />
              )}
          </TableBody>           
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </div>
    </Fragment>
  );
}

export default PayrollTable;