import React, { FC, useState, useContext, useEffect, useCallback, useReducer, Reducer } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Container, Divider, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ActionSnackbar from 'components/ActionSnackBar';
import ErrorIcon from '@material-ui/icons/Error';
import { PRODUCT_SERVICE_BASE_URL } from 'constants/url';

import ProductServiceTable from './components/ProductServiceTable';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    margin: 'auto'
  },
  subMenuGrid: {
    borderRight: '1px solid #dcdcdc',
    maxWidth: theme.spacing(15)
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.2)
  },
  headerSubMenuTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0)
  },
  headerPageTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2)
  },
  contentContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2)
  }
}));

interface IState {
  selectedTab: number;
}

interface IAction {
  type: string;
  value?: number;
}

const initialState: IState = {
  selectedTab: 0
};

const reducer = (state: IState, action: IAction) => {
  if (action.type === "reset") {
      return initialState;
  }

  const result: IState = { ...state };
  return result;
};

const ProductService: FC = () => {
  useCurrentPageTitleUpdater('Pengaturan Produk & Servis');

  const classes = useStyles();

  const [state, dispatch] = useReducer<Reducer<IState, IAction>, IState>(reducer, initialState, () => initialState);
  const [categories, setCategories] = useState<Select[]>([]);
  const [isSearchingProductService, setSearchingProductService] = useState<boolean>(false);
  const [productServices, setProductServices] = useState<ProductServicesModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const [filterBy, setFilterBy] = useState<string>('');
  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);
  const [openCreateProductService, setOpenCreateProductService] = useState<boolean>(false);
  const [openEditProductService, setOpenEditProductService] = useState<boolean>(false);
  const [currentEditingProductServiceIndex, setCurrentEditingProductServiceIndex] = useState<number>(0);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [isSearchProductServiceError, setSearchProductServiceError] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [checked, setChecked] = useState<number[]>([]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleCancelCreateProductService = () => {
    setOpenCreateProductService(false);
  };

  const addNewProductService = (productService: ProductServicesModel) => {
    productService.new = true;
    productServices.unshift(productService);
    setProductServices([...productServices]);
    setCount(c => c + 1);
  };

  const deleteIndividualProductService = (productServiceIndex: number) => {
    productServices.splice(productServiceIndex, 1);
    setProductServices([...productServices]);
    setCount(c => c - 1);
  };

  const handleOpenEditProductService = (productServiceIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingProductServiceIndex(productServiceIndex);
    setOpenCreateProductService(false);
    setOpenEditProductService(true);
  };

  const handleCancelEditProductService = () => {
    setOpenEditProductService(false);
  };

  const updateIndividualProductService = (updatedProductServiceProperties: Partial<ProductServicesModel>, productServiceIndex?: number) => {
    let currentEditingIndex: number;
    if (productServiceIndex === undefined) {
      currentEditingIndex = currentEditingProductServiceIndex;
    } else {
      currentEditingIndex = productServiceIndex;
    }
    setProductServices(
      productServices!.map((productService, index) => {
        if (index !== currentEditingIndex) {
          return productService;
        }

        return Object.assign({}, productService, updatedProductServiceProperties);
      })
    );
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const handleOpenCreateProductService = () => {
    setOpenEditProductService(false);
    setOpenCreateProductService(true);
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Search ProductService whenever rowsPerPage, currentPage, queryString, contract, and filterby changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    dispatch({ type: "reset" });
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      if (filterBy) {
        params.append('fb', filterBy.toString());
      }

      if (columnFilter.length !== 0) {
        columnFilter.map(value => {
          if (value.columnName === 'clientId') {
            return params.append('ci', value.columnValue.toString());
          } else {
            return params.append('ei', value.columnValue.toString());
          }
        });
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchProductService = async () => {
      setSearchingProductService(true);
      setSearchProductServiceError(false);

      try {
        const url = `${PRODUCT_SERVICE_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        
        setCount(data.count);
        setProductServices(data.products);
        setCategories(data.categories);
      } catch (err) {
        if (axios.isCancel(err)) {
          setSearchProductServiceError(true);
        } else {
          setSearchProductServiceError(true);
        }
        
      }

      setSearchingProductService(false);
      setDelete(false);
    };

    searchProductService();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString, filterBy, columnFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Paper className={classes.paper}>
      <ProductServiceTable
        categories={categories}
        isLoadingData={isSearchingProductService}
        productServices={productServices}
        count={count}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        query={query}
        setQuery={setQuery}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        setColumnFilter={setColumnFilter}
        columnFilter={columnFilter}
        openCreateProductService={openCreateProductService}
        handleCancelCreateProductService={handleCancelCreateProductService}
        addNewProductService={addNewProductService}
        handleOnSearch={event => performActionAndRevertPage(setQueryString, event)}
        deleteIndividualProductService={deleteIndividualProductService}
        openEditProductService={openEditProductService}
        productService={productServices[currentEditingProductServiceIndex]}
        currentEditingProductServiceIndex={currentEditingProductServiceIndex}
        handleOpenEditProductService={handleOpenEditProductService}
        handleCancelEditProductService={handleCancelEditProductService}
        updateIndividualProductService={updateIndividualProductService}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
        handleOpenCreateProductService={handleOpenCreateProductService}
        checked={checked}
        setChecked={setChecked}
      />
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
    </Paper>
  );
};

export default ProductService;
