import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import { Grid, Theme, Table, TableBody, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TableCell from '@material-ui/core/TableCell';
import NumberFormat from 'react-number-format';
import logo from '../../../../../src/images/darwin-dashboard-logo02.png';

interface Props {
  paymentMethod: string;
  tender: number;
  notes: string;
  addTransactionColumn: TransactionModel[];
  transaction: TransactionDetailModel;
  transactions: TransactionModel[];
  dateNow: string;
  subTotal: number;
  totalDiscount: number;
  uniqueEmployeeNumbers: string[];
  componentRef: React.MutableRefObject<any>;
}

const useStyles = makeStyles((theme: Theme) => ({
  rincian: {
    fontSize: 10
  },
  icon: {
    fontSize: 40
  },
  typoText: {
    color: theme.palette.common.black
  },
  textInfoRight: {
    textAlign: 'right',
    fontSize: 9
  },
  textFieldFont: {
    fontSize: 9
  },
  GridLogo: {
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  TextInfo: {
    textAlign: 'right',
    fontSize: 9
  },
  textNotes: {
    marginLeft: theme.spacing(2),
    fontSize: 9
  },
  textValue: {
    fontSize: 9,
    marginLeft: theme.spacing(1)
  },
  textheader: {
    fontSize: 9
  },
  address: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
    fontSize: 9
  }
}));

const Print: React.FC<Props> = props => {
  const classes = useStyles();
  const {
    paymentMethod,
    tender,
    notes,
    addTransactionColumn,
    transaction,
    transactions,
    dateNow,
    subTotal,
    totalDiscount,
    uniqueEmployeeNumbers,
    componentRef
  } = props;

  const [categoryName, setCategoryName] = useState<string[]>([]);

  useEffect(() => {
    const tempCategory = addTransactionColumn
      .map((item: any) => item.categoryName)
      .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
    setCategoryName(tempCategory);
  }, [transactions]);

  return (
    <Grid ref={componentRef}>
      <div className={classes.paper}>
        <Grid item xs={12} className={classes.GridLogo}>
          <img src={logo} alt='logo' width='150'/>
        </Grid>
      </div>
      <Grid container>
        <Grid container item justify='center'>
          <Typography className={classes.TextInfo}>{dateNow}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.TextInfo}>Transaction ID : </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.textValue}>{transaction.refId}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.TextInfo}>Nama Pelanggan : </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.textValue}>{transaction.Customer && (`${transaction.Customer.name} - ${transaction.Customer.contactNumber}`)}</Typography>
        </Grid>
      </Grid>
      <Table size='small' style={{ minWidth: 300, marginTop: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell align='center' className={classes.textheader}>
              Item
            </TableCell>
            <TableCell align='center' className={classes.textheader}>
              Diskon
            </TableCell>
            <TableCell align='center' className={classes.textheader}>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {transactions &&
          transactions.map((tempTransaction, i) =>
            !tempTransaction && tempTransaction!.productPrice ? null : (
              <React.Fragment>
                <TableRow key={i}>
                  <TableCell colSpan={3} style={{fontSize: 9, fontWeight: 'bold'}}>
                    {categoryName[i]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rincian}>
                    <Typography style={{fontSize: 9}}>{tempTransaction.productName}</Typography>
                    <Typography style={{fontSize: 9}}>
                      {tempTransaction.qty} X{' '}
                      <NumberFormat value={tempTransaction.productPrice} style={{fontSize: 9}} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.rincian}>
                    <NumberFormat value={tempTransaction.discountAmount} style={{fontSize: 9}} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                  </TableCell>
                  <TableCell className={classes.rincian}>
                    <NumberFormat
                      value={tempTransaction.qty * tempTransaction.productPrice - tempTransaction.discountAmount}
                      style={{fontSize: 9}}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'Rp'}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          )}
          <TableRow>
            <TableCell className={classes.rincian} colSpan={2}>
              <Typography style={{fontSize: 9}}>SUBTOTAL:</Typography>
              <Typography style={{fontSize: 9}}>DISKON:</Typography>
            </TableCell>
            <TableCell className={classes.rincian}>
              <Typography>
                <NumberFormat value={subTotal} style={{fontSize: 9}} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
              <Typography>
                <NumberFormat value={totalDiscount} style={{fontSize: 9}} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} className={classes.rincian}>
              <Typography style={{fontSize: 9}}>GRAND TOTAL</Typography>
              <Typography style={{fontSize: 9}}>METODE PEMBAYARAN</Typography>
              <Typography style={{fontSize: 9}}>{paymentMethod == 'TUNAI' && paymentMethod}</Typography>
              <Typography style={{fontSize: 9}}>{tender > 0 && 'KEMBALIAN'}</Typography>
              <Typography style={{fontSize: 9}}>{paymentMethod !== 'TUNAI' && transaction.nameBank}</Typography>
            </TableCell>
            <TableCell className={classes.rincian}>
              <Typography>
                <NumberFormat value={subTotal - totalDiscount} style={{fontSize: 9}} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
              <Typography style={{fontSize: 9}}> {paymentMethod} </Typography>
              <Typography style={{fontSize: 9}}>
                {paymentMethod == 'TUNAI' && <NumberFormat value={tender} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />}
              </Typography>
              <Typography>
                {tender > 0 && (
                  <NumberFormat value={tender - (subTotal - totalDiscount)} style={{fontSize: 9}} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                )}
              </Typography>
              <Typography style={{fontSize: 9}}>
                {paymentMethod !== 'TUNAI' && (
                  transaction.numberBank
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} className={classes.rincian}>
              <Typography style={{fontSize: 9}}>Catatan: {transaction.notes}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Typography style={{ textAlign: 'center' }} className={classes.TextInfo}>
          Terima kasih atas kunjungan Anda.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 10}}>
        <Typography className={classes.address}>
          Darwin Salon <br />
          Jl. Tengku Zainal Abidin No.16E, Sekip, Kec. Lima Puluh, Kota Pekanbaru, Riau 28112 <br />
          Tel: (0761) 858877
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Print;
