import React, { useEffect, Fragment, useState, useRef } from 'react';
import {
  Grid,
  TextField,
  Button,
  Theme,
  Typography,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import HeaderRow from 'components/HeaderRow';
import Locale from 'date-fns/esm/locale/id';
import BodyCell from 'components/BodyCell';
import logo from '../../../../../../../../../../../../../images/logodar.png';
import Print from './Print';

interface Props {
  okLabel: 'Cetak';
  historyTransactions: HistoryTransactionModel[];
  employees: EmployeeModel[];
  employee: EmployeeModel;
  oldPayroll: PayrollModel;
  noteReCalculation: string;
  notes: string;
  addAllowanceDeduction: AllowanceDeductionModel[];
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  addButton: {
    right: 140,
    marginTop: theme.spacing(2),
    color: '#FFFFFF'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#53a0be',
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1)
  },
  GridLogo: {
    width: 217,
    position: 'relative',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  rincian: {
    backgroundColor: '#e9e9e9',
    left: 70,
    textAlign: 'center'
  },
  slipGaji: {
    backgroundColor: '#e9e9e9',
    left: 70,
    textAlign: 'center'
  },
  tableRincian: {
    fontWeight: 'bold',
    fontSize: 12,
    width: 340,
    textAlign: 'center'
  },
  TextInfo: {
    fontSize: 12,
    textAlign: 'left'
  },
  textGaji: {
    fontWeight: 'bold',
    fontSize: 12,
    width: 340
  },
  textInfoItalic: {
    fontStyle: 'italic',
    textAlign: 'left',
    fontSize: 11
  },
  textInfoRight: {
    textAlign: 'right',
    fontStyle: 'bold',
    fontSize: 12
  },
  textInfoItalicRight: {
    fontStyle: 'italic',
    textAlign: 'right',
    fontSize: 11
  },
  textInfoBoldRight: {
    fontStyle: 'bold',
    textAlign: 'right',
    fontSize: 11
  },
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  tableRow: {
    height: 23
  }
}));

const ReportPayslip: React.FC<Props> = props => {
  const classes = useStyles();

  const { isSubmitting, onSubmit, onCancel, okLabel } = props;
  const { notes, noteReCalculation, employee, historyTransactions, oldPayroll, addAllowanceDeduction, employees } = props;
  const [newHistoryTransactions, setNewHistoryTransactions] = useState<HistoryTransactionModel[]>([]);
  const [detailCommisionShares, setDetailCommisionShares] = useState<HistoryTransactionModel[]>([]);

  useEffect(() => {
    if (historyTransactions.length === 0) {
      return;
    }
    
    const renderHistoryTransaction = () => {
      let newHistoryTransactions = historyTransactions.reduce((ar: HistoryTransactionModel[], obj) => {
        let bool = false;
        if (!ar) {
          ar = [];
        }
        ar.map(value => {
          if (value.productName === obj.productName) {
            value.qtyTemp += obj.qty;
            if(value.netCommissionTemp) value.netCommissionTemp += obj.netCommission;
            bool = true;
          }
        });
        if (!bool) {
          obj.qtyTemp = obj.qty;
          obj.netCommissionTemp = obj.netCommission;
          ar.push(obj);
        }
        return ar;
      }, []);
      
      setNewHistoryTransactions(newHistoryTransactions);
      
      const forCommissions = historyTransactions.filter((ar,index) => {
        if (ar.EmployeeDetails.length > 1) {
          ar.shareId = '';
          
          ar.EmployeeDetails.map((x, employeeIndex) => {
            ar.shareId += `${ar.EmployeeDetails[employeeIndex + 1] ? x.employeeNumber+', ' : x.employeeNumber}`;
          });
          return ar;
        }
      }, []);

      setDetailCommisionShares(forCommissions);
    };

    renderHistoryTransaction();
  }, [historyTransactions, oldPayroll]);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page {
      size: auto;   /* auto is the initial value */ 

      /* this affects the margin in the printer settings */ 
      margin: 25mm 25mm 25mm 25mm;  
      }`
  });

  return (
    <form noValidate onSubmit={onSubmit}>
      <div style={{ display: 'none' }}>
        <Print
          historyTransactions={historyTransactions}
          employee={employee}
          employees={employees}
          noteReCalculation={noteReCalculation}
          notes={notes}
          oldPayroll={oldPayroll}
          addAllowanceDeduction={addAllowanceDeduction}
          componentRef={componentRef}
        />
      </div>
      <Grid item sm={12}>
        <Grid className={classes.contentGrid}>
          <div className={classes.paper}>
            <Grid item xs={12} className={classes.GridLogo}>
              <img src={logo} alt='logo' width='200' height='50' />
            </Grid>
          </div>
          <Grid item xs={12} className={classes.rincian}>
            <Typography variant='h6' id='modal-title' color='primary'>
              RINCIAN TRANSAKSI
            </Typography>
          </Grid>
          <Table>
            <TableHead>
              <HeaderRow
                headers={[
                  { label: 'KATEGORI', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'ITEM', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'QTY', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'KOMISI', pL: '10px', pR: '10px', verticalAlign: 'top' }
                ]}
              />
            </TableHead>
            <TableBody>
              {newHistoryTransactions &&
                newHistoryTransactions.map((historyTransaction, index) =>
                  (
                    <Fragment key={index}>
                      <TableRow>
                        <BodyCell cellWidth='10%' pT='10px' pB='10px'>{historyTransaction.categoryName}</BodyCell>
                        <BodyCell cellWidth='10%' pT='10px' pB='10px'>{historyTransaction.productName}</BodyCell>
                        <BodyCell cellWidth='10%' pT='10px' pB='10px'>{historyTransaction.qtyTemp}</BodyCell>
                        <BodyCell cellWidth='10%'>
                          <NumberFormat
                            value={historyTransaction.netCommissionTemp}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Rp'}
                          />
                        </BodyCell>
                      </TableRow>
                    </Fragment>
                  ))}
            </TableBody>
          </Table>
          <Grid item xs={12} className={classes.rincian}>
            <Typography variant='h6' id='modal-title' color='primary'>
              RINCIAN BAGI KOMISI
            </Typography>
          </Grid>
          <Table>
            <TableHead>
              <HeaderRow
                headers={[
                  { label: 'HARI', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'KATEGORI', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'ITEM', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'QTY', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'Share Id', pL: '15px', pR: '15px', verticalAlign: 'top' },
                  { label: 'KOMISI', pL: '10px', pR: '10px', verticalAlign: 'top' }
                ]}
              />
            </TableHead>
            <TableBody>
              {detailCommisionShares &&
                detailCommisionShares.map((detailCommisionShare, index) =>
                  (
                    <Fragment key={index}>
                      <TableRow>
                        <BodyCell cellWidth='10%' pT='10px' pB='10px'>{format(new Date(detailCommisionShare.createdAt), 'cccc, dd/MM/yyyy', { locale: Locale }).toString()}</BodyCell>
                        <BodyCell cellWidth='10%' pT='10px' pB='10px'>{detailCommisionShare.categoryName}</BodyCell>
                        <BodyCell cellWidth='10%' pT='10px' pB='10px'>{detailCommisionShare.productName}</BodyCell>
                        <BodyCell cellWidth='10%' pT='10px' pB='10px'>{detailCommisionShare.qty}</BodyCell>
                        <BodyCell cellWidth='10%' pT='10px' pB='10px'>{detailCommisionShare.shareId}</BodyCell>
                        <BodyCell cellWidth='10%'>
                          <NumberFormat
                            value={detailCommisionShare.netCommission}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Rp'}
                          />
                        </BodyCell>
                      </TableRow>
                    </Fragment>
                  ))}
            </TableBody>
          </Table>
          <Grid item xs={12} className={classes.slipGaji}>
            <Typography variant='h6' id='modal-title' color='primary'>
              SLIP GAJI - {oldPayroll.idPayroll}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.TextInfo}>
                Nama Karyawan :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoRight}>
                {employee.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.TextInfo}>
                ID Karyawan :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoRight}>
                {employee.employeeNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.TextInfo}>
                Bulan :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoRight}>
                {oldPayroll.monthYear ? format(new Date(oldPayroll.monthYear), 'MMMM yyyy', { locale: Locale }).toString() : ''}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Typography variant='h6' className={classes.TextInfo}>
            GAJI/TUNJANGAN
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalic}>
                -Jabatan
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalicRight}>
                <NumberFormat value={oldPayroll.isMonthEnd ? oldPayroll.allowancePosition : (addAllowanceDeduction[0] && addAllowanceDeduction[0].amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalic}>
                -Transport
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalicRight}>
                <NumberFormat value={oldPayroll.isMonthEnd ? oldPayroll.allowanceTransport : (addAllowanceDeduction[1] && addAllowanceDeduction[1].amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalic}>
                -Kerajinan
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalicRight}>
                <NumberFormat value={oldPayroll.isMonthEnd ? oldPayroll.allowanceCraft : (addAllowanceDeduction[2] && addAllowanceDeduction[2].amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalic}>
                -Makan
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalicRight}>
                <NumberFormat value={oldPayroll.isMonthEnd ? oldPayroll.allowanceMealt : (addAllowanceDeduction[3] && addAllowanceDeduction[3].amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalic}>
                -Kesehatan
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalicRight}>
                <NumberFormat value={oldPayroll.isMonthEnd ? oldPayroll.allowanceHealth : (addAllowanceDeduction[4] && addAllowanceDeduction[4].amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalic}>
                -THR
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalicRight}>
                <NumberFormat value={oldPayroll.isMonthEnd ? oldPayroll.allowanceHoliday : (addAllowanceDeduction[5] && addAllowanceDeduction[5].amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalic}>
                KOMISI
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalicRight}>
                <NumberFormat value={oldPayroll.totalCommission} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalic}>
                POTONGAN + Pinjaman
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoItalicRight}>
                <NumberFormat value={oldPayroll.isMonthEnd ? oldPayroll.totalDeduction : addAllowanceDeduction[6] && addAllowanceDeduction[6].amount} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textGaji}>
                TOTAL GAJI
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.textInfoBoldRight}>
                <NumberFormat value={oldPayroll.netSalary} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />
          <Grid item xs={12}>
            <TextField
              margin='dense'
              fullWidth
              disabled
              id='notes'
              value={oldPayroll.isMonthEnd ? oldPayroll.notes : notes ? notes : '-' }
              label='Catatan Umum'
              variant='outlined'
              autoComplete='off'
              multiline
              rowsMax='4'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
          {oldPayroll.reCalculation > 0 && (
            <Grid item xs={12}>
              <TextField
                margin='dense'
                fullWidth
                disabled
                id='notesReCalculation'
                value={oldPayroll.isMonthEnd ? oldPayroll.noteReCalculation : noteReCalculation ? noteReCalculation : '-'}
                label='Catatan Potongan'
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  }
                }}
                InputLabelProps={{
                  className: classes.textFieldFont
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
            Selesai
          </Button>
          <Button type='submit' variant='contained' color='primary' disabled={isSubmitting} onClick={handlePrint}>
            {okLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReportPayslip;
