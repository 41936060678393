import React, { FC, Fragment, useState, useEffect, useContext, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import { Container, Typography, Theme, makeStyles, Table, TableBody, TableHead } from '@material-ui/core';
import TablePagination from 'components/TablePagination';
import ToolBar from './components/ToolBar';


interface Props {
  isLoadingData: boolean;
  employee: EmployeeModel;
  historyTransactions: HistoryTransactionModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterBy: string;
  categories: Select[];
  paymentMethod: Select[];
  employees: Select[];
  handleOnSearch: (actionParam: any) => void;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  columnFilter: ColumnFilter[];
  openCreateHistoryTransaction: boolean;
  handleCancelCreateHistoryTransaction: () => void;
  openEditHistoryTransaction: boolean;
  historyTransaction?: HistoryTransactionModel;
  currentEditingHistoryTransactionIndex: number;
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  handleOpenEditHistoryTransaction: (historyTransactionIndex: number) => React.MouseEventHandler;
  handleCancelEditHistoryTransaction: () => void;
  addNewHistoryTransaction(historyTransaction: HistoryTransactionModel): void;
  updateIndividualHistoryTransaction: (updatedHistoryTransactionProperties: Partial<HistoryTransactionModel>, historyTransactionIndex?: number) => void;
  deleteIndividualHistoryTransaction: (historyTransactionIndex: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleOpenCreateHistoryTransaction: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    tableWrapper: {
      overflowX: 'auto'
    },
    headerPageTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(0)
    }
  })
);

const HistoryTransactionTable: FC<Props> = props => {
  const classes = useStyles();
  const { employee } = props;

  let cancelTokenSource: CancelTokenSource;

  const {
    isLoadingData,
    historyTransactions,
    count,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleCancelCreateHistoryTransaction,
    openEditHistoryTransaction,
    categories,
    paymentMethod,
    employees,
    historyTransaction,
    handleOpenEditHistoryTransaction,
    handleCancelEditHistoryTransaction,
    updateIndividualHistoryTransaction,
    deleteIndividualHistoryTransaction,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    addNewHistoryTransaction,
    handleOnSearch
  } = props;
  
  const { query, setQuery } = props;
  const { filterBy, setFilterBy } = props;
  const { startDate, setStartDate } = props;
  const { endDate, setEndDate } = props;
  const { columnFilter, setColumnFilter } = props;

  const dummyHistoryTransaction: HistoryTransactionModel = {
    ietId: 0,
    transactionId: 1,
    refId: '',
    categoryName: '',
    productName: '',
    EmployeeName: '',
    paymentMethod: '',
    productPrice: 0,
    EmployeeDetails: [],
    netCommission: 0,
    ProductMergers: [],
    qty: 0,
    qtyTemp: 0,
    totalTransaction: 0,
    commission: 0,
    commissionTemp: 0,
    createdAt: new Date()
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  
  const [CategoryId, setCategoryId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [totalTransaction, setSellingPrice] = useState<number>(0);
  const [barcode, setBarcode] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  
  const resetEditFormValues = useCallback(() => {
    if (!historyTransaction) {
      return;
    }
    
    const { totalTransaction } = historyTransaction;

    setName(name);
    setSellingPrice(totalTransaction);
    setBarcode(barcode);
    setNotes(notes);
    setCategoryId(CategoryId === null ? 0 : CategoryId);
  }, [historyTransaction]);

  useEffect(() => {
    if (!openEditHistoryTransaction) {
     let timeout: NodeJS.Timeout;
      if (isLoadingData) {
        timeout = setTimeout(() => {
          setShowSkeleton(true);
        }, 500);
      }

      setShowSkeleton(false);
      resetInputFormValues();

      return () => {
        clearTimeout(timeout);
      };
    } else {
      resetEditFormValues();
    }
    
  }, [openEditHistoryTransaction, isLoadingData, resetEditFormValues]);

  const resetInputFormValues = () => {
    setCategoryId(0);
    setName('');
    setSellingPrice(0);
    setBarcode('');
    setNotes('');
  };

  return (
    <Fragment>
      <Container className={classes.headerPageTitleContainer}>
        <ToolBar
          isProcessing={isProcessing}
          employee={employee}
          query={query}
          setQuery={setQuery}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate} 
          categories={categories}
          employees={employees}
          paymentMethod={paymentMethod}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          setOpenDialog={setOpenDialog}
          setMessage={setMessage}
          handleOnSearch={handleOnSearch}
        />
      </Container>
      <div className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <HeaderRow
              headers={[
                { label: 'Ref ID', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Hari & tanggal', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Kategori', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Item', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Metode Pembayaran', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Harga', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Qty', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Komisi', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Bagi Komisi', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'NET Komisi', pL: '10px', pR: '10px', verticalAlign: 'top' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton
            ? [1, 2, 3, 4, 5].map(index => (
                <BodyRow
                  index={index}
                  key={index}
                  historyTransaction={dummyHistoryTransaction}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  handleSetMessageSuccess={handleSetMessageSuccess}
                  handleSetMessageError={handleSetMessageError}
                  deleteIndividualHistoryTransaction={deleteIndividualHistoryTransaction}
                  onEditHistoryTransaction={handleOpenEditHistoryTransaction(index)}
                  openEditHistoryTransaction={openEditHistoryTransaction}
                  updateHistoryTransaction={updateIndividualHistoryTransaction}
                  isLoadingData={isLoadingData}
                />
              ))
            : historyTransactions.map((historyTransaction, index) =>
                  <BodyRow
                    index={index}
                    key={historyTransaction.ietId}
                    historyTransaction={historyTransaction}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    deleteIndividualHistoryTransaction={deleteIndividualHistoryTransaction}
                    onEditHistoryTransaction={handleOpenEditHistoryTransaction(index)}
                    openEditHistoryTransaction={openEditHistoryTransaction}
                    updateHistoryTransaction={updateIndividualHistoryTransaction}
                    isLoadingData={isLoadingData}
                  />
              )}
          </TableBody>           
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </div>
    </Fragment>
  );
}

export default HistoryTransactionTable;