import React, { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Container, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import useRouter from 'hooks/useRouter';
import CustomizedTabs from 'components/CustomizedTabs';
import ProductService from './components/ProductService';
import Employee from './components/Employee';
import InformationContent from '../../components/InformationContent';
import Locale from 'date-fns/esm/locale/id';
import axios, { CancelTokenSource } from 'axios';
import { TRANSACTION_BASE_INFO_URL } from 'constants/url';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  divider: {
    marginBottom: theme.spacing(4)
  },
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    margin: 'auto'
  },
  subMenuGrid: {
    borderRight: '1px solid #dcdcdc',
    maxWidth: theme.spacing(15)
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.2)
  },
  headerSubMenuTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0)
  },
  headerPageTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2)
  },
  contentContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2)
  },
  spacing: {
    marginLeft: theme.spacing(1)
  }
}));

const SettingsPage: FC = () => {
  useCurrentPageTitleUpdater('Pengaturan Produk & Servis');

  const classes = useStyles();
  const { history } = useRouter();
  const state = history.location.state as any;

  const [selectedTab, setSelectedTab] = useState<number>(!state ? 0 : state.selectedTab);
  const [totalMonthTransaction, setTotalMonthTransaction] = useState<number>(0);
  const [totalDayTransaction, setTotalDayTransaction] = useState<number>(0);
  const [countDayTransaction, setCountDayTransaction] = useState<number>(0);
  const [totalOmsetThisMonth, setTotalOmsetThisMonth] = useState<number>(0);
  const [jobEmployees, setJobEmployes] = useState<EmployeeModel[]>([]);
  const [transactionToday, setTransactionToday] = useState<number>(0);
  const [totalOmsetToday, setTotalOmsetToday] = useState<number>(0);
  const [transactions, setTransactions] = useState<TransactionDetailModel[]>([]);
  const [checked, setChecked] = useState<number[]>([]);
  const [count, setCount] = useState<number>(0);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    action(actionParam);
  };

  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getTransactionInformation = async () => {
      try {
        const url = `${TRANSACTION_BASE_INFO_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        let transactionInfosData: TransactionInformationModel = data.row;
        setTotalMonthTransaction(transactionInfosData.totalMonthTransaction);
        setTotalDayTransaction(transactionInfosData.totalDayTransaction);
        setCountDayTransaction(transactionInfosData.countDayTransaction);
      } catch (err) {
        console.log(err);
      }
    };

    getTransactionInformation();
    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addNewTransaction = (transactionData: TransactionDetailModel) => {
    let newTotalOmsetThisMonth = totalOmsetThisMonth;
    let newTotalOmsetToday = totalOmsetToday;

    transactionData.new = true;
    newTotalOmsetToday += transactionData.totalTransaction;
    newTotalOmsetThisMonth += transactionData.totalTransaction;
    transactions.unshift(transactionData);
   
    setTransactions([...transactions]);
    setCount(c => c + 1);

    setTotalOmsetThisMonth(newTotalOmsetThisMonth);
    setTransactionToday((Number(transactionToday) + 1));
    setTotalOmsetToday(newTotalOmsetToday);
  };

  const detailDate = format(new Date(), 'cccc, dd MMMM yyyy', { locale: Locale }).toString();
  const renderGreeting = () => {
    const now = format(new Date(), 'aa').toString();
    if (now === 'AM') {
      return 'Selamat Pagi,';
    } else if (now === 'PM') {
      return 'Selamat Sore,';
    }
  };

  const SelectedContent: FC<{ page: number }> = props => {
    switch (props.page) {
      case 0:
        return <ProductService />;
      case 1:
        return <Employee />;
      default:
        return <div />;
    }
  };
  
  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Typography variant='h4' color='primary' display='inline'>
        {renderGreeting()} Admin
      </Typography>
      <Typography variant='body1' color='primary' display='inline' className={classes.spacing}>
        {detailDate}
      </Typography>
      <InformationContent
        addNewTransaction={addNewTransaction}
        totalOmsetThisMonth={totalMonthTransaction}
        transactionToday={countDayTransaction}
        totalOmsetToday={totalDayTransaction}
        newTransaction={'1'}
        employeeJob={jobEmployees}
      />
      <Typography variant='h4' color='primary' gutterBottom>
        Pengaturan
      </Typography>
      <Divider className={classes.divider} />
      <CustomizedTabs
        tabs={[
          { id: 0, name: 'Produk & Servis' },
          { id: 1, name: 'Karyawan' }
        ]}
        selectedTabId={selectedTab}
        onSelect={(tabId: number) => performActionAndRevertPage(setSelectedTab, tabId)}
      />
      <SelectedContent page={selectedTab} />
    </Container>
  );
};

export default SettingsPage;
