import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Theme,
  withStyles,
  Typography,
  Divider,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CreateCustomer from './CreateCustomer';
import axios, { CancelTokenSource } from 'axios';
import HeaderRow from 'components/HeaderRow';
import BodyCell from 'components/BodyCell';
import NumberFormat from 'react-number-format';
import AddTransactionColumn from './AddTransactionColumn';
import { CUSTOMER_BASE_URL, BANK_NAME_BASE_URL, PRODUCT_SERVICE_BASE_URL, EMPLOYEE_BASE_URL } from '../../../../constants/url';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { ucWords } from 'utils';

interface Props {
  okLabel: 'KONFIRMASI';
  customerId: number;
  notes: string;
  tender: number;
  paymentMethod: string;
  customerName: string;
  addTransactionColumn: TransactionModel[];
  isAllowSave: boolean;
  onEditTransaction: boolean;
  nameBank: string;
  numberBank: string;

  handleAllowButtonSave(customerId: number, customerName: string, paymentMethod: string): void;
  setCustomerId: React.Dispatch<React.SetStateAction<number>>;
  setCustomerName: React.Dispatch<React.SetStateAction<string>>;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  setTender: React.Dispatch<React.SetStateAction<number>>;
  setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
  setAddTransactionColumn: React.Dispatch<React.SetStateAction<TransactionModel[]>>;
  setNameBank: React.Dispatch<React.SetStateAction<string>>;
  setNumberBank: React.Dispatch<React.SetStateAction<string>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const AddButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  addButton: {
    right: 140,
    color: '#FFFFFF'
  },
  extendedIconadd: {
    paddingRight: theme.spacing(1),
    textAlign: 'center'
  },
  icon: {
    fontSize: 40
  },
  tableRow: {
    height: 64
  },
  contentGrid: {
    paddingTop: theme.spacing(1)
  },
  headerModalText: {
    textAlign: 'left',
    color: '#53A0BE'
  },
  divider: {
    marginBottom: theme.spacing(4)
  },
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  TextInfo: {
    textAlign: 'right'
  },
  gridOdd: {
    backgroundColor: '#F8F8F8',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  gridEven: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  contentLabel: {
    fontWeight: 'normal'
  },
  contentValue: {
    paddingLeft: theme.spacing(4)
  },
  radioTypo: {
    marginTop: theme.spacing(2),
    textTransform: 'uppercase'
  },
  table: {
    width: 518,
    height: 35,
    backgroundColor: '#F5F5DC',
    fontSize: '13px',
    fontStyle: 'Bold'
  },
  dialog: {
    width: 1000
  }
}));

const CreateTransactionForm: React.FC<Props> = props => {
  const classes = useStyles();

  const { customerId, setCustomerId } = props;
  const { customerName, setCustomerName } = props;
  const { notes, setNotes } = props;
  const { tender, setTender } = props;
  const { nameBank, setNameBank } = props;
  const { numberBank, setNumberBank } = props;
  const { paymentMethod, setPaymentMethod } = props;
  const { isAllowSave, handleAllowButtonSave, addTransactionColumn, setAddTransactionColumn } = props;

  const { okLabel, onSubmit, onEditTransaction, isSubmitting, onCancel } = props;

  const [showTender, setShowTender] = useState<boolean>(false);
  const [isShowBank, setShowBank] = useState<boolean>(false);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [count, setCount] = useState<number>(0);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [customers, setCustomers] = useState<CustomerDetailModel[]>([]);
  const [categories, setCategories] = useState<CategoriesModel[]>([]);
  const [employees, setEmployees] = useState<EmployeeModel[]>([]);
  const [bankNames, setBankNames] = useState<BankNameModel[]>([]);

  const [isSearchingCustomer, setSearchingCustomer] = useState<boolean>(false);
  const [isSearchCustomerError, setSearchCustomerError] = useState<boolean>(false);
  const [isSearchingProduct, setSearchingProduct] = useState<boolean>(false);
  const [isSearchProductError, setSearchProductError] = useState<boolean>(false);
  const [isSearchingEmployee, setSearchingEmployee] = useState<boolean>(false);
  const [isSearchEmployeeError, setSearchEmployeeError] = useState<boolean>(false);
  const [isOpenDiscountSelect, setIsOpenDiscountSelect] = useState<boolean>(false);
  const [serviceBankName, setServiceBankName] = useState<string>('');
  const [bankQueryString, setBankQueryString] = useState<string>();
  const bankNumberRef = useRef<HTMLInputElement | null>(null);
  const [EmployeeJob, setEmployeeJob] = useState<any[]>([
    {
      EmployeeJob: []
    }
  ]);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (bankQueryString) {
        params.append('q', bankQueryString);
      }

      return params.toString();
    };

    const searchCustomer = async () => {
      setSearchingCustomer(true);
      setSearchCustomerError(false);

      try {
        const url = `${CUSTOMER_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setCount(data.count);
        setCustomers(data.customers);
      } catch (err) {
        setSearchCustomerError(true);
      }

      setSearchingCustomer(false);
    };

    const searchCategory = async () => {
      setSearchingProduct(true);
      setSearchProductError(false);

      try {
        const url = `${PRODUCT_SERVICE_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        setCount(data.count);
        setCategories(data.categories);
      } catch (err) {
        setSearchProductError(true);
      }

      setSearchingProduct(false);
    };

    const searchBankName = async () => {
      try {
        const url = `${BANK_NAME_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        setBankNames(data.bankNames);
      } catch (err) {
        setSearchProductError(true);
      }
    };

    const searchEmployee = async () => {
      setSearchingEmployee(true);
      setSearchEmployeeError(false);

      try {
        const url = `${EMPLOYEE_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        setCount(data.count);
        setEmployees(data.Employees);
      } catch (err) {
        setSearchEmployeeError(true);
      }

      setSearchingEmployee(false);
    };

    searchCustomer();
    searchEmployee();
    searchCategory();
    searchBankName();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [setBankQueryString]);

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const addNewCustomer = (customer: CustomerDetailModel) => {
    customer.new = true;
    customers.unshift(customer);
    setCustomerId(customer.id);
    setCustomerName(`${customer.name} - ${customer.contactNumber}`);

    setCustomers([...customers.sort()]);
    setCount(c => c + 1);
  };

  const handleChange = (event: any) => {
    setPaymentMethod(event.target.value);
    setTender(0);

    if (event.target.value === 'TUNAI') {
      setShowTender(true);
      setShowBank(false);
    } else {
      setShowTender(false);
      setShowBank(true);
    }

    handleAllowButtonSave(customerId, customerName, event.target.value);
  };

  const handleCustomer = (customer: CustomerDetailModel) => {
    const { customerId } = customer;

    setCustomerId(customerId);
    let customerNameNew = '';
    customers.map(value => {
      if (customerId == value.customerId) customerNameNew = value.customerName;
    });
    setCustomerName(customerNameNew);
    handleAllowButtonSave(customerId, customerNameNew, paymentMethod);
  };

  const handleBankName = (value: string) => {
    setBankQueryString(value);
    if (typeof value === 'object') {
      const newValue: BankNameModel = value;
      setNameBank(newValue ? newValue.name : value);
    }
  };

  const handleCancelCreateCustomer = () => {
    setOpenCreateModal(false);
  };

  const clearFormErrors = () => {
    const currentAddTransactionColumn = [...addTransactionColumn];

    currentAddTransactionColumn.map((value, index) => {
      value.productNameError = '';
      value.employeeNameTwoError = '';
      value.employeeNameError = '';
    });

    setAddTransactionColumn(currentAddTransactionColumn);
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    const currentAddTransactionColumn = [...addTransactionColumn];

    currentAddTransactionColumn.map((value, index) => {
      if (!value.CategoryId) {
        ret = false;
      } else if (!value.ProductId) {
        ret = false;
      } else if (!value.isProductMerge && value.EmployeeDetails.length === 0) {
        value.employeeNameError = 'Karyawan Harus diisi';
        ret = false;
      } else if (value.isProductMerge && value.EmployeeDetailsOne.length === 0) {
        value.employeeNameError = 'Karyawan Harus diisi';
        ret = false;
      } else if (value.isProductMerge && value.EmployeeDetailsTwo.length === 0) {
        value.employeeNameTwoError = 'Karyawan Harus diisi';
        ret = false;
      }
    });
    
    setAddTransactionColumn(currentAddTransactionColumn);

    return ret;
  };

  const handleAddTempTransactionColumn = () => {
    const currentAddTransactionColumn = [...addTransactionColumn];
    const currentEmployeeJob = [...EmployeeJob];
    let isAllowAdd: Boolean = true;

    if (!validateForm()) {
      return;
    }

    currentAddTransactionColumn.map((value, index) => {
      value.id = index;
      value.new = false;
    });

    setIsOpenDiscountSelect(false);

    if (isAllowAdd) {
      currentAddTransactionColumn.push({
        id: 0,
        CategoryId: 0,
        categoryName: '',
        ProductId: 0,
        productName: '',
        productPrice: 0,
        EmployeeId: [],
        EmployeeNumber: [],
        EmployeeDetails: [],
        EmployeeDetailsOne: [],
        EmployeeDetailsTwo: [],
        EmployeeJob: [],
        isProductMerge: false,
        employeeName: '',
        productsTemp: [],
        qty: 1,
        isService: true,
        discount: 0,
        discountAmount: 0,
        discountType: 0,
        discountTypeName: '',
        categoryNameError: '',
        employeeNameError: '',
        productNameError: '',
        employeeNameTwoError: '',
        new: true
      });

      currentEmployeeJob.push({
        EmployeeJob: []
      });

      setEmployeeJob(currentEmployeeJob);
      setAddTransactionColumn(currentAddTransactionColumn);
      handleCalculate();
    }
  };

  const handleCalculate = (value?: string, transaction?: TransactionModel[]) => {
    let subTotal = 0;
    let totalDiscount = 0;

    if (value === 'delete' && transaction) {
      for (const value of transaction) {
        subTotal += value.productPrice * value.qty;
        totalDiscount += value.discountAmount;
      }
    } else {
      for (const value of addTransactionColumn) {
        if (value.new == false) {
          subTotal += value.productPrice * value.qty;
          totalDiscount += value.discountAmount;
        }
      }
    }

    setTender(subTotal - totalDiscount);
    setSubTotal(subTotal);
    setTotalDiscount(totalDiscount);
  };

  const handleSearchCustomer = async (value: string) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setSearchingCustomer(true);
    setSearchCustomerError(false);

    try {
      const { data } = await axios.get(CUSTOMER_BASE_URL, { cancelToken: cancelTokenSource.token, params: { qc: value } });
      setCount(data.count);
      setCustomers(data.customers);
    } catch (err) {
      setSearchCustomerError(true);
    }
    setSearchingCustomer(false);
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid item sm={12}>
        <Grid container className={classes.contentGrid}>
          <Typography variant='h4' id='modal-title' className={classes.headerModalText}>
            Masukkan Transaksi
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Autocomplete
                id='nameCustomer'
                size='small'
                autoHighlight
                options={customers}
                value={customers.filter(value => value.id === customerId)[0]}
                inputValue={customerName}
                onInputChange={(event, newInputValue) => {
                  setCustomerName(newInputValue);
                }}
                getOptionLabel={(option: CustomerDetailModel) => {
                  return option.customerName && option.contactNumber && ucWords(`${option.customerName} - ${option.contactNumber} `);
                }}
                onChange={(event: any, value: any) => handleCustomer(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Nama Pelanggan'
                    variant='outlined'
                    placeholder='Nama Pelanggan'
                    onChange={(event: any) => handleSearchCustomer(event.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <PersonAddIcon color='primary' className={classes.icon} onClick={handleOpenCreateModal} />
            </Grid>
          </Grid>
          <AddTransactionColumn
            categories={categories}
            employees={employees}
            addTransactionColumn={addTransactionColumn}
            setAddTransactionColumn={setAddTransactionColumn}
            setEmployeeJob={setEmployeeJob}
            EmployeeJob={EmployeeJob}
            onEditTransaction={onEditTransaction}
            handleCalculate={handleCalculate}
            isOpenDiscountSelect={isOpenDiscountSelect}
            setIsOpenDiscountSelect={setIsOpenDiscountSelect}
            handleAddTempTransactionColumn={handleAddTempTransactionColumn}
          />
          <Grid item xs={12}>
            <TextField
              margin='dense'
              fullWidth
              id='notes'
              label='Catatan'
              value={notes}
              onChange={event => setNotes(event.target.value)}
              variant='outlined'
              autoComplete='off'
              multiline
              rowsMax='4'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.radioTypo}>
                Metode Pembayaran
              </Typography>
              <FormControl component='fieldset'>
                <RadioGroup aria-label='paymentMethod' name='paymentMethod' value={paymentMethod} onChange={handleChange} row>
                  <Grid container item sm={12}>
                    <Grid item xs={showTender ? 3 : 5}>
                      <FormControlLabel value='KARTU KREDIT' control={<Radio color='primary' />} label='Kartu Kredit' labelPlacement='end' />
                    </Grid>
                    <Grid item xs={showTender ? 3 : 5}>
                      <FormControlLabel value='KARTU DEBIT' control={<Radio color='primary' />} label='Kartu Debit' labelPlacement='end' />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel value='TUNAI' control={<Radio color='primary' />} label='Tunai' labelPlacement='end' />
                    </Grid>
                    {showTender && (
                      <Grid item lg={4}>
                        <TextField
                          margin='dense'
                          required
                          fullWidth
                          id='notes'
                          label='Jumlah Tender'
                          value={tender === 0 ? setTender(subTotal - totalDiscount) : tender}
                          onChange={event => setTender(+event.target.value)}
                          variant='outlined'
                          autoComplete='off'
                          multiline
                          rowsMax='4'
                          InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            inputProps: {
                              thousandSeparator: true,
                              prefix: 'Rp'
                            }
                          }}
                          InputLabelProps={{
                            className: NumberFormatCustom as any
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </RadioGroup>
              </FormControl>
              {isShowBank && (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Autocomplete
                      id='nameBank'
                      size='small'
                      autoHighlight
                      freeSolo
                      options={bankNames}
                      value={bankNames.filter(value => value.name === nameBank).length ? bankNames.filter(value => value.name === nameBank)[0] : null}
                      inputValue={nameBank}
                      onInputChange={(event, value) => setNameBank(value)}
                      getOptionLabel={(option: BankNameModel) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        
                        return option.name;
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          if (bankNumberRef.current) {
                            e.preventDefault();
                            bankNumberRef.current.focus();
                          }
                        }
                      }}
                      onChange={(event: any, value: any) => handleBankName(value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Nama Bank'
                          margin='dense'
                          variant='outlined'
                          value={nameBank}
                          InputLabelProps={{
                            className: classes.textFieldFont
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin='dense'
                      fullWidth
                      id='numberBank'
                      label='Nomor Kartu'
                      inputRef={bankNumberRef}
                      value={numberBank}
                      onChange={event => setNumberBank(event.target.value)}
                      variant='outlined'
                      autoComplete='off'
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        inputProps: {
                          thousandSeparator: false
                        }
                      }}
                      InputLabelProps={{
                        className: NumberFormatCustom as any
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h4' className={classes.headerModalText}>
                Order Item
              </Typography>
              <Table>
                <TableHead>
                  <HeaderRow
                    headers={[
                      { label: 'Kategori', pL: '10px', pR: '10px', verticalAlign: 'top' },
                      { label: 'Deskripsi', pL: '10px', pR: '10px', verticalAlign: 'top' },
                      { label: 'Qty', pL: '10px', pR: '10px', verticalAlign: 'top' },
                      { label: 'Harga', pL: '10px', pR: '10px', verticalAlign: 'top' },
                      { label: 'Diskon', pL: '10px', pR: '10px', verticalAlign: 'top' },
                      { label: 'Total', pL: '10px', pR: '10px', verticalAlign: 'top' }
                    ]}
                  />
                </TableHead>
                <TableBody>
                  {addTransactionColumn &&
                    addTransactionColumn.map((tempTransaction, index) =>
                      tempTransaction && !tempTransaction.new ? (
                        <TableRow key={index} className={classes.tableRow}>
                          <BodyCell cellWidth='10%'>{tempTransaction.categoryName}</BodyCell>

                          <BodyCell cellWidth='10%'>{tempTransaction.productName}</BodyCell>

                          <BodyCell cellWidth={'6%'}>{tempTransaction.qty}</BodyCell>
                          <BodyCell cellWidth={'9%'} pL='10px' pR='10px'>
                            <NumberFormat value={tempTransaction.productPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                          </BodyCell>
                          <BodyCell cellWidth='15%' pR='5px'>
                            <NumberFormat value={tempTransaction.discountAmount} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                          </BodyCell>
                          <BodyCell cellWidth='15%' pR='5px'>
                            <NumberFormat
                              value={tempTransaction.productPrice * tempTransaction.qty - tempTransaction.discountAmount}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Rp'}
                            />
                          </BodyCell>
                        </TableRow>
                      ) : null
                    )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.TextInfo}>
                SubTotal: <NumberFormat value={subTotal} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
              <Typography variant='h6' className={classes.TextInfo}>
                Diskon: <NumberFormat value={totalDiscount} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
              {showTender ? (
                <Typography variant='h6' className={classes.TextInfo}>
                  {paymentMethod}: <NumberFormat value={tender} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                </Typography>
              ) : (
                <Typography variant='h6' className={classes.TextInfo}>
                  {paymentMethod}
                </Typography>
              )}
              <Typography variant='h6' className={classes.TextInfo}>
                Kembalian:{' '}
                <NumberFormat value={tender ? tender - (subTotal - totalDiscount) : 0} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant='h6' className={classes.TextInfo}>
                Grand Total <NumberFormat value={subTotal - totalDiscount} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel}>
            Batal
          </Button>
          <AddButton type='submit' variant='contained' color='primary' disabled={isSubmitting || isAllowSave}>
            {okLabel}
          </AddButton>
        </Grid>
      </Grid>
      <CreateCustomer
        open={openCreateModal}
        addNewCustomer={addNewCustomer}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleCancel={handleCancelCreateCustomer}
      />
    </form>
  );
};

export default CreateTransactionForm;
