import React, { FC, Fragment, useState } from 'react';
import clsx from 'clsx';
import { Grid, IconButton, makeStyles, Theme, Tooltip, Typography, Button, TextField } from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

interface Props {
  isProcessing: boolean;
  checked: number[];
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
  primaryButtonLabel: string;
  customBackground?: string;
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerFlex: {
    display: 'flex'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1.5)
  },
  toolbarTable: {
    backgroundColor: '#E7F4F8',
    paddingBottom: theme.spacing(0.9)
  },

  addButton: {
    color: '#FFFFFF'
  },
  saveButton: {
    marginLeft: theme.spacing(2)
  },
  headerColor: {
    color: '#53A0BE'
  },
  allStandart: {
    paddingRight: theme.spacing(6)
  },
  toolButton: {
    marginLeft: theme.spacing(4)
  },
  icon: {
    fontSize: 20
  },
  leftHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const StandartToolBar: FC<Props> = props => {
  const classes = useStyles();
  const { isSubmitting, primaryButtonLabel, checked, onSubmit, onCancel } = props;
  const countChecked = checked.length;

  const renderLeftHeader = () => {
    return (
      <div className={classes.headerFlex}>
        <div className={classes.otherTextCell}>
          {countChecked !== 0 ? (
            <Typography variant='h5' color='primary' className={classes.headerColor}>
              {`${countChecked} selected`}
            </Typography>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  };

  const renderRightHeader = () => {
    if (countChecked !== 0) {
      return (
        <Fragment>
          <Grid container className={classes.allStandart} spacing={1} alignItems='center'>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <Typography>
                Merge ?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Button variant='contained' onClick={onCancel} className={classes.toolButton} disabled={isSubmitting}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Button onClick={onSubmit} variant='contained' className={classes.toolButton} color='secondary' disabled={isSubmitting}>
                {primaryButtonLabel}
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else {
    }
  };

  let isChecked;
  if (countChecked === 0) {
    isChecked = false;
  } else {
    isChecked = true;
  }

  return (
    <Grid container spacing={2} className={clsx({ [classes.toolbarTable]: isChecked })}>
      <Grid item xs={8}>
        <Grid container direction='row' justify='flex-start' alignItems='center' className={classes.leftHeader}>
          {renderLeftHeader()}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container direction='row' justify='center' alignItems='center'>
          {renderRightHeader()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StandartToolBar;
