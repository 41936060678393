import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Theme, withStyles, Table, TableBody, TableHead, TableRow, TableFooter, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import HeaderRow from 'components/HeaderRow';
import BodyCell from 'components/BodyCell';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import logo from '../../../../..//src/images/darwin-dashboard-logo02.png';
import Locale from 'date-fns/esm/locale/id';
import { useReactToPrint } from 'react-to-print';
import Print from './Print';

interface Props {
  okLabel: 'PRINT INVOICE';
  paymentMethod: string;
  tender: number;
  notes: string;
  addTransactionColumn: TransactionModel[];
  transaction: TransactionDetailModel;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const AddButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  addButton: {
    right: 140,
    color: '#FFFFFF'
  },
  extendedIconadd: {
    paddingRight: theme.spacing(1),
    textAlign: 'center'
  },
  rincian: {
    backgroundColor: '#e9e9e9',
    fontSize: 16
  },
  icon: {
    fontSize: 40
  },
  typoText: {
    color: theme.palette.common.black
  },
  tableRow: {
    height: 64
  },
  textInfoRight: {
    textAlign: 'right',
    fontSize: 12
  },
  contentGrid: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1)
  },
  headerModalText: {
    textAlign: 'left',
    color: '#53A0BE'
  },
  divider: {
    marginBottom: theme.spacing(4)
  },
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  GridLogo: {
    width: 217,
    position: 'relative',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  TextInfo: {
    textAlign: 'right'
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  gridOdd: {
    backgroundColor: '#F8F8F8',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  gridEven: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  contentLabel: {
    fontWeight: 'normal'
  },
  contentValue: {
    paddingLeft: theme.spacing(4)
  },
  radioTypo: {
    marginTop: theme.spacing(2),
    textTransform: 'uppercase'
  },
  table: {
    width: 518,
    height: 35,
    backgroundColor: '#F5F5DC',
    fontSize: '13px',
    fontStyle: 'Bold'
  },
  dialog: {
    width: 1000
  }
}));

const ReportInvoice: React.FC<Props> = props => {
  const classes = useStyles();
  const { paymentMethod, tender, notes, addTransactionColumn, transaction } = props;

  const dateNow = format(new Date(transaction.createdAt), 'cccc dd/MM/yyyy HH:mm:ss', { locale: Locale }).toString();

  const { onSubmit, isSubmitting, onCancel } = props;

  const [subTotal, setSubtotal] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [uniqueEmployeeNumbers, setEmployeeNumbers] = useState<string[]>([]);
  const [transactions, setTransactions] = useState<TransactionModel[]>([]);

  useEffect(() => {
    const handleCalculate = () => {
      let subTotalCalc = 0;
      let totalDiscountCalc = 0;
      var uniqueEmployeeNumber: any[] = [];
      var uniqueProductServices: TransactionModel[] = [];

      addTransactionColumn.map(itemTransaction => {
        if (itemTransaction.isProductMerge) {
          itemTransaction.EmployeeDetailsOne &&
            itemTransaction.EmployeeDetailsOne.map(employee => {
              if (!uniqueEmployeeNumber.includes(employee.employeeNumber ? employee.employeeNumber : employee.employeeNumb))
                uniqueEmployeeNumber.push(employee.employeeNumber ? employee.employeeNumber : employee.employeeNumb);
            });

          itemTransaction.EmployeeDetailsTwo &&
            itemTransaction.EmployeeDetailsTwo.map(employee => {
              if (!uniqueEmployeeNumber.includes(employee.employeeNumber ? employee.employeeNumber : employee.employeeNumb))
                uniqueEmployeeNumber.push(employee.employeeNumber ? employee.employeeNumber : employee.employeeNumb);
            });

          itemTransaction.EmployeeDetails &&
            itemTransaction.EmployeeDetails.map(employee => {
              if (!uniqueEmployeeNumber.includes(employee.employeeNumber ? employee.employeeNumber : employee.employeeNumb))
                uniqueEmployeeNumber.push(employee.employeeNumber ? employee.employeeNumber : employee.employeeNumb);
            });
        } else {
          itemTransaction.EmployeeDetails &&
            itemTransaction.EmployeeDetails.map(employee => {
              if (!uniqueEmployeeNumber.includes(employee.employeeNumber ? employee.employeeNumber : employee.employeeNumb))
                uniqueEmployeeNumber.push(employee.employeeNumber ? employee.employeeNumber : employee.employeeNumb);
            });
        }
      });

      for (const value of addTransactionColumn) {
        if (uniqueProductServices.filter(transaction => transaction.productName === value.productName).length == 0) uniqueProductServices.push(value);
      }

      for (const value of uniqueProductServices) {
        subTotalCalc += value.productPrice * value.qty;
        totalDiscountCalc += value.discountAmount;
      }

      setTransactions(uniqueProductServices);
      setSubtotal(subTotalCalc);
      setTotalDiscount(totalDiscountCalc);
      setEmployeeNumbers(uniqueEmployeeNumber);
    };

    handleCalculate();
  }, []);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page {
      size: auto;   /* auto is the initial value */ 

      /* this affects the margin in the printer settings */ 
      margin: 25mm 25mm 25mm 25mm;  
      }`
  });

  return (
    <form noValidate onSubmit={onSubmit}>
      <div style={{ display: 'none' }}>
        <Print
          paymentMethod={paymentMethod}
          tender={tender}
          notes={notes}
          addTransactionColumn={addTransactionColumn}
          transaction={transaction}
          transactions={transactions}
          dateNow={dateNow}
          subTotal={subTotal}
          totalDiscount={totalDiscount}
          uniqueEmployeeNumbers={uniqueEmployeeNumbers}
          componentRef={componentRef}
        />
      </div>

      <Grid item sm={12}>
        <Grid className={classes.contentGrid}>
          <div className={classes.paper}>
            <Grid item xs={12} className={classes.GridLogo}>
              <img src={logo} alt='logo' width='200' />
            </Grid>
          </div>
          <Grid container spacing={1}>
            <Grid container item justify='center'>
              <Typography variant='h6' className={classes.TextInfo}>
                {dateNow}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.TextInfo}>
                Transaksi ID :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>{transaction.refId}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant='h6' className={classes.TextInfo}>
                Nama Pelanggan :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>{transaction.Customer && (`${transaction.Customer.name} - ${transaction.Customer.contactNumber}`)}</Typography>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <HeaderRow
                headers={[
                  { label: 'Kategori', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'Deskripsi', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'Qty', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'Harga', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'Diskon', pL: '10px', pR: '10px', verticalAlign: 'top' },
                  { label: 'Total', pL: '10px', pR: '10px', verticalAlign: 'top' }
                ]}
              />
            </TableHead>
            <TableBody>
              {transactions &&
                transactions.map((tempTransaction, index) =>
                  !tempTransaction && tempTransaction!.productPrice ? null : (
                    <TableRow key={index} className={classes.tableRow}>
                      <BodyCell cellWidth='10%'>{tempTransaction.categoryName}</BodyCell>

                      <BodyCell cellWidth='10%'>{tempTransaction.productName}</BodyCell>

                      <BodyCell cellWidth={'6%'}>{tempTransaction.qty}</BodyCell>
                      <BodyCell cellWidth={'9%'} pL='10px' pR='10px'>
                        <NumberFormat value={tempTransaction.productPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                      </BodyCell>
                      <BodyCell cellWidth='15%' pR='5px'>
                        <NumberFormat value={tempTransaction.discountAmount} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                      </BodyCell>
                      <BodyCell cellWidth='15%' pR='5px'>
                        <NumberFormat
                          value={tempTransaction.qty * tempTransaction.productPrice - tempTransaction.discountAmount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Rp'}
                        />
                      </BodyCell>
                    </TableRow>
                  )
                )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <BodyCell cellWidth='10%'>SUBTOTAL: DISKON:</BodyCell>
                <BodyCell cellWidth='10%'></BodyCell>
                <BodyCell cellWidth='10%'></BodyCell>
                <BodyCell cellWidth='10%'></BodyCell>
                <BodyCell cellWidth='10%'></BodyCell>
                <BodyCell cellWidth='10%'>
                  <NumberFormat value={subTotal} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                  <br />
                  <NumberFormat value={totalDiscount} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                </BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell cellWidth='10%' colSpan={5}>
                  GRAND TOTAL {<br />} METODE PEMBAYARAN {<br />} {paymentMethod == 'TUNAI' && `${paymentMethod}`} {paymentMethod == 'TUNAI' && <br />} {tender > 0 && `KEMBALIAN`} {tender > 0 && <br />} {paymentMethod !== 'TUNAI' && transaction.nameBank}
                </BodyCell>
                <BodyCell cellWidth='20%'>
                  <NumberFormat value={subTotal - totalDiscount} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                  <br />
                  {paymentMethod}
                  <br />
                  {paymentMethod == 'TUNAI' && <NumberFormat value={tender} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />}
                  {paymentMethod == 'TUNAI' && <br />}
                  {tender > 0 && (
                    <NumberFormat value={tender - (subTotal - totalDiscount)} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                  )}
                  {tender > 0 && <br />}
                  {paymentMethod !== 'TUNAI' && transaction.numberBank}
                </BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell cellWidth='10%' colSpan={6}>
                  Catatan : {notes}
                </BodyCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>
      <br />
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} onClick={onCancel}>
          SELESAI
        </Button>
        <AddButton variant='contained' color='primary' disabled={isSubmitting} onClick={handlePrint}>
          PRINT INVOICE
        </AddButton>
      </Grid>
    </form>
  );
};

export default ReportInvoice;
