import React from 'react';
import { Grid, TextField, MenuItem, Button, Theme, withStyles } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

interface Props {
  okLabel: 'Add' | 'Edit';

  name: string;
  contactNumber: string;
 
  setName: React.Dispatch<React.SetStateAction<string>>;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;
 
  nameError: string;
  contactNumberError: string;
 
  isSubmitting: boolean;
  onSubmitCustomer: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const AddButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    color: '#FFFFFF'
  }
}));

const CreateCustomerForm: React.FC<Props> = props => {
  const classes = useStyles();

  const { name, setName, nameError } = props;
  const { contactNumber, setContactNumber, contactNumberError } = props;
  const { isSubmitting, onSubmitCustomer, onCancel, okLabel } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='name'
          label='Nama Pelanggan'
          error={nameError !== ''}
          helperText={nameError}
          value={name}
          onChange={event => setName(event.target.value)}
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='contactNumber'
          label='Nomor Kontak'
          error={contactNumberError !== ''}
          helperText={contactNumberError}
          value={contactNumber}
          onChange={event => setContactNumber(event.target.value)}
          autoComplete='off'
        />
      </Grid>
      <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
          Cancel
        </Button>
        <AddButton variant='contained' color='primary' className={classes.addButton} onClick={onSubmitCustomer} disabled={isSubmitting}>
          {okLabel}
        </AddButton>
      </Grid>
    </Grid>
  );
};

export default CreateCustomerForm;
