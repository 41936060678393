import React, { FC, Fragment, useState, useEffect, useContext, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import { Container, Typography, Theme, makeStyles, Table, TableBody, TableHead } from '@material-ui/core';
import TablePagination from 'components/TablePagination';
import EditCommissionForm from './components/EditCommissionForm';
import { CREATE_COMMISSION_BASE_URL, CREATE_COMMISSION_MULTIPLE_SELECT_BASE_URL  } from 'constants/url';
import { GET_DELETE_COMMISSION_URL} from 'constants/url';
import StandartToolBar from './components/StandartToolBar';
import { StandardConfirmationDialog } from 'components/AppDialog';

interface Props {
  isLoadingData: boolean;
  employee: EmployeeModel;
  commissions: CommissionModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterBy: string;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  columnFilter: ColumnFilter[];
  handleCancelCreateCommission: () => void;
  openEditCommission: boolean;
  commission: CommissionModel;
  currentEditingCommissionIndex: number;
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  handleOpenEditCommission: (commissionIndex: number) => React.MouseEventHandler;
  setCurrentEditingMultipleCommissionIndex: React.Dispatch<React.SetStateAction<number[]>>;
  handleCancelEditCommission: () => void;
  addNewCommission(commission: CommissionModel): void;
  updateIndividualCommission: (updatedCommissionProperties: Partial<CommissionModel>, commissionIndex?: number) => void;
  updateMultipleCommission: (updatedCommissionProperties: Partial<CommissionModel[]>, commissionIndex?: number[]) => void;
  deleteIndividualCommission: (commissionIndex: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleOpenCreateCommission: () => void;
  checked: number[];
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    overflowX: 'auto'
  },
  headerPageTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(0)
  }
}));

const CommissionTable: FC<Props> = props => {
  const classes = useStyles();
  const { currentPageTitle } = useContext(CurrentPageContext);
  let cancelTokenSource: CancelTokenSource;

  const {
    isLoadingData,
    employee,
    commissions,
    count,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleCancelCreateCommission,
    openEditCommission,
    commission,
    currentEditingCommissionIndex,
    handleOpenEditCommission,
    setCurrentEditingMultipleCommissionIndex,
    handleCancelEditCommission,
    updateIndividualCommission,
    updateMultipleCommission,
    deleteIndividualCommission,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    setDelete
  } = props;

  const dummyCommission: CommissionModel = {
    id: 1,
    ProductId: 0,
    commissionPrice: 0,
    categoryName: '',
    productName: '',
    qty: 0,
    barcode: '',
    sellingPrice: 0,
    createdAt: new Date(),
    updatedAt: new Date()
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [commissionPrice, setCommissionPrice] = useState<number>(0);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [id, setId] = useState<number []>();
  const { checked, setChecked } = props;
  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);

  const unCheckAll = () => {
    setCurrentEditingMultipleCommissionIndex([]);
    setChecked([]);
  };
  const checkAll = () => {
    const newBulkChecked = [...checked];
    const countChecked = newBulkChecked.length;
    if (count > rowsPerPage) {
      if (countChecked !== rowsPerPage) {
        newBulkChecked.splice(0, countChecked);
        commissions.map(commission => newBulkChecked.push(commission.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    } else {
      if (countChecked !== count) {
        newBulkChecked.splice(0, countChecked);
        commissions.map(commission => newBulkChecked.push(commission.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    }
    setCurrentEditingMultipleCommissionIndex(newBulkChecked);
    setChecked(newBulkChecked);
  };

  const individualCheck = (id: number) => {
    const newChecked = [...checked];
    // count element in object selected filter for check already exist or not
    const countElement = newChecked.filter(newCheckedValue => newCheckedValue === id).length;
    if (countElement === 0) {
      newChecked.push(id);
    } else {
      // check index of element and remove object by index
      const checkedFilterIndex = newChecked.map(newCheckedValue => newCheckedValue).indexOf(id);
      newChecked.splice(checkedFilterIndex, 1);
    }
    setCurrentEditingMultipleCommissionIndex(newChecked);
    setChecked(newChecked);
  };

  const resetEditFormValues = useCallback(() => {
    if (!commission) {
      return;
    }
    const { barcode, sellingPrice } = commission;

    setCommissionPrice(0);
  }, [commission]);

  useEffect(() => {
    if (!openEditCommission) {
      let timeout: NodeJS.Timeout;

      if (isLoadingData) {
        timeout = setTimeout(() => {
          setShowSkeleton(true);
        }, 500);
      }

      setShowSkeleton(false);
      resetInputFormValues();
      clearFormErrors();

      return () => {
        clearTimeout(timeout);
      };
    } else {
      resetEditFormValues();
      clearFormErrors();
    }
  }, [openEditCommission, isLoadingData, resetEditFormValues]);

  const resetInputFormValues = () => {
    setCommissionPrice(0);
  };

  const validateForm = () => {
    let ret = true;

    return ret;
  };

  const handleCloseCreateCommission = () => {
    handleCancelCreateCommission();
    resetInputFormValues();
    clearFormErrors();
  };

  const handleCloseEditCommission = () => {
    handleCancelEditCommission();
    resetInputFormValues();
    clearFormErrors();
  };

  const clearFormErrors = () => {};

  
  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();

      if (openEditCommission) {
        const response = await axios.post(
          `${CREATE_COMMISSION_BASE_URL}`,
          {
            EmployeeId: employee.id,
            ProductId: commission.ProductId,
            commissionPrice: commissionPrice
          },
          { cancelToken: cancelTokenSource.token }
        );

        updateIndividualCommission(response.data);
        handleSetMessageSuccess('Berhasil menambah komisi');
      }
      else{
        const select = await axios.post(
          `${CREATE_COMMISSION_MULTIPLE_SELECT_BASE_URL(checked)}`,
          {  
            EmployeeId: employee.id,
            ProductId: commission.ProductId,
            commissionPrice: commissionPrice
          },
          { cancelToken: cancelTokenSource.token }
        );
        updateMultipleCommission(select.data);
        setOpenSnackbar(true);
        handleSetMessageSuccess(`Berhasil Tambah Komisi`);
        setSnackbarVarient('success');
        unCheckAll();
  
      }
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      !openEditCommission ? handleCloseCreateCommission() : handleCloseEditCommission();
    } catch (err) {
      const { errorCode } = err.data;

      handleSetMessageError('Gagal mengubah Produk');

      if (errorCode !== 99) {
        setSnackbarVarient('error');
        setOpenSnackbar(true);
        console.log(`err:${err}`);
        console.log(`errorCode:${errorCode}`);
      }
    }

    setLoading(false);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>, actionMessage: string) => {
    setProcessing(true);

    try {
      await action();
      handleCloseDialog();
      setDelete(true);
      handleSetMessageSuccess(`Successfully ${actionMessage}`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleCloseDialog();
      handleSetMessageError(`Failed to ${actionMessage}`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setProcessing(false);
  };

  const deleteContract = async (selectedId: number) => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_COMMISSION_URL([selectedId]));
    }, 'delete commission');
  };

  const bulkDeleteContract: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_COMMISSION_URL(checked));
    }, 'bulk delete commission');

    setChecked([]);
  };

  return (
    <Fragment>
      <StandartToolBar
        isProcessing={isProcessing}
        checked={checked}
        setOpenDialog={setOpenDialog}
        setMessage={setMessage}
        commissionPrice={commissionPrice}
        setCommissionPrice={setCommissionPrice}
        commissionData={commission}
        onSubmit={handleOnSubmit}
        onCancel={unCheckAll}
        primaryButtonLabel={'Save'}
        customBackground={'#F4F9FC'}
        isSubmitting={isLoading}
      />

      <div className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <HeaderRow
              headers={[
                { label: 'CheckBox', pR: '10px', pT: '7px', verticalAlign: 'top', isCheckBox: true, checked, rowsPerPage, handleCheckAll: checkAll },
                { label: 'Kategori', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Nama Produk / Service', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'ID / Barcode', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Harga', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Komisi', pL: '10px', pR: '10px', verticalAlign: 'top' },
                { label: 'Action', pL: '10px', pR: '10px', verticalAlign: 'top' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton
              ? [1, 2, 3, 4, 5, 6].map(index => (
                  <BodyRow
                    index={index}
                    key={index}
                    commission={dummyCommission}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    deleteIndividualCommission={deleteIndividualCommission}
                    onEditCommission={handleOpenEditCommission(index)}
                    openEditCommission={openEditCommission}
                    updateCommission={updateIndividualCommission}
                    isLoadingData={isLoadingData}
                    setSelectedId={setSelectedId}
                    checked={checked}
                    handleIndividualCheck={individualCheck}
                  />
                ))
              : commissions.map((commission, index) =>
                  openEditCommission && currentEditingCommissionIndex === index ? (
                    <EditCommissionForm
                      key={commission.id}
                      commissionData={commission}
                      commissionPrice={commissionPrice}
                      setCommissionPrice={setCommissionPrice}
                      isSubmitting={isLoading}
                      onSubmit={handleOnSubmit}
                      onCancel={handleCloseEditCommission}
                      primaryButtonLabel={'Save'}
                      customBackground={'#F4F9FC'}
                      isLoadingData={isLoadingData}
                    />
                  ) : (
                    <BodyRow
                      index={index}
                      key={commission.ProductId}
                      commission={commission}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarVarient={setSnackbarVarient}
                      handleSetMessageSuccess={handleSetMessageSuccess}
                      handleSetMessageError={handleSetMessageError}
                      deleteIndividualCommission={deleteIndividualCommission}
                      onEditCommission={handleOpenEditCommission(index)}
                      openEditCommission={openEditCommission}
                      updateCommission={updateIndividualCommission}
                      isLoadingData={isLoadingData}
                      setSelectedId={setSelectedId}
                      checked={checked}
                      handleIndividualCheck={individualCheck}
                    />
                  )
                )}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </div>
      <StandardConfirmationDialog
        variant={'warning'}
        message={message}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={checked.length === 0 ? event => selectedId && deleteContract(selectedId) : bulkDeleteContract}
      />
    </Fragment>
  );
};

export default CommissionTable;
