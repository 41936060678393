import React from 'react';
import { Grid, TextField, DialogContent, Button, Theme, withStyles, Typography, Divider } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/styles';
import Locale from 'date-fns/esm/locale/id';
import { format, startOfMonth, lastDayOfMonth } from 'date-fns';
import { yellow } from '@material-ui/core/colors';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  okLabel: string;
  reCalculation: boolean;
  payroll: PayrollModel;
  employee: EmployeeModel;
  cutting: number;
  notes: string;
  noteReCalculation: string;
  allowancePosition: number;
  allowanceTransport: number;
  allowanceCraft: number;
  allowanceMealt: number;
  allowanceHealth: number;
  allowanceHoliday: number;
  generalNote: string;

  setCutting: React.Dispatch<React.SetStateAction<number>>;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  setNoteReCalculation: React.Dispatch<React.SetStateAction<string>>;
  setAllowancePosition: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceTransport: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceCraft: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceMealt: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceHealth: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceHoliday: React.Dispatch<React.SetStateAction<number>>;
  setGeneralNote: React.Dispatch<React.SetStateAction<string>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3),
    right:115
  },
  addButton: {
    color: '#FFFFFF',
    right:115
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  headerText: {
    textAlign: 'center',
    width: '60%'
  },
  warningSvg: {
    fontSize: '120px',
    color: yellow[500],
    paddingBottom: theme.spacing(2)
  }
}));

const CalculateForm: React.FC<Props> = props => {
  const classes = useStyles();
  const { payroll, reCalculation } = props;
  const { cutting, setCutting } = props;
  const { notes, setNotes } = props;
  const { noteReCalculation, setNoteReCalculation } = props;
  const { allowancePosition, setAllowancePosition } = props;
  const { allowanceTransport, setAllowanceTransport } = props;
  const { allowanceCraft, setAllowanceCraft } = props;
  const { allowanceMealt, setAllowanceMealt } = props;
  const { allowanceHealth, setAllowanceHealth } = props;
  const { allowanceHoliday, setAllowanceHoliday } = props;
  const { generalNote, setGeneralNote } = props;
  const { isSubmitting, onSubmit, onCancel, okLabel } = props;

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid item sm={12}>
        <Grid className={classes.contentGrid}>
          <div className={classes.paper}>
            <Grid item xs={12} className={classes.headerText}>
              <WarningIcon fontSize="large" className={classes.warningSvg} />
              <Typography variant='h4' id='modal-title' color='primary'>
                Anda akan melakukan perhitungan {reCalculation && 'kembali'} gaji bulan {format(
                new Date(!payroll.monthYear ? new Date() : payroll.monthYear),
                'MMMM yyyy',
                { locale: Locale }
              ).toString()}
              </Typography>
            </Grid>
          </div>
          <Typography variant='h5' id='modal-title' color='primary'>
            Potongan
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='cuts'
                label='Potongan'
                value={cutting === 0 ? null : cutting}
                onChange={event => setCutting(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                margin='dense'
                fullWidth
                id='notes'
                label='Catatan'
                value={notes}
                onChange={event => setNotes(event.target.value)}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='h6' id='modal-title' color='primary'>
                Gaji/Tunjangan
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowancePosition'
                label='Jabatan'
                value={allowancePosition === 0 ? null : allowancePosition}
                onChange={event => setAllowancePosition(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceTransport'
                label='Transport'
                value={allowanceTransport === 0 ? null : allowanceTransport}
                onChange={event => setAllowanceTransport(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceCraft'
                label='Kerajinan'
                value={allowanceCraft === 0 ? null : allowanceCraft}
                onChange={event => setAllowanceCraft(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceMeal'
                label='Makan'
                value={allowanceMealt === 0 ? null : allowanceMealt}
                onChange={event => setAllowanceMealt(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceHealth'
                label='Kesehatan'
                value={allowanceHealth === 0 ? null : allowanceHealth}
                onChange={event => setAllowanceHealth(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceHoliday'
                label='THR'
                value={allowanceHoliday === 0 ? null : allowanceHoliday}
                onChange={event => setAllowanceHoliday(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='h6' id='modal-title' color='primary'>
                Catatan Umum
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                fullWidth
                id='generalNote'
                label='Catatan umum'
                value={generalNote}
                onChange={event => setGeneralNote(event.target.value)}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
              />
            </Grid>
          </Grid>
          <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
            <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
              Batal
            </Button>
            <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
              {okLabel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CalculateForm;
