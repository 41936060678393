import React, { FC, useState, useEffect, useCallback } from 'react';
import { Avatar, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import axios, { CancelTokenSource } from 'axios';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';

import EditIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackBar';
import { format } from 'date-fns';
import CreateEmployeeModal from '../../../../../../../../src/pages/SettingsPage/components/Employee/components/CreateEmployeeModal';

interface Props {
  employee: EmployeeModel;
  allowances: AllowanceDeductionModel[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  containerContactEmergency: {
    paddingTop: theme.spacing(3)
  },
  gridName: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  gridEmployeeNumber: {
    marginTop: theme.spacing(1.5)
  },
  contentTypography: {
    fontWeight: 500
  },
  skills: {
    border: '1px solid #0B3469',
    borderRadius: 3,
    color: '#0B3469',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  lineHeight: {
    lineHeight: 2
  },
  avatar: {
    backgroundColor: '#0B3469'
  },
  gridHeader: {
    display: 'flex'
  },
  employeeNumber: {
    border: '1px solid #FF0000',
    borderRadius: 3,
    color: '#FF0000',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  }
}));

const HeaderContent: FC<Props> = props => {
  const classes = useStyles();
  const { employee, allowances } = props;
 
  const {
    name,
    dateOfBirth,
    address,
    employeeNumber,
    joinDate,
    identityNumber,
    contactNumber,
    contactNameEmergency,
    contactNumberEmergency,
    contactRelationEmergency,
    basicSalary
  } = employee!;

  const [img, setImg] = useState<string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [employeeName, setEmployeeName] = useState<string>('');
  const [employeeDateOfBirth, setEmployeeDateOfBirth] = useState<Date>(new Date());
  const [employeeAddress, setEmployeeAddress] = useState<string>('');
  const [employeeNo, setEmployeeNumber] = useState<string>('');
  const [employeeJoinDate, setEmployeeJoinDate] = useState<Date>(new Date());
  const [employeeIdentityNumber, setEmployeeIdentityNumber] = useState<string>('');
  const [employeeContactNumber, setEmployeeContactNumber] = useState<string>('');
  const [employeeContactNameEmergency, setEmployeeContactNameEmergency] = useState<string>('');
  const [employeeContactNumberEmergency, setEmployeeContactNumberEmergency] = useState<string>('');
  const [employeeContactRelationEmergency, setEmployeeContactRelationEmergency] = useState<string>('');
  const [employeeBasicSalary, setEmployeBasicSalary] = useState<number>(0);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [openCreateEmployeeModal, setOpenCreateEmployeeModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [employees, setEmployees] = useState<EmployeeModel[]>([]);
  const [openCreateEmployee, setOpenCreateEmployee] = useState<boolean>(false);

  const fectData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadData = async () => {
      setIsLoadingData(true);
      try {
        setEmployeeName(name);
        setEmployeeDateOfBirth(dateOfBirth);
        setEmployeeAddress(address);
        setEmployeeNumber(employeeNumber);
        setEmployeeJoinDate(joinDate);
        setEmployeeIdentityNumber(identityNumber);
        setEmployeeContactNumber(contactNumber);
        setEmployeeContactNameEmergency(contactNameEmergency);
        setEmployeeContactNumberEmergency(contactNumberEmergency);
        setEmployeeContactRelationEmergency(contactRelationEmergency);
        setEmployeBasicSalary(basicSalary);
      } catch (err) {
        console.log(err);
      }
      setIsLoadingData(false);
    };
    loadData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [name, allowances, dateOfBirth, address, employeeNumber, joinDate, identityNumber, contactNumber, contactNameEmergency, contactNumberEmergency, contactRelationEmergency, basicSalary]);
  
  useEffect(() => {
    fectData()
  }, [fectData]);

  let initialName: any = [];
  if (employeeName) {
    const splitedNames = employeeName.split(' ');
    splitedNames.map((splitedName, index) => {
      if (index === 0 || index === splitedNames.length - 1) {
        if (splitedName[0]) {
          initialName.push(splitedName[0].toUpperCase());
        } else {
          initialName.push(splitedNames[index - 1][0] ? splitedNames[index - 1][0].toUpperCase() : '');
        }
      }

      return initialName;
    });
  }

  const renderAvatar = () => {
    if (isLoadingData) {
      return <Avatar className={classes.avatar} alt={name} src={img} />;
    } else {
      return (
        <Avatar className={classes.avatar} aria-label='employeeInitial'>
          {initialName.join('')}
        </Avatar>
      );
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenCreateEmployee = () => {
    setOpenCreateEmployeeModal(true);
    setOpenCreateEmployee(false);
  };
  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const addNewEmployee = (employee: EmployeeModel) => {
    employee.new = true;
    employees.unshift(employee);
    setEmployees([...employees]);
    setCount(c => c + 1);
  };

  const handleCancelCreateEmployee = () => {
    setOpenCreateEmployeeModal(false);
  };

  return (
    <Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='h6' color='primary' display='inline'>
            Karyawan
          </Typography>
        </Grid>
        <Grid container item xs={6} justify='flex-end' className={classes.gridHeader}>
          <Button variant='outlined' color='primary' onClick={handleOpenCreateEmployee}>
            <EditIcon /> EDIT
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>{renderAvatar()}</Grid>
        <Grid item className={classes.gridName}>
          <Typography color='primary' variant='h3'>
            {isLoadingData ? <Skeleton width={90} /> : `${employeeName}`}
          </Typography>
        </Grid>
        <Grid item className={classes.gridEmployeeNumber}>
          <Typography variant='body1' align='right' display='inline' className={classes.lineHeight}>
            <span className={classes.employeeNumber}>{employeeNo}</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item sm={3}>
          <Typography variant='h6' color='primary' display='inline'>
            Gaji/Tunjangan:
          </Typography>
          <Typography variant='h6' display='inline'>
            {isLoadingData ? (
              <Skeleton width={60} />
            ) : (
              <NumberFormat value={employeeBasicSalary} displayType={'text'} thousandSeparator={true} prefix={' Rp'} className={classes.contentTypography} />
            )}
          </Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography variant='h6' color='primary' display='inline'>
            Nomor Kontak:
          </Typography>
          <Typography variant='h6' display='inline'>
            {isLoadingData ? <Skeleton width={50} /> : <span className={classes.contentTypography}> {employeeContactNumber}</span>}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' color='primary' display='inline'>
            Tanggal Masuk:
          </Typography>
          <Typography variant='h6' display='inline'>
            {isLoadingData ? (
              <Skeleton width={50} />
            ) : (
              <span className={classes.contentTypography}> {format(new Date(employeeJoinDate), 'dd/MM/yyyy')} </span>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item sm={3}>
          <Typography variant='h6' color='primary' display='inline'>
            Tanggal Lahir:
          </Typography>
          <Typography variant='h6' display='inline'>
            {isLoadingData ? (
              <Skeleton width={50} />
            ) : (
              <span className={classes.contentTypography}> {format(new Date(employeeDateOfBirth), 'dd/MM/yyyy')}</span>
            )}
          </Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography variant='h6' color='primary' display='inline'>
            Nomor KTP:
          </Typography>
          <Typography variant='h6' display='inline'>
            {isLoadingData ? <Skeleton width={50} /> : <span className={classes.contentTypography}> {employeeIdentityNumber}</span>}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' color='primary' display='inline'>
            Alamat:
          </Typography>
          <Typography variant='h6' display='inline'>
            {isLoadingData ? <Skeleton width={50} /> : <span className={classes.contentTypography}> {employeeAddress}</span>}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.containerContactEmergency}>
        <Grid item sm={3}>
          <Typography variant='h6' color='primary' display='inline'>
            KONTAK DARURAT
          </Typography>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item sm={2}>
            <Typography variant='h6' color='primary' display='inline'>
              Nama:
            </Typography>
            <Typography variant='h6' display='inline'>
              {isLoadingData ? <Skeleton width={50} /> : <span className={classes.contentTypography}> {employeeContactNameEmergency}</span>}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant='h6' color='primary' display='inline'>
              Nomor Kontak:
            </Typography>
            <Typography variant='h6' display='inline'>
              {isLoadingData ? <Skeleton width={50} /> : <span className={classes.contentTypography}> {employeeContactNumberEmergency}</span>}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6' color='primary' display='inline'>
              Relasi:
            </Typography>
            <Typography variant='h6' display='inline'>
              {isLoadingData ? <Skeleton width={50} /> : <span className={classes.contentTypography}> {employeeContactRelationEmergency}</span>}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Berhasil mengubah Karyawan' : 'Gagal'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <CreateEmployeeModal
        open={openCreateEmployeeModal}
        handleCancel={handleCancelCreateEmployee}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        addNewEmployee={addNewEmployee}
        employeeData={employee}
        openCreateEmployee={openCreateEmployee}
        allowancesData={allowances}
        employeeName={employeeName}
        setEmployeeName={setEmployeeName}
        employeeDateOfBirth={employeeDateOfBirth}
        setEmployeeDateOfBirth={setEmployeeDateOfBirth}
        employeeAddress={employeeAddress}
        setEmployeeAddress={setEmployeeAddress}
        employeeNo={employeeNo}
        setEmployeeNumber={setEmployeeNumber}
        employeeJoinDate={employeeJoinDate}
        setEmployeeJoinDate={setEmployeeJoinDate}
        employeeIdentityNumber={employeeIdentityNumber}
        setEmployeeContactNumber={setEmployeeContactNumber}
        employeeContactNameEmergency={employeeContactNameEmergency}
        setEmployeeContactNameEmergency={setEmployeeContactNameEmergency}
        employeeContactNumberEmergency={employeeContactNumberEmergency}
        setEmployeeContactNumberEmergency={setEmployeeContactNumberEmergency}
        employeeContactRelationEmergency={employeeContactRelationEmergency}
        setEmployeeContactRelationEmergency={setEmployeeContactRelationEmergency}
        employeeBasicSalary={employeeBasicSalary}
        setEmployeBasicSalary={setEmployeBasicSalary}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
      />
    </Grid>
  );
};

export default HeaderContent;
