import React, { useState, useEffect, FC, Fragment } from 'react';
import {
  Checkbox,
  Fab,
  Grid,
  IconButton,
  Button,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Paper,
  Popper,
  MenuItem,
  ClickAwayListener,
  Fade,
  FormControl,
  Typography
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { PopperPlacementType } from '@material-ui/core/Popper';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { PRODUCT_SERVICE_BASE_URL } from '../../../../constants/url';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios, { CancelTokenSource } from 'axios';
import { ucWords } from 'utils';
import NumberFormatCustom from 'components/NumberFormatCustom';
import Select from '@material-ui/core/Select';
import { parse } from 'path';
import { identity } from 'lodash';
import { isFirstDayOfMonth } from 'date-fns';

interface Props {
  categories: CategoriesModel[];
  employees: EmployeeModel[];
  addTransactionColumn: TransactionModel[];
  onEditTransaction: boolean;
  isOpenDiscountSelect: boolean;
  setIsOpenDiscountSelect: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddTempTransactionColumn(): void;
  setAddTransactionColumn: React.Dispatch<React.SetStateAction<TransactionModel[]>>;
  handleCalculate(value: string, transaction: TransactionModel[]): void;
  EmployeeJob: any[];
  setEmployeeJob: React.Dispatch<React.SetStateAction<any[]>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  popper: (props: Props) => ({
    zIndex: 99
  }),
  textFieldDiscountFont: {
    fontSize: '13px',
    height: 18,
    width: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  autoCompleteResponsive: {
    width: '100%'
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  partionDiv: {
    padding: theme.spacing(1)
  },
  paddingRow: {
    paddingTop: theme.spacing(2)
  }
}));

const AddTransactionColumn: FC<Props> = props => {
  const classes = useStyles(props);
  let cancelTokenSource: CancelTokenSource;

  const {
    onEditTransaction,
    handleAddTempTransactionColumn,
    categories,
    employees,
    addTransactionColumn,
    setAddTransactionColumn,
    handleCalculate,
    setEmployeeJob,
    EmployeeJob,
    setIsOpenDiscountSelect,
    isOpenDiscountSelect
  } = props;

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;
  const [products, setProducts] = useState<ProductServicesModel[]>([]);
  const [anchorElCalendarPopper, setAnchorElCalendarPopper] = useState<HTMLElement | null>(null);
  const [placementCalendarPopper, setPlacementCalendarPopper] = useState<PopperPlacementType>();
  const [productMerges, setProductMerges] = useState<TempProductMergerModel[]>([]);
  const [CategoryId, setCategoryId] = useState<number>(0);
  const [isProductsMerge, setIsProductsMerge] = useState<boolean[]>([]);
  const [inputValue, setInputValue] = useState<string[]>([]);
  const [barcodeSearch, setBarcodeSearch] = useState<number>(0);

  useEffect(() => {
    const handleEditTransaction = () => {
      addTransactionColumn &&
        addTransactionColumn.map((value, index) => {
          handleCategoryChange(value.CategoryId, index);
          value.categoryNameError = '';
          value.productNameError = '';
          value.employeeNameError = '';
          value.employeeNameTwoError = '';
        });
    };

    if (onEditTransaction) handleEditTransaction();
  }, [setIsOpenDiscountSelect]);

  const handleCategoryChange = async (categoryId: number, categoryNameIndex: number) => {
    const currentAddTransactionColumn = [...addTransactionColumn];
    let categoryName = '';
    categories!.find(x => {
      if (x.id === categoryId) categoryName = x.name;
    });

    currentAddTransactionColumn[categoryNameIndex].CategoryId = categoryId;
    currentAddTransactionColumn[categoryNameIndex].categoryName = categoryName;
    currentAddTransactionColumn[categoryNameIndex].isService = true;

    if (categoryName === 'Product') currentAddTransactionColumn[categoryNameIndex].isService = false;

    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('ci', categoryId.toString());

      return params.toString();
    };

    try {
      cancelTokenSource = axios.CancelToken.source();

      const url = `${PRODUCT_SERVICE_BASE_URL}?${getQueryParams()}`;
      const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
      currentAddTransactionColumn[categoryNameIndex].productsTemp = data.products;
      setProducts(data.products);
    } catch (err) {
      console.log(err);
    }

    setAddTransactionColumn(currentAddTransactionColumn);
    setCategoryId(categoryId);
  };

  const handleProductChange = (product: ProductServicesModel, productNameIndex: number) => {
    if(product) {
      const { id, isProductMerge, ProductMergers } = product;
      if (id) {
        let productId = id;

        const currentAddTransactionColumn = [...addTransactionColumn];
        const currentIsProductsMerge = [...isProductsMerge];
        const currentProductMerges = [...productMerges];
        
        let productName = '';
        let productPrice = 0;

        products!.find(x => {
          if (x.id === productId) {
            productName = x.name;
            productPrice = x.sellingPrice;
          }
        });

        currentAddTransactionColumn[productNameIndex].ProductId = productId;
        currentAddTransactionColumn[productNameIndex].productName = productName;
        currentAddTransactionColumn[productNameIndex].productPrice = productPrice;
        currentAddTransactionColumn[productNameIndex].isProductMerge = isProductMerge;

        currentIsProductsMerge[productNameIndex] = isProductMerge;

        if(ProductMergers && ProductMergers.length > 0) {
          currentProductMerges[productNameIndex] = { ProductMerger: [] };

          currentProductMerges[productNameIndex].ProductMerger = [...ProductMergers];
          
          setProductMerges(currentProductMerges);
        }
        
        setIsProductsMerge(currentIsProductsMerge);
        
        setAddTransactionColumn(currentAddTransactionColumn);
      }
    }
  };

  const handleQtyChange = (qty: number, qtyIndex: number) => {
    const currentAddTransactionColumn = [...addTransactionColumn];

    currentAddTransactionColumn[qtyIndex].qty = qty;

    setAddTransactionColumn(currentAddTransactionColumn);
  };

  const handleEmployeeChange = (employees: EmployeeModel[], employeeNameIndex: number, bulkOfNumber: number, productMerger?: ProductMergerModel) => {
    const currentAddTransactionColumn = [...addTransactionColumn];

    currentAddTransactionColumn[employeeNameIndex].EmployeeId = [];
    currentAddTransactionColumn[employeeNameIndex].EmployeeNumber = [];
    currentAddTransactionColumn[employeeNameIndex].EmployeeDetails = [];
    
    if (bulkOfNumber === 1) {
      currentAddTransactionColumn[employeeNameIndex].EmployeeId = employees.map(value => value.id);
      currentAddTransactionColumn[employeeNameIndex].EmployeeNumber = employees.map(value => value.employeeNumber);
      currentAddTransactionColumn[employeeNameIndex].EmployeeDetailsOne = [...employees];

      const newJob = currentAddTransactionColumn[employeeNameIndex].EmployeeDetailsOne.map(value => {
        if (productMerger) {
          const newEmpJob = { EmployeeSelectedJob: [productMerger] };
          return Object.assign({}, value, newEmpJob);
        }

        return value;
      });
      
      currentAddTransactionColumn[employeeNameIndex].EmployeeDetailsOne = [...newJob];
    } else if (bulkOfNumber === 2) {
      currentAddTransactionColumn[employeeNameIndex].EmployeeId = employees.map(value => value.id);
      currentAddTransactionColumn[employeeNameIndex].EmployeeNumber = employees.map(value => value.employeeNumber);
      currentAddTransactionColumn[employeeNameIndex].EmployeeDetailsTwo = [...employees];

      const newJob = currentAddTransactionColumn[employeeNameIndex].EmployeeDetailsTwo.map(value => {
        if (productMerger) {
          const newEmpJob = { EmployeeSelectedJob: [productMerger] };
          return Object.assign({}, value, newEmpJob);
        }

        return value;
      });

      currentAddTransactionColumn[employeeNameIndex].EmployeeDetailsTwo = [...newJob];
    } else {
      currentAddTransactionColumn[employeeNameIndex].EmployeeId = employees.map(value => value.id);
      currentAddTransactionColumn[employeeNameIndex].EmployeeNumber = employees.map(value => value.employeeNumber);
      currentAddTransactionColumn[employeeNameIndex].EmployeeDetails = [...employees];
    }

    setAddTransactionColumn(currentAddTransactionColumn);
  };

  const handleJobEmployee = (productMerger: ProductMergerModel[], index: number, idEmployee: number) => {
    const currentAddTransactionColumn = [...addTransactionColumn];

    productMerger.map((value, _indexPm) => {
      currentAddTransactionColumn[index].EmployeeDetails.map((_employee, indexEmployee) => {
        if (currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].id == idEmployee) {
          if (
            currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].EmployeeSelectedJob &&
            currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].EmployeeSelectedJob.length > 0
          ) {
            if (currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].EmployeeSelectedJob.filter(t => t.id === value.id).length === 0) {
              currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].EmployeeSelectedJob.push(value);
            }
          } else if (!currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].EmployeeSelectedJob) {
            currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].EmployeeSelectedJob = [];
            currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].EmployeeSelectedJob.push(value);
          } else {
            currentAddTransactionColumn[index].EmployeeDetails[indexEmployee].EmployeeSelectedJob.push(value);
          }
        }
      });
    });

    setAddTransactionColumn(currentAddTransactionColumn);
  };

  const handleCloseTipeDiscount = () => {
    setIsOpenDiscountSelect(false);
  };

  const handleDiscountTypeChange = (discountType: number, discountTypeIndex: number) => {
    const currentAddTransactionColumn = [...addTransactionColumn];
    currentAddTransactionColumn[discountTypeIndex].discountType = discountType;

    setAddTransactionColumn(currentAddTransactionColumn);

    switch (discountType) {
      case 2:
        currentAddTransactionColumn[discountTypeIndex].discountTypeName = 'Nominal';
        break;
      case 1:
        currentAddTransactionColumn[discountTypeIndex].discountTypeName = 'Persen';
        break;
      default:
        currentAddTransactionColumn[discountTypeIndex].discountTypeName = '';
        break;
    }
  };

  const handleDiscountChange = (discount: number, discountIndex: number, discountType: number) => {
    const currentAddTransactionColumn = [...addTransactionColumn];
    let discountAmount = (discount / 100) * currentAddTransactionColumn[discountIndex].productPrice;
    if (currentAddTransactionColumn[discountIndex].discountType == 2) {
      discountAmount = discount;
    }

    currentAddTransactionColumn[discountIndex].discount = discount;
    currentAddTransactionColumn[discountIndex].discountAmount = discountAmount;
    handleDiscountTypeChange(discountType, discountIndex);
    setAddTransactionColumn(currentAddTransactionColumn);
    handleCalculate('', currentAddTransactionColumn);
  };

  const handleSearchProduct = async (q: string) => {
    const cancelTokenSource = axios.CancelToken.source();
    const { data } = await axios.get(PRODUCT_SERVICE_BASE_URL, { cancelToken: cancelTokenSource.token, params: { q: q, ci: CategoryId } });
    setProducts(data.products);
  };

  const handleDeleteNewAddTransactionColumn = (index: number) => {
    const currentAddTransactionColumn = [...addTransactionColumn];
    const currentEmployeeJob = [...EmployeeJob];
    const currentisProductsMerge = [...isProductsMerge];

    currentAddTransactionColumn.splice(index, 1);
    currentEmployeeJob.splice(index, 1);
    currentisProductsMerge.splice(index, 1);
    setIsProductsMerge(currentisProductsMerge);
    setAddTransactionColumn(currentAddTransactionColumn);

    handleCalculate('delete', currentAddTransactionColumn);
  };

  const renderForm = () => {
    let idx: number = 0;

    return addTransactionColumn.map((value, index) => {
      const renderDeleteButton = () => {
        if (!value.new) {
          return (
            <Grid item xs={1} container direction='row' justify='center' alignItems='center'>
              <Tooltip title='Delete' placement='left' onClick={_event => handleDeleteNewAddTransactionColumn(index)}>
                <IconButton size='small'>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          );
        }
      };

      return (
        <Fragment key={index}>
          {index !== 0 && <Grid className={classes.partionDiv}></Grid>}
          <Grid container spacing={3}>
            <Grid item xs={4} key={`categoryName${index}`} className={classes.paddingRow}>
              <TextField
                select
                margin='dense'
                required
                fullWidth
                id='categoryName'
                label='Kategori'
                disabled={!value.new}
                error={value.categoryNameError !== ''}
                placeholder={value.categoryNameError}
                value={value.CategoryId}
                onChange={event => handleCategoryChange(+event.target.value, index)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  }
                }}
                InputLabelProps={{
                  className: classes.textFieldFont,
                  shrink: value.CategoryId === 0 ? false : true
                }}
              >
                <MenuItem key={0} value=''>
                  <em>None</em>
                </MenuItem>
                {categories.map(category => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={5} key={`productName${index}`} className={classes.paddingRow}>
              <Autocomplete
                id='nameProduct'
                size='small'
                disabled={!value.new}
                options={products}
                autoHighlight={true}
                inputValue={inputValue[index] ? inputValue[index] : ''}
                value={products.filter(product => product.id === value.ProductId)[0]}
                onInputChange={(event, newInputValue) => {
                  handleSearchProduct(newInputValue);
                  const currentInputValue = [...inputValue];

                  currentInputValue[index] = newInputValue;

                  if (!newInputValue) {
                    const currentAddTransactionColumn = [...addTransactionColumn];

                    currentAddTransactionColumn[index].ProductId = 0;
                    currentAddTransactionColumn[index].productName = '';
                    currentAddTransactionColumn[index].productPrice = 0;

                    setAddTransactionColumn(currentAddTransactionColumn);

                    return setInputValue(currentInputValue);
                  }

                  if (products.filter(product => product.barcode === newInputValue).length) {
                    currentInputValue[index] = products.filter(product => product.barcode === newInputValue)[0].name
                  }
                  
                  return setInputValue(currentInputValue);
                }}
                getOptionLabel={option => option.name}
                onChange={(_event: any, value: any) => {
                  handleProductChange(value, index)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Item*'
                    margin='dense'
                    variant='outlined'
                    placeholder='Item'
                    InputLabelProps={{
                      className: classes.textFieldFont
                    }}
                  />
                )}
                renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
              />
            </Grid>
            {!value.isService && (
              <Grid item xs={2} key={`qty${index}`} className={classes.paddingRow}>
                <TextField
                  margin='dense'
                  fullWidth
                  id='qty'
                  label='Qty'
                  disabled={!value.new}
                  value={value.qty === 0 ? null : value.qty}
                  onChange={event => handleQtyChange(+event.target.value, index)}
                  variant='outlined'
                  autoComplete='off'
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      thousandSeparator: false
                    }
                  }}
                  InputLabelProps={{
                    className: NumberFormatCustom as any
                  }}
                />
              </Grid>
            )}
            {renderDeleteButton()}
          </Grid>
          <Grid container spacing={3}>
            <Grid
              item
              xs={isProductsMerge[index] ? 4 : 6}
              container
              direction='row'
              justify='center'
              alignItems='center'
              key={`employeeName1${index}`}
            >
              <div className={classes.autoCompleteResponsive}>
                <Autocomplete
                  multiple
                  id='employee1'
                  size='small'
                  disabled={!value.new}
                  options={employees}
                  value={isProductsMerge[index] ? value.EmployeeDetailsOne : value.EmployeeDetails}
                  disableCloseOnSelect
                  getOptionLabel={(option: Select) => option.name && ucWords(option.name)}
                  onChange={(event, value) => {
                    return isProductsMerge[index]
                      ? handleEmployeeChange(value, index, 1, productMerges[index].ProductMerger[0])
                      : handleEmployeeChange(value, index, 0);
                  }}
                  renderOption={(option: Select, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={isProductsMerge[index] ? value.EmployeeDetailsOne.filter(value => value.id === option.id).length ? true : false : value.EmployeeDetails.filter(value => value.id === option.id).length  ? true : false}
                        color='primary'
                        disableRipple
                        className={classes.checkBox}
                      />
                      {ucWords(option.name)}
                    </Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      margin='dense'
                      required
                      fullWidth
                      id='employeeName'
                      label={
                        isProductsMerge[index] && productMerges[index]
                          ? productMerges[index].ProductMerger[0].ProductOriginalAs.name
                          : 'Masukkan Nama Karyawan'
                      }
                      error={value.employeeNameError !== ''}
                      helperText={value.employeeNameError}
                      placeholder='Masukkan Nama Karyawan'
                      value={value.EmployeeId}
                      variant='outlined'
                      autoComplete='off'
                      InputProps={{
                        classes: {
                          input: classes.textFieldFont
                        },
                        ...params.InputProps,
                        type: 'search'
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
            {isProductsMerge[index] && (
              <Grid item xs={4} container direction='row' justify='center' alignItems='center' key={`employeeName2${index}`}>
                <div className={classes.autoCompleteResponsive}>
                  <Autocomplete
                    multiple
                    id='employee2'
                    size='small'
                    disabled={!value.new}
                    options={employees}
                    value={value.EmployeeDetailsTwo}
                    getOptionLabel={(option: Select) => option.name && ucWords(option.name)}
                    onChange={(event, value) => handleEmployeeChange(value, index, 2, productMerges[index].ProductMerger[1])}
                    renderOption={(option: Select, { selected }) => (
                      <Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={value.EmployeeDetailsTwo.filter(value => value.id === option.id).length ? true : false}
                          color='primary'
                          disableRipple
                          className={classes.checkBox}
                        />
                        {ucWords(option.name)}
                      </Fragment>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        margin='dense'
                        required
                        fullWidth
                        id='employeeName2'
                        label={
                          isProductsMerge[index] && productMerges[index]
                            ? productMerges[index].ProductMerger[1].ProductOriginalAs.name
                            : 'Masukkan Nama Karyawan'
                        }
                        error={value.employeeNameTwoError !== ''}
                        helperText={value.employeeNameTwoError}
                        placeholder='Masukkan Nama Karyawan'
                        variant='outlined'
                        autoComplete='off'
                        InputProps={{
                          classes: {
                            input: classes.textFieldFont
                          },
                          ...params.InputProps,
                          type: 'search'
                        }}
                      />
                    )}
                  />
                </div>
              </Grid>
            )}

            <Grid item xs={3} container direction='row' justify='center' alignItems='center' key={`discount${index}`}>
              <div className={classes.autoCompleteResponsive}>
                <Fragment>
                  <TextField
                    margin='dense'
                    fullWidth
                    id='typeDiscount'
                    label='Tipe Diskon'
                    disabled={!value.new}
                    value={value.discountTypeName ? `${value.discountTypeName} - ${Number(value.discount).toLocaleString('en-SG')}` : ''}
                    onClick={event => {
                      setAnchorElCalendarPopper(event.currentTarget);
                      setPlacementCalendarPopper('bottom-end');
                      setIsOpenDiscountSelect(true);
                    }}
                    variant='outlined'
                    autoComplete='off'
                    multiline
                    rowsMax='4'
                    InputProps={{
                      classes: {
                        input: classes.textFieldFont
                      }
                    }}
                    InputLabelProps={{
                      className: classes.textFieldFont
                    }}
                  />

                  <ClickAwayListener
                    onClickAway={event => {
                      // setIsOpenDiscountSelect(false);
                    }}
                  >
                    <Popper
                      open={isOpenDiscountSelect}
                      anchorEl={anchorElCalendarPopper}
                      placement={placementCalendarPopper}
                      className={classes.popper}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            <MenuItem value='1'>
                              {isOpenDiscountSelect ? (
                                <TextField
                                  select
                                  margin='dense'
                                  required
                                  fullWidth
                                  id='discountTypePercent'
                                  label='Percent %'
                                  value={value.discountType === 1 ? value.discount : null}
                                  onChange={event => {
                                    handleDiscountChange(+event.target.value, index, 1);
                                  }}
                                  variant='outlined'
                                  autoComplete='off'
                                  InputProps={{
                                    classes: {
                                      input: classes.textFieldFont
                                    }
                                  }}
                                  InputLabelProps={{
                                    className: classes.textFieldFont
                                  }}
                                  type='number'
                                >
                                  <MenuItem value='10'>10%</MenuItem>
                                  <MenuItem value='15'>15%</MenuItem>
                                  <MenuItem value='20'>20%</MenuItem>
                                  <MenuItem value='25'>25%</MenuItem>
                                  <MenuItem value='30'>30%</MenuItem>
                                  <MenuItem value='40'>40%</MenuItem>
                                  <MenuItem value='50'>50%</MenuItem>
                                </TextField>
                              ) : (
                                <Typography>
                                  {value.discountTypeName} - {value.discount}
                                </Typography>
                              )}
                            </MenuItem>
                            <MenuItem value='2'>
                              {isOpenDiscountSelect ? (
                                <TextField
                                  margin='dense'
                                  required
                                  fullWidth
                                  id='discountTypeNominal'
                                  label='Nominal'
                                  value={value.discountType === 2 ? value.discount : null}
                                  onChange={event => {
                                    if (+event.target.value > 0) {
                                      handleDiscountChange(+event.target.value, index, 2);
                                    }
                                  }}
                                  variant='outlined'
                                  autoComplete='off'
                                  InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                    inputProps: {
                                      thousandSeparator: true,
                                      prefix: 'Rp'
                                    }
                                  }}
                                  InputLabelProps={{
                                    className: NumberFormatCustom as any
                                  }}
                                />
                              ) : (
                                <Typography>
                                  {value.discountTypeName} - {value.discount}
                                </Typography>
                              )}
                            </MenuItem>
                            <Grid item xs={12}>
                              <Button fullWidth variant='contained' color='primary' onClick={handleCloseTipeDiscount}>
                                Selesai
                              </Button>
                            </Grid>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </ClickAwayListener>
                </Fragment>
              </div>
            </Grid>
          </Grid>
        </Fragment>
      );
    });
  };
  return (
    <Fragment>
      {renderForm()}
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Tooltip title='Tambah' placement='right'>
          <Fab size='small' color='primary' aria-label='add' onClick={handleAddTempTransactionColumn}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
    </Fragment>
  );
};

export default AddTransactionColumn;
