import 'date-fns';
import React from 'react';
import { Button, makeStyles, MenuItem, TableRow, TextField, Theme, Tooltip, Switch, withStyles } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import NumberFormatCustom from 'components/NumberFormatCustom';
import BodyCell from 'components/BodyCell';
import clsx from 'clsx';

interface Props {
  isLoadingData: boolean;
  commissionPrice: number;
  commissionData: CommissionModel;
  setCommissionPrice: React.Dispatch<React.SetStateAction<number>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;

  primaryButtonLabel: string;
  customBackground?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  serviceItemTemplateForm: {
    height: 64
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: props.customBackground
  }),
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  buttonContainer: {
    display: 'flex'
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}));

const EditCommissionForm: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { isLoadingData, commissionData } = props;
  const { commissionPrice, setCommissionPrice } = props;

  const { primaryButtonLabel, customBackground, isSubmitting, onSubmit, onCancel } = props;
 
  return (
    <TableRow className={classes.serviceItemTemplateForm}>
      <BodyCell cellWidth='20%' pL='10px' pR='10px' isComponent={true}>
      
      </BodyCell>
      <BodyCell cellWidth='20%' pL='10px' pR='10px' isComponent={true}>
        {isLoadingData ? <Skeleton width={80} /> : commissionData.categoryName}
      </BodyCell>
      <BodyCell cellWidth='15%' pL='10px' pR='10px' isComponent={true}>
        {isLoadingData ? <Skeleton width={80} /> : commissionData.productName}
      </BodyCell>
      <BodyCell cellWidth='15%' pL='10px' pR='10px' isComponent={true}>
        {isLoadingData ? <Skeleton width={80} /> : commissionData.barcode}
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        {isLoadingData ? <Skeleton width={80} /> : <NumberFormat value={commissionData.sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />}
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <TextField
            margin='dense'
            fullWidth
            id='commissionPrice'
            label='Komisi'
            value={commissionPrice  === 0 ? null : commissionPrice}
            onChange={event => setCommissionPrice(+event.target.value)}
            variant='outlined'
            autoComplete='off'
            InputProps={{
              inputComponent: NumberFormatCustom as any ,
              inputProps: {
                 thousandSeparator: true,
                 prefix: 'Rp'
              }
            }}
          />
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <div className={classes.buttonContainer}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={onSubmit} variant='contained' color='secondary' disabled={isSubmitting}>
            {primaryButtonLabel}
          </Button>
        </div>
      </BodyCell>
    </TableRow>
  );
};

export default EditCommissionForm;
