import React, { useState, useEffect, useCallback } from 'react';
import { Theme, makeStyles, Grid, Dialog } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import { TRANSACTION_BASE_URL } from '../../../constants/url';
import CreateTransactionForm from './components/CreateTransactionForm';
import ReportInvoice from './components/ReportInvoice';

interface Props {
  open: boolean;
  employeeJob: EmployeeModel[];
  addNewTransaction(transaction: TransactionDetailModel): void;
  invoiceData: TransactionDetailModel;
  openPrintInvoce: boolean;
  printInvoice: boolean;
  onEditTransaction: boolean;
  setOpenInvoice: React.Dispatch<React.SetStateAction<boolean>>;
  close(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    outline: 'none',
    top: '50%',
    left: '50%',
    borderRadius: 4
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const Transaction: React.FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  let cancelTokenSource: CancelTokenSource;

  const dummyTransactionDetail: TransactionDetailModel = {
    id: 0,
    transactionId: 0,
    createdAt: new Date(),
    paymentMethod: '',
    totalTransaction: 0,
    discount: 0,
    discountType: 0,
    productId: 0,
    tenderAmount: 0,
    notes: '',
    productName: '',
    categoryId: 0,
    categoryName: '',
    employeeId: 0,
    itemProducts: [],
    employeeName: '',
    refId: '',
    customerId: 0,
    customerName: '',
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAllowSave, setAllowSave] = useState<boolean>(true);
  
  const [customerId, setCustomerId] = useState<number>(0);
  const [customerName, setCustomerName] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [tender, setTender] = useState<number>(0);
  const [nameBank, setNameBank] = useState<string>('');
  const [numberBank, setNumberBank] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [transaction, setTransaction] = useState<TransactionDetailModel>(dummyTransactionDetail);
  const [customerNameError, setCustomerNameError] = useState<string>('');
  const [addTransactionColumn, setAddTransactionColumn] = useState<TransactionModel[]>([
    {
      id: 0,
      CategoryId: 0,
      categoryName: '',
      ProductId: 0,
      productName: '',
      productPrice: 0,
      EmployeeId: [],
      EmployeeNumber: [],
      EmployeeDetails: [],
      EmployeeDetailsOne: [],
      EmployeeDetailsTwo:[],
      EmployeeJob: [],
      isProductMerge: false,
      employeeName: '',
      productsTemp: [],
      qty: 1,
      isService: false,
      discount: 0,
      discountAmount: 0,
      discountType: 0,
      discountTypeName: '',
      categoryNameError: '',
      employeeNameError: '',
      productNameError: '',
      employeeNameTwoError: '',
      new: true
    }
  ]);

  const { open, employeeJob, openPrintInvoce, onEditTransaction, setOpenInvoice, printInvoice, addNewTransaction, invoiceData, close, setOpenSnackbar, setSnackbarVarient } = props;
  const { itemProducts } = invoiceData;
  
  const resetEditFormValues = useCallback(() => {
    const { customerId, customerName, notes, tenderAmount, paymentMethod, ItemTransactions } = invoiceData;

    const newTransaction: TransactionModel[] = [];

    const dummyEmployee: EmployeeModel = {
      id: 0,
      PayrollId: 0,
      name: '',
      dateOfBirth: new Date(),
      address: '',
      employeeNumber: '',
      EmployeeNumber: '',
      EmployeeName: '',
      joinDate: new Date(),
      identityNumber: '',
      contactNumber: '',
      contactNameEmergency: '',
      contactNumberEmergency: '',
      contactRelationEmergency: '',
      EmployeeSelectedJob: [],
      isCommissionSet: false,
      basicSalary: 0,
      bankAccount: '',
      createdAt: new Date(),
      updatedAt: new Date()
    };
    
    ItemTransactions && ItemTransactions.map(value => {
      const employeDetails: EmployeeModel[] = [...value.ItemEmployeeTransactions!.map(value => value.Employee ? value.Employee : dummyEmployee)];

      newTransaction.push({
        id: value.id,
        discount: value.discount,
        discountAmount: value.discountAmount,
        discountType: value.discountType,
        discountTypeName: '',
        ProductId: value.ProductMerger ? value.ProductMerger.ProductMergeAs!.id : value.Product!.productId,
        isProductMerge: value.ProductMerger ? true : false,
        productName: value.ProductMerger ? value.ProductMerger.ProductMergeAs!.name : value.Product!.name,
        productPrice: value.ProductMerger ? value.ProductMerger.ProductMergeAs!.sellingPrice : value.Product!.sellingPrice,
        CategoryId: value.Product!.CategoryId,
        categoryName: value.Product!.Category!.name,
        EmployeeId: [],
        EmployeeDetailsOne: [],
        EmployeeDetailsTwo:[],
        EmployeeNumber: [],
        EmployeeDetails: employeDetails,
        EmployeeJob: [],
        employeeName: '',
        productsTemp: [],
        qty: value.qty,
        isService: false
      })
    });
    
    setCustomerId(customerId);
    setCustomerName(customerName);
    setNotes(notes);
    setTender(tenderAmount);
    setPaymentMethod(paymentMethod);
    setAddTransactionColumn(newTransaction);
    setTransaction(invoiceData);
  }, [itemProducts, customerId, customerName, notes, tender, paymentMethod]);

  useEffect(() => {
    if(onEditTransaction || printInvoice) resetEditFormValues();
  }, [resetEditFormValues, employeeJob]);
  
  const handleClose = () => {
    clearFormValue();
    clearFormErrors();
    handleCloseModal();
  };

  const handleCloseModal = () => {
    resetFormValues();
    close();
  };

  const clearFormValue = () => {
    setPaymentMethod('');
    setNotes('');
    setNumberBank('');
    setNameBank('');
    setAddTransactionColumn([
      {
        id: 0,
        CategoryId: 0,
        categoryName: '',
        ProductId: 0,
        productName: '',
        productPrice: 0,
        EmployeeId: [],
        EmployeeNumber: [],
        EmployeeDetails: [],
        EmployeeDetailsOne: [],
        EmployeeDetailsTwo:[],
        isProductMerge: false,
        EmployeeJob:[],
        employeeName: '',
        productsTemp: [],
        qty: 1,
        isService: false,
        discount: 0,
        discountAmount: 0,
        discountType: 0,
        discountTypeName: '',
        categoryNameError: '',
        employeeNameError: '',
        productNameError: '',
        employeeNameTwoError: '',
        new: true
      }
    ]);
    setPaymentMethod('');
    setTender(0);
    setOpenInvoice(false);
    setCustomerId(0);
  };

  const clearFormErrors = () => {
    setCustomerNameError('');
  };

  const resetFormValues = () => {
    clearFormErrors();
    clearFormValue();
  };

  const handleAllowButtonSave = (customerId: number, customerName: string, paymentMethod: string) => {
    setAllowSave(true);
    setCustomerName(customerName);
    if(addTransactionColumn.length > 1 && customerId && paymentMethod) setAllowSave(false);
  }

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!customerId) {
      setCustomerNameError('Masukkan nama Pelanggan');
      ret = false;
    }

    return ret;
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();
      addTransactionColumn.map((value,index) => {
        if(!value.ProductId || value.new)
        addTransactionColumn.splice(index, 1);
      });

      const response = await axios.post(
        `${TRANSACTION_BASE_URL}`,
        {
          CustomerId: customerId,
          notes,
          tender,
          paymentMethod,
          bulkProduct: addTransactionColumn,
          nameBank: nameBank,
          numberBank: numberBank
        },
        { cancelToken: cancelTokenSource.token }
      );

      addNewTransaction(response.data);
      setTransaction(response.data);
      setPaymentMethod(paymentMethod);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      setOpenInvoice(true);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const handleOnPrintInvoice: React.FormEventHandler = async event => {
    history.push({ pathname: `/transactions`});
  };
  
  return (
    <Dialog open={open} onClose={handleClose} scroll={'body'} aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction='column' className={classes.paper}>
        {!openPrintInvoce ? (
          <CreateTransactionForm
            okLabel='KONFIRMASI'
            customerId={customerId}
            setCustomerId={setCustomerId}
            setCustomerName={setCustomerName}
            customerName={customerName}
            notes={notes}
            setNotes={setNotes}
            tender={tender}
            setTender={setTender}
            nameBank={nameBank}
            setNameBank={setNameBank}
            setNumberBank={setNumberBank}
            numberBank={numberBank}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            addTransactionColumn={addTransactionColumn}
            setAddTransactionColumn={setAddTransactionColumn}
            onEditTransaction={onEditTransaction}
            isAllowSave={isAllowSave}
            isSubmitting={isLoading}
            handleAllowButtonSave={handleAllowButtonSave}
            onSubmit={handleOnSubmit}
            onCancel={handleCloseModal}
          />
        ) : (
          <ReportInvoice
            okLabel='PRINT INVOICE'
            addTransactionColumn={addTransactionColumn}
            transaction={transaction}
            paymentMethod={paymentMethod}
            tender={tender}
            notes={notes}
            isSubmitting={isLoading}
            onSubmit={handleOnPrintInvoice}
            onCancel={handleCloseModal}
          />
        )}
      </Grid>
    </Dialog>
  );
};

export default Transaction;
