import React, { FC, Fragment, useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Skeleton from 'react-loading-skeleton';
import { Chip, Avatar, Checkbox, IconButton, makeStyles, TableRow, Tooltip, Theme, Typography, Menu, MenuItem } from '@material-ui/core';
import { format } from 'date-fns';
import { green } from '@material-ui/core/colors';
import axios from 'axios';
import BodyCell from 'components/BodyCell';
import clsx from 'clsx';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import Locale from 'date-fns/esm/locale/id';
import { StandardConfirmationDialog } from 'components/AppDialog';
import NumberFormat from 'react-number-format';
import { GET_DELETE_TRANSACTION } from 'constants/url';
import Transaction from '../../../../../../src/components/InformationContent/Transaction';

interface Props {
  isLoadingData: boolean;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
  transaction: TransactionDetailModel;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  onEditJob: React.MouseEventHandler;
  onViewJob: React.MouseEventHandler;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  addNewTransaction(transaction: TransactionDetailModel): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 64
  },
  tableRowChecked: {
    height: 64,
    backgroundColor: '#F4F4F4'
  },

  newIcon: {
    color: green[500],
    fontSize: 30,
    marginTop: theme.spacing(0.8)
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderRadius: 7
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  editIconForEmptyInvoice: {
    marginLeft: theme.spacing(5.5)
  },
  wrapper: {
    position: 'relative'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  icon: {
    fontSize: 16.3
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column'
  },
  menuAvatar: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px',
    color: '#707070'
  },
  menuList: {
    minHeight: 30
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { isLoadingData, transaction, setSnackbarVarient, setOpenSnackbar, handleSetMessageSuccess, handleSetMessageError, setDelete, index } = props;

  const { transactionId, id, refId, createdAt, paymentMethod, totalTransaction, ItemTransactions, new: isNew } = transaction;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [uniqueNames, setUniqueName] = useState<string[]>([]);
  const [uniqueItems, setUniqueItems] = useState<string[]>([]);
  const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
  const [openCreateUserModal, setOpenCreateUserModal] = useState<boolean>(false);
  const [allowedEdit, setAllowedEdit] = useState<boolean>(false);
  const [openPrintInvoce, setOpenInvoice] = useState<boolean>(false);
  const [onEditTransaction, setOnEditTransaction] = useState<boolean>(false);
  const [jobEmployees, setJobEmployes] = useState<EmployeeModel[]>([]);
  const { addNewTransaction } = props;
  const invoiceData: TransactionDetailModel = transaction;

  const handleOpenEditTransaction = () => {
    setOpenCreateUserModal(true);
    setOnEditTransaction(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateUserModal(false);
  };

  const handleOpenReportInvoice = () => {
    setOpenCreateUserModal(true);
    setOpenInvoice(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const actionWrapper = async (action: () => Promise<void>) => {
    setProcessing(true);

    try {
      await action();
      handleSetMessageSuccess('Successfully deleted a Transaction');
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleSetMessageError('Failed to delete a Transaction');
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setProcessing(false);
  };

  const deleteTransaction: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_TRANSACTION(id));
    });
    setDelete(true);
  };

  useEffect(() => {
    const handleUniqueName = () => {
      let uniqueNames: any[] = [];
      let uniqueItems: any[] = [];
      let uniqueCategories: any[] = [];

      if (ItemTransactions) {
        ItemTransactions.map(itemTransaction => {
          itemTransaction.ItemEmployeeTransactions &&
            itemTransaction.ItemEmployeeTransactions.map(employee => {
              if (!uniqueNames.includes(employee.Employee!.name)) uniqueNames.push(employee.Employee!.name);
            });
          
          if (itemTransaction.ProductMerger && itemTransaction.ProductMerger.ProductMergerId && !uniqueItems.includes(itemTransaction.ProductMerger!.ProductMergeAs!.name!)){
            uniqueItems.push(itemTransaction.ProductMerger!.ProductMergeAs!.name!);
          } else if(!itemTransaction.ProductMerger && itemTransaction && itemTransaction.Product && !uniqueItems.includes(itemTransaction.Product!.name!)) {
            uniqueItems.push(itemTransaction.Product!.name!);
          }
          
          if (!uniqueCategories.includes(itemTransaction.Product!.Category!.name)) uniqueCategories.push(itemTransaction.Product!.Category!.name);
        });
      }
      setUniqueName(uniqueNames);
      setUniqueItems(uniqueItems);
      setUniqueCategories(uniqueCategories);
    };

    const handleAllowedEdit = () => {
      const cre = new Date(format(new Date(createdAt === undefined ? new Date() : createdAt), 'yyyy-MM'));
      const today = new Date(format(new Date(), 'yyyy-MM'));

      setAllowedEdit(cre >= today);
    };

    handleAllowedEdit();
    handleUniqueName();
  }, []);

  return (
    <Fragment>
      <TableRow className={clsx()}>
        <BodyCell cellWidth='15%' pL='10px' pR='10px'>
          {isLoadingData ? <Skeleton width={80} /> : refId}
        </BodyCell>

        <BodyCell cellWidth={'15%'} pL='10px' pR='10px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            `${format(new Date(createdAt === undefined ? new Date() : createdAt), 'cccc, dd/MM/yyyy | HH:mm', { locale: Locale }).toString()}`
          )}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <Typography variant='body2'>
              {uniqueCategories &&
                uniqueCategories.map((uniqueCategory, index) =>
                uniqueCategories[index + 1] ? uniqueCategory + ', ' || <Skeleton width={100} /> : uniqueCategory || <Skeleton width={100} />
                )}
            </Typography>
          )}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <Typography variant='body2'>
              {uniqueItems &&
                uniqueItems.map((uniqueItem, index) =>
                  uniqueItems[index + 1] ? uniqueItem + ', ' || <Skeleton width={100} /> : uniqueItem || <Skeleton width={100} />
                )}
            </Typography>
          )}
        </BodyCell>

        <BodyCell cellWidth='15%' pL='10px' pR='10px'>
          <div className={classes.tableCellInner}>
            {isLoadingData ? (
              <Skeleton circle={true} height={36} width={36} />
            ) : (
              uniqueNames &&
              uniqueNames.map((uniqueName, index) =>
                uniqueNames[index + 1] ? (
                  <Fragment key={index}>
                    <div className={classes.wrapper}>
                      <Avatar className={classes.avatar}>{uniqueName && uniqueName[0].toUpperCase()}</Avatar>
                    </div>
                    <div className={classes.nameTextCell}>
                      <Typography variant='body2'>{uniqueName + ', '} </Typography>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment key={index}>
                    <div className={classes.wrapper}>
                      <Avatar className={classes.avatar}>{uniqueName && uniqueName[0].toUpperCase()}</Avatar>
                    </div>
                    <div className={classes.nameTextCell}>
                      <Typography variant='body2'>{uniqueName}</Typography>
                    </div>
                  </Fragment>
                )
              )
            )}
          </div>
        </BodyCell>
        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : <Typography variant='body2'>{paymentMethod || <Skeleton width={100} />}</Typography>}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
          <Typography variant='body2'>
            {isLoadingData ? (
              <Skeleton width={80} />
            ) : (
              <NumberFormat value={totalTransaction} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /> || <Skeleton width={100} />
            )}
          </Typography>
        </BodyCell>

        <BodyCell cellWidth='5%' pL='1px' isComponent={true}>
          {isLoadingData ? null : (
            <Fragment>
              <Tooltip title={'Print'} placement='top'>
                <IconButton size='small' onClick={handleOpenReportInvoice}>
                  <PrintIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete'} placement='top'>
                <IconButton size='small' onClick={event => setOpenDialog(true)} disabled={!allowedEdit || isProcessing}>
                  <DeleteOutlineIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>
      <StandardConfirmationDialog
        variant={'warning'}
        message={'Data Anda akan dihapus dari sistem. Apakah anda yakin ingin menghapus?'}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={deleteTransaction}
      />
      <Transaction
        open={openCreateUserModal}
        close={handleCloseCreateModal}
        addNewTransaction={addNewTransaction}
        invoiceData={invoiceData}
        openPrintInvoce={openPrintInvoce}
        printInvoice={true}
        onEditTransaction={onEditTransaction}
        setOpenInvoice={setOpenInvoice}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        employeeJob={jobEmployees}
      />
    </Fragment>
  );
};

export default BodyRow;
