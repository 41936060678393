import React, { FC, useState, Fragment, useEffect, useContext, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import { Button, Grid, makeStyles, Theme, Container, Paper, Typography } from '@material-ui/core';
import { PAYROLL_BASE_URL } from 'constants/url';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import PayrollTable from './components/PayrollTable';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    margin: 'auto'
  }
}));

interface Props {
  employee: EmployeeModel;
}

const PayrollContent: FC<Props> = props => {
  useCurrentPageTitleUpdater('');
  const { employee } = props;
  const classes = useStyles();

  const [isSearchingPayroll, setSearchingPayroll] = useState<boolean>(false);
  const [payrolls, setPayrolls] = useState<PayrollModel[]>([]);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const [filterBy, setFilterBy] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);
  const [openCreatePayroll, setOpenCreatePayroll] = useState<boolean>(false);
  const [openEditPayroll, setOpenEditPayroll] = useState<boolean>(false);
  const [currentEditingPayrollIndex, setCurrentEditingPayrollIndex] = useState<number>(0);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [isSearchPayrollError, setSearchPayrollError] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);

  const { currentPageTitle } = useContext(CurrentPageContext);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleCancelCreatePayroll = () => {
    setOpenCreatePayroll(false);
  };

  const addNewPayroll = (payroll: PayrollModel[]) => {
    payroll.map((value, index) => {
      value.new = true;
      value.totalCommissionTemp = 0;
      value.totalDeductionTemp = 0;
      value.basicSalaryTemp = payroll[index - 1] ? payroll[index - 1].basicSalary : 0;
      payrolls.unshift(value);
    });
    
    const currentAddTransactionColumn = payrolls.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id )) === i);
    setPayrolls([...currentAddTransactionColumn]);
    setCount(c => c + 1);
  };

  const deleteIndividualPayroll = (payrollIndex: number) => {
    payrolls.splice(payrollIndex, 1);
    setPayrolls([...payrolls]);
    setCount(c => c - 1);
  };

  const handleOpenEditPayroll = (payrollIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingPayrollIndex(payrollIndex);
    setOpenCreatePayroll(false);
    setOpenEditPayroll(true);
  };

  const handleCancelEditPayroll = () => {
    setOpenEditPayroll(false);
  };

  const updateIndividualPayroll = (updatedPayrollProperties: Partial<PayrollModel>, payrollIndex?: number) => {
    let currentEditingIndex: number;
    if (payrollIndex === undefined) {
      currentEditingIndex = currentEditingPayrollIndex;
    } else {
      currentEditingIndex = payrollIndex;
    }
    setPayrolls(
      payrolls!.map((payroll, index) => {
        if (index !== currentEditingIndex) {
          return payroll;
        }

        return Object.assign({}, payroll, updatedPayrollProperties);
      })
    );
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const handleOpenCreatePayroll = () => {
    setOpenEditPayroll(false);
    setOpenCreatePayroll(true);
  };

  // Search Payroll whenever rowsPerPage, currentPage, queryString, contract, and filterby changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      if (filterBy) {
        params.append('fb', filterBy.toString());
      }

      params.append('ei', employee.id.toString());
      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchPayroll = async () => {
      setSearchingPayroll(true);
      setSearchPayrollError(false);

      try {
        const url = `${PAYROLL_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        let payrollsData: PayrollModel[] = [...data.Payrolls];
        setCount(data.count);
        setPayrolls(payrollsData);
      } catch (err) {
        setSearchPayrollError(true);
      }

      setSearchingPayroll(false);
      setDelete(false);
    };

    searchPayroll();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString, filterBy, columnFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Paper className={classes.paper}>
      <PayrollTable
        isLoadingData={isSearchingPayroll}
        employee={employee}
        payrolls={payrolls}
        count={count}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handleOnSearch={event => performActionAndRevertPage(setQueryString, event)}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        query={query}
        setQuery={setQuery}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setColumnFilter={setColumnFilter}
        columnFilter={columnFilter}
        openCreatePayroll={openCreatePayroll}
        handleCancelCreatePayroll={handleCancelCreatePayroll}
        addNewPayroll={addNewPayroll}
        deleteIndividualPayroll={deleteIndividualPayroll}
        openEditPayroll={openEditPayroll}
        payroll={payrolls[currentEditingPayrollIndex]}
        currentEditingPayrollIndex={currentEditingPayrollIndex}
        handleOpenEditPayroll={handleOpenEditPayroll}
        handleCancelEditPayroll={handleCancelEditPayroll}
        updateIndividualPayroll={updateIndividualPayroll}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
        handleOpenCreatePayroll={handleOpenCreatePayroll}
      />
    </Paper>
  );
};

export default PayrollContent;
