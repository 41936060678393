import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import axios, { CancelTokenSource } from 'axios';
import { Button, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';
import CalendarIcon from '@material-ui/icons/EventNote';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import FilterIcon from '@material-ui/icons/FilterListRounded';
import PositionedPopper from 'components/PositionedPopper';
import { format } from 'date-fns';
import SearchInput from 'components/SearchInput';
import DownloadIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv';
import useDebounce from 'hooks/useDebounce';
import { TRANSACTION_BASE_URL, TRANSACTION_BASE_INFO_URL } from 'constants/url';

interface Props {
  isProcessing: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  transactions: TransactionDetailModel[];
  filterBy: string;
  handleOnSearch: (actionParam: any) => void;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  categories: Select[];
  employees: Select[];
  paymentMethod: Select[];
  columnFilter: ColumnFilter[];
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  checked: number[];
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  count: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerFlex: {
    display: 'flex'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1.5)
  },
  toolbarTable: {
    backgroundColor: '#E7F4F8',
    paddingBottom: theme.spacing(0.9)
  },
  headerColor: {
    color: '#53A0BE'
  },
  icon: {
    fontSize: 20
  },
  leftHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  rightHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1.5)
  },
  exportToCsvButton: {
    textDecoration: 'none'
  }
}));

const ToolBar: FC<Props> = props => {
  const classes = useStyles();
  const [openCalendarPopper, setOpenCalendarPopper] = useState(false);
  const [anchorElCalendarPopper, setAnchorElCalendarPopper] = useState<HTMLElement | null>(null);
  const [placementCalendarPopper, setPlacementCalendarPopper] = useState<PopperPlacementType>();
  const [openCheckboxMenusPopper, setOpenCheckboxMenusPopper] = useState(false);
  const [anchorElCheckboxMenusPopper, setAnchorElCheckboxMenusPopper] = useState<HTMLElement | null>(null);
  const [placementCheckboxMenusPopper, setPlacementCheckboxMenusPopper] = useState<PopperPlacementType>();
  const { isProcessing, categories, count, employees, paymentMethod, checked, setOpenDialog, setMessage } = props;

  const [exportTransaction, setExportTransaction] = useState<ExportTransactionCSV[]>([]);
  const { query, setQuery } = props;
  const { filterBy, setFilterBy } = props;
  const { startDate, setStartDate } = props;
  const { endDate, setEndDate } = props;

  const { columnFilter, setColumnFilter, transactions, handleOnSearch } = props;
  const countChecked = checked.length;

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCheckboxMenusPopper(false);
    setOpenCalendarPopper(!openCalendarPopper);
    setAnchorElCalendarPopper(event.currentTarget);
    setPlacementCalendarPopper('bottom-end');
  };

  const handleMenuListClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarPopper(false);
    setOpenCheckboxMenusPopper(!openCheckboxMenusPopper);
    setAnchorElCheckboxMenusPopper(event.currentTarget);
    setPlacementCheckboxMenusPopper('bottom-end');
  };

  const openBulkDeleteDialog = () => {
    setOpenDialog(true);
    setMessage('Are you sure you want to bulk delete this?');
  };

  const handleSearch = useCallback((searchQuery: string) => {
    handleOnSearch(searchQuery);
  }, []);
  
  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    if (transactions.length === 0) {
      return;
    }
    
    const newTransactions: ExportTransactionCSV[] = [];

    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('fb', 'createdAt');
      params.append('sd', startDate !== null ? format(new Date(startDate), 'yyyy-MM-dd').toString() : '');
      params.append('ed', endDate !== null ? format(new Date(endDate), 'yyyy-MM-dd').toString() : '');

      return params.toString();
    };

    const searchTransaction = async () => {
      
      const url = `${TRANSACTION_BASE_URL}?${getQueryParams()}`;
      const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

      const transactionsData: TransactionDetailModel[] = [...data.transactions];
      
      transactionsData.map((transaction, index) => {

        let uniqueNames: any[] = [];
        let uniqueItems: any[] = [];
        let uniqueCategories: any[] = [];
  
        if (transaction.ItemTransactions) {
          transaction.ItemTransactions.map(itemTransaction => {
            itemTransaction.ItemEmployeeTransactions &&
              itemTransaction.ItemEmployeeTransactions.map(employee => {
                if (!uniqueNames.includes(employee.Employee!.name)) uniqueNames.push(employee.Employee!.name);
              });
            
            if (itemTransaction.ProductMerger && itemTransaction.ProductMerger.ProductMergerId && !uniqueItems.includes(itemTransaction.ProductMerger!.ProductMergeAs!.name!)){
              uniqueItems.push(itemTransaction.ProductMerger!.ProductMergeAs!.name!);
            } else if(!itemTransaction.ProductMerger && itemTransaction && itemTransaction.Product && !uniqueItems.includes(itemTransaction.Product!.name!)) {
              uniqueItems.push(itemTransaction.Product!.name!);
            }
            
            if (!uniqueCategories.includes(itemTransaction.Product!.Category!.name)) uniqueCategories.push(itemTransaction.Product!.Category!.name);
          });
        }
  
        newTransactions.push({
          TransaksiId: transaction.refId,
          HariTanggal: transaction.createdAt,
          Kategori: String(uniqueCategories),
          Item: String(uniqueItems),
          Karyawan: String(uniqueNames),
          MetodePembayaran: transaction.paymentMethod,
          Jumlah: transaction.totalTransaction,
          NamaBank: transaction.nameBank,
          NomorKartu: transaction.numberBank
        });
      })
  
      setExportTransaction(newTransactions);
    };
    
    searchTransaction();
  }, [transactions]);

  const renderLeftHeader = () => {
    return (
      <div className={classes.headerFlex}>
        <div className={classes.otherTextCell}>
          {countChecked !== 0 ? (
            <Typography variant='h5' color='primary' className={classes.headerColor}>
              {`${countChecked} selected`}
            </Typography>
          ) : (
            <div>{renderHeaderLabel()}</div>
          )}
        </div>
      </div>
    );
  };

  const renderHeaderLabel = () => {
    switch (filterBy) {
      case '1':
        return (
          <Typography variant='h5' color='primary'>
            TRANSAKSI HARI INI
          </Typography>
        );
      case '2':
        return (
          <Typography variant='h5' color='primary'>
            TRANSAKSI MINGGU INI
          </Typography>
        );
      case '3':
        return (
          <Typography variant='h5' color='primary'>
            TRANSAKSI BULAN INI
          </Typography>
        );
      case '4':
        return (
          <Fragment>
            <Typography variant='h5' color='primary'>
              Pilih tanggal
            </Typography>
            <Typography variant='h6' color='primary'>
              (dari tanggal {format(startDate !== null ? new Date(startDate) : new Date(), 'dd/MM/yyyy')} - {format(endDate !== null ? new Date(endDate) : new Date(), 'dd/MM/yyyy')})
            </Typography>
          </Fragment>
        );
      default:
        return (
          <Typography variant='h5' color='primary'>
            TRANSAKSI HARI INI
          </Typography>
        );
    }
  };

  const renderExportButton = () => {
    if (transactions.length === 0) {
      return (
        <Button variant='outlined' color='primary' disabled={true}>
          <DownloadIcon /> EXPORT .CSV
        </Button>
      );
    } else {
      return (
        <CSVLink data={exportTransaction} separator={','} filename={'Transaksi list.csv'} className={classes.exportToCsvButton}>
          <Button variant='outlined' color='primary'>
            <DownloadIcon /> EXPORT .CSV
          </Button>
        </CSVLink>
      );
    }
  };

  const renderRightHeader = () => {
    if (countChecked !== 0) {
      return (
        <Tooltip title='Delete' placement='top'>
          <IconButton size='small' onClick={openBulkDeleteDialog} disabled={isProcessing}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Fragment>
          <SearchInput
            withBorder
            withTransition={false}
            width={150}
            placeHolder='Cari Transaksi...'
            iconColor='#989898'
            tableSearchValue={query}
            setTableSearchValue={setQuery}
          />
          <PositionedPopper
            openPopper={openCalendarPopper}
            setOpenPopper={setOpenCalendarPopper}
            anchorEl={anchorElCalendarPopper}
            placement={placementCalendarPopper}
            containerWidth={200}
            fadeTransition={350}
            popperComponent='dateRangePicker'
            options={[
              { key: '1', label: 'Hari ini' },
              { key: '2', label: 'Minggu ini' },
              { key: '3', label: 'Bulan ini' },
              { key: '4', label: 'Pilih Tanggal' }
            ]}
            filterBy={filterBy ? filterBy : '1'}
            setFilterBy={setFilterBy}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <PositionedPopper
            openPopper={openCheckboxMenusPopper}
            setOpenPopper={setOpenCheckboxMenusPopper}
            anchorEl={anchorElCheckboxMenusPopper}
            placement={placementCheckboxMenusPopper}
            containerWidth={300}
            fadeTransition={350}
            popperComponent='checkBoxMenus'
            options={[
              { key: 'employeeId', label: 'Karyawan', checkBoxList: employees },
              { key: 'categoryId', label: 'Kategory', checkBoxList: categories },
              { key: 'paymentMethodId', label: 'Metode Pembayaran', checkBoxList: paymentMethod }
            ]}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
          />

          <Tooltip title='Filter Kalender ' placement='top'>
            <IconButton size='small' onClick={event => handleCalendarFilterClick(event)}>
              <CalendarIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Column filter' placement='top'>
            <IconButton size='small' onClick={event => handleMenuListClick(event)}>
              <FilterIcon className={classes.icon} />
            </IconButton>
          </Tooltip>

          {renderExportButton()}
        </Fragment>
      );
    }
  };

  let isChecked;
  if (countChecked === 0) {
    isChecked = false;
  } else {
    isChecked = true;
  }

  return (
    <Grid container spacing={2} className={clsx({ [classes.toolbarTable]: isChecked })}>
      <Grid item xs={6}>
        <Grid container direction='row' justify='flex-start' alignItems='center' className={classes.leftHeader}>
          {renderLeftHeader()}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction='row' justify='flex-end' alignItems='center' className={classes.rightHeader}>
          {renderRightHeader()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToolBar;
