import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import useDebounce from 'hooks/useDebounce';
import SearchInput from 'components/SearchInput';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  isProcessing: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterBy: string;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  columnFilter: ColumnFilter[];
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  handleOnSearch: (actionParam: any) => void;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  openEditProductService: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerFlex: {
    display: 'flex'
  },
  addButton: {
    color: '#fff',
    backgroundColor: '#53A0BE',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  extendedIcon: {
    paddingRight: theme.spacing(1)
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1.5)
  },
  toolbarTable: {
    paddingBottom: theme.spacing(0.9)
  },
  headerColor: {
    color: '#53A0BE'
  },
  icon: {
    fontSize: 20
  },
  filterIcon: (props: Props) => ({
    fontSize: 20,
    color: props.columnFilter.length > 0 ? theme.palette.primary.main : ''
  }),
  leftHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  rightHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1.5)
  }
}));
const ToolBar: FC<Props> = props => {
  const classes = useStyles(props);


  const { openEditProductService, handleOnSearch } = props;
  const { query, setQuery } = props;
  const [color, setColor] = useState("white");

  // Load Product / Service data if search not empty and populate on search list
  const handleSearch = useCallback((searchQuery: string) => {
    handleOnSearch(searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  const renderLeftHeader = () => {
    return (
      <Fragment>
        <SearchInput
          withBorder
          withTransition={false}
          width={150}
          placeHolder='Cari Produk / Servis'
          iconColor='#989898'
          tableSearchValue={query}
          setTableSearchValue={setQuery}
        />
      </Fragment>
    );
  };

  const handleMouseEnter = () =>{
    setColor("#53a0be");
  };

  const handleMouseLeave = () =>{
    setColor("white");
  };

  const renderRightHeader = () => {

    return (
      <Fragment>
        <Button color='primary' size='medium' variant='outlined' className={classes.addButton} onClick={openEditProductService} 
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         style={{
           color: color
         }}>
          <AddIcon className={classes.extendedIcon} />
          Tambah Baru
        </Button>
      </Fragment>
    );
  };

  return (
    <Grid container spacing={8} className={classes.toolbarTable}>
      <Grid item xs={6}>
        <Grid container direction='row' justify='flex-start' alignItems='center' className={classes.leftHeader}>
          {renderLeftHeader()}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction='row' justify='flex-end' alignItems='center' className={classes.rightHeader}>
          {renderRightHeader()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToolBar;
