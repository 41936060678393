const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgotpassword`;
export const RESET_PASSWORD_URL = `${BASE_URL}/resetpassword`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/changepassword`;

export const USER_BASE_URL = `${BASE_URL}/users`;
export const GET_CURRENT_USER_URL = `${USER_BASE_URL}/current`;
export const GET_EDIT_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_DEACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_ACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}/activate`;

export const CUSTOMER_BASE_URL = `${BASE_URL}/customer`;

export const TRANSACTION_BASE_URL = `${BASE_URL}/transactions`;
export const TRANSACTION_BASE_INFO_COLUMNFILTER_URL = `${TRANSACTION_BASE_URL}/columnfilter`;
export const TRANSACTION_BASE_INFO_URL = `${TRANSACTION_BASE_URL}/global/info`;
export const GET_DETAIL_BY_EMPLOYEE = (employeeId: number) => `${TRANSACTION_BASE_URL}/${employeeId}`;
export const GET_DELETE_TRANSACTION = (transactionId: number) => `${TRANSACTION_BASE_URL}/${transactionId}`;

export const EMPLOYEE_BASE_URL = `${BASE_URL}/employees`;
export const EMPLOYEE_GLOBAL_SEARCH_URL = `${EMPLOYEE_BASE_URL}/globalsearch`;
export const GET_EMPLOYEE_BY_ID_URL = (employeeId?: string) => `${EMPLOYEE_BASE_URL}/${employeeId}`;

export const PRODUCT_SERVICE_BASE_URL = `${BASE_URL}/products`;
export const GET_EDIT_PRODUCT_URL = (productId: number) => `${PRODUCT_SERVICE_BASE_URL}/${productId}`;
export const GET_DELETE_VEHICLE_URL = (productId: number) => `${PRODUCT_SERVICE_BASE_URL}/${productId}`;

export const BANK_NAME_BASE_URL = `${BASE_URL}/bankname`;

export const PRODUCT_MERGER_BASE_URL= `${BASE_URL}/product-merger`;

export const CATEGORIES_SERVICE_BASE_URL = `${BASE_URL}/categories`;

export const EMPLOYEE_SERVICE_BASE_URL = `${BASE_URL}/employees`;
export const GET_DELETE_EMPLOYEE = (employeeId: number) => `${EMPLOYEE_SERVICE_BASE_URL}/${employeeId}`;
export const GET_EDIT_EMPLOYEE_URL = (employeeId: number) => `${EMPLOYEE_SERVICE_BASE_URL}/${employeeId}`;
export const GET_DETAIL_EMPLOYEE = (employeeId?: string) => `${EMPLOYEE_SERVICE_BASE_URL}/${employeeId}`;


export const COMMISSION_SERVICE_BASE_URL = `${BASE_URL}/commissions`;

export const HISTORY_TRANSACTION_BASE_URL = `${BASE_URL}/history_transaction`;

export const JOB_BASE_URL = `${BASE_URL}/jobs`;
export const JOB_BASE_INFO_URL = `${JOB_BASE_URL}/infojob`;

export const PAYROLL_BASE_URL = `${BASE_URL}/payrolls`;
export const CREATE_PAYROLL_BASE_URL = `${BASE_URL}/payrolls`;
export const GET_EDIT_PAYROLL_URL = (payrollId: number) => `${CREATE_PAYROLL_BASE_URL}/${payrollId}`;
export const GET_DETAIL_PAYROLL_BY_ID = (id: number) => `${PAYROLL_BASE_URL}/${id}`;

export const CREATE_COMMISSION_BASE_URL = `${BASE_URL}/commissions`;
export const CREATE_COMMISSION_MULTIPLE_SELECT_BASE_URL = (commissionId: number[]) =>  `${CREATE_COMMISSION_BASE_URL}/multiple/${commissionId}`;
export const COMMISSION_BASE_URL = (commissionId: number[]) =>  `${CREATE_COMMISSION_BASE_URL}/[${commissionId}]`;

export const GET_DELETE_COMMISSION_URL = (commissionId: number[]) => `${CREATE_COMMISSION_BASE_URL}/${commissionId}`;
export const ALLOWANCE_DEDUCTION_BASE_URL = `${BASE_URL}/allowance-deduction`;
export const GET_DETAIL_ALLOWANCE_DEDUCTION = (employeeId?: string) => `${ALLOWANCE_DEDUCTION_BASE_URL}/${employeeId}`;