import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, TextField, DialogContent, Button, Theme, withStyles, Typography, Divider } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  okLabel: 'Simpan' | 'Ubah';

  employeeName: string;
  dateOfBirth: Date | null;
  address: string;
  identityNumber: string;
  joinDate: Date | null;
  contactNumber: string;
  allowancePosition: number;
  allowanceTransport: number;
  allowanceCraft: number;
  allowanceMeal: number;
  allowanceHealth: number;
  allowanceHoliday: number;
  contactNameEmergency: string;
  contactNumberEmergency: string;
  contactRelationEmergency: string;

  nameError: string;
  dateOfBirthError: string;
  addressError: string;
  identityNumberError: string;
  joinDateError: string;
  contactNumberError: string;
  contactNameEmergencyError: string;
  contactNumberEmergencyError: string;
  contactRelationEmergencyError: string;

  setName: React.Dispatch<React.SetStateAction<string>>;
  setDateOfBirth: React.Dispatch<React.SetStateAction<Date | null>>;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  setIdentityNumber: React.Dispatch<React.SetStateAction<string>>;
  setJoinDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;
  setAllowancePosition: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceTransporty: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceCraft: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceMeal: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceHealth: React.Dispatch<React.SetStateAction<number>>;
  setAllowanceHoliday: React.Dispatch<React.SetStateAction<number>>;
  setContactNameEmergency: React.Dispatch<React.SetStateAction<string>>;
  setContactNumberEmergency: React.Dispatch<React.SetStateAction<string>>;
  setContactRelationEmergency: React.Dispatch<React.SetStateAction<string>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const AddButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    right:135
  },
  addButton: {
    color: '#FFFFFF',
    right:135
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#53a0be',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  headerModalText: {
    textAlign: 'left',
    color: '#53A0BE'
  },
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  TextInfo: {
    textAlign: 'right'
  },
  radioTypo: {
    marginTop: theme.spacing(2),
    textTransform: 'uppercase'
  }
}));

const EmployeeForm: React.FC<Props> = props => {
  const classes = useStyles();

  const { employeeName, setName, nameError } = props;
  const { dateOfBirth, setDateOfBirth, dateOfBirthError } = props;
  const { address, setAddress, addressError } = props;
  const { identityNumber, setIdentityNumber, identityNumberError } = props;
  const { joinDate, setJoinDate, joinDateError } = props;
  const { contactNumber, setContactNumber, contactNumberError } = props;
  const { allowancePosition, setAllowancePosition } = props;
  const { allowanceTransport, setAllowanceTransporty } = props;
  const { allowanceCraft, setAllowanceCraft } = props;
  const { allowanceMeal, setAllowanceMeal } = props;
  const { allowanceHealth, setAllowanceHealth } = props;
  const { allowanceHoliday, setAllowanceHoliday } = props;
  
  const { contactNameEmergency, setContactNameEmergency, contactNameEmergencyError } = props;
  const { contactNumberEmergency, setContactNumberEmergency, contactNumberEmergencyError } = props;
  const { contactRelationEmergency, setContactRelationEmergency, contactRelationEmergencyError } = props;

  const { isSubmitting, onSubmit, onCancel, okLabel } = props;

  const handleDateOfBirthChange = (date: Date | null) => {
    setDateOfBirth(date);
  };

  const handleJoinDateChange = (date: Date | null) => {
    setJoinDate(date);
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <DialogContent>
        <Grid className={classes.contentGrid}>
          <Typography variant='h5' id='modal-title' className={classes.headerModalText}>
            Input Data Karyawan
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                margin='dense'
                fullWidth
                id='name'
                label='Nama'
                value={employeeName}
                onChange={event => setName(event.target.value)}
                variant='outlined'
                autoComplete='off'
                multiline
                error={nameError !== ''}
                rowsMax='4'
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  }
                }}
                InputLabelProps={{
                  className: classes.textFieldFont
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  required
                  fullWidth
                  autoOk
                  allowKeyboardControl
                  margin='dense'
                  id='dateOfBirth'
                  label='Tanggal Lahir'
                  value={dateOfBirth}
                  variant='inline'
                  inputVariant='outlined'
                  format='dd MMMM yyyy'
                  onChange={handleDateOfBirthChange}
                  error={dateOfBirthError !== ''}
                  InputProps={{
                    classes: {
                      input: classes.textFieldFont
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin='dense'
              required
              fullWidth
              id='address'
              label='Alamat'
              value={address}
              onChange={event => setAddress(event.target.value)}
              error={addressError !== ''}
              variant='outlined'
              autoComplete='off'
              multiline
              rowsMax='4'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                margin='dense'
                fullWidth
                id='identityNumber'
                label='No KTP'
                value={identityNumber}
                onChange={event => setIdentityNumber(event.target.value)}
                error={identityNumberError !== ''}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  }
                }}
                InputLabelProps={{
                  className: classes.textFieldFont
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  allowKeyboardControl
                  margin='dense'
                  id='joinDate'
                  label='Tanggal Bergabung'
                  value={joinDate}
                  variant='inline'
                  inputVariant='outlined'
                  format='dd MMMM yyyy'
                  onChange={handleJoinDateChange}
                  error={joinDateError !== ''}
                  InputProps={{
                    classes: {
                      input: classes.textFieldFont
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='contactNumber'
                label='Nomor Kontak'
                value={contactNumber}
                onChange={event => setContactNumber(event.target.value)}
                error={contactNumberError !== ''}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  }
                }}
                InputLabelProps={{
                  className: classes.textFieldFont
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Typography variant='h5' id='modal-title' className={classes.headerModalText}>
            Gaji/Tunjangan
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowancePosition'
                label='Jabatan'
                value={allowancePosition === 0 ? null : allowancePosition}
                onChange={event => setAllowancePosition(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceTransport'
                label='Transport'
                value={allowanceTransport === 0 ? null : allowanceTransport}
                onChange={event => setAllowanceTransporty(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceCraft'
                label='Kerajinan'
                value={allowanceCraft === 0 ? null : allowanceCraft}
                onChange={event => setAllowanceCraft(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceMeal'
                label='Makan'
                value={allowanceMeal === 0 ? null : allowanceMeal}
                onChange={event => setAllowanceMeal(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceHealth'
                label='Kesehatan'
                value={allowanceHealth === 0 ? null : allowanceHealth}
                onChange={event => setAllowanceHealth(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
                multiline
                rowsMax='4'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='allowanceHoliday'
                label='THR'
                value={allowanceHoliday === 0 ? null : allowanceHoliday}
                onChange={event => setAllowanceHoliday(+event.target.value)}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
                InputProps={{
                  inputComponent: NumberFormatCustom as any ,
                  inputProps: {
                     thousandSeparator: true,
                     prefix: 'Rp'
                  }
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Typography variant='h5' id='modal-title' className={classes.headerModalText}>
            Kontak Darurat
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='contactNameEmergency'
                label='Nama'
                value={contactNameEmergency}
                onChange={event => setContactNameEmergency(event.target.value)}
                error={contactNameEmergencyError !== ''}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                fullWidth
                id='contactNumberEmergency'
                label='Nomor Kontak'
                value={contactNumberEmergency}
                onChange={event => setContactNumberEmergency(event.target.value)}
                error={contactNumberEmergencyError !== ''}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  }
                }}
                InputLabelProps={{
                  className: classes.textFieldFont
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                fullWidth
                id='contactRelationEmergency'
                label='Relasi'
                value={contactRelationEmergency}
                onChange={event => setContactRelationEmergency(event.target.value)}
                error={contactRelationEmergencyError !== ''}
                variant='outlined'
                autoComplete='off'
                multiline
                rowsMax='4'
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
            Batal
          </Button>
          <AddButton type='submit' variant='contained' color='primary' className={classes.addButton} disabled={isSubmitting}>
            {okLabel}
          </AddButton>
        </Grid>
      </DialogContent>
    </form>
  );
};

export default EmployeeForm;
