import React, { FC, Fragment, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';
import { Tooltip, IconButton, makeStyles, TableRow, Theme, Typography, Checkbox } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { green } from '@material-ui/core/colors';
import BodyCell from 'components/BodyCell';
import { StandardConfirmationDialog } from 'components/AppDialog';
import axios from 'axios';
import { GET_DELETE_VEHICLE_URL } from 'constants/url';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import clsx from 'clsx';
import { CategoryOutlined } from '@material-ui/icons';

interface Props {
  isLoadingData: boolean;
  productService: ProductServicesModel;
  openEditProductService: boolean;
  updateProductService: (updatedProductServiceProperties: Partial<ProductServicesModel>, productIndex?: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageError: (message: string) => void;
  index: number;
  deleteIndividualProductService: (productIndex: number) => void;
  onEditProductService: React.MouseEventHandler;
  handleSetMessageSuccess: (message: string) => void;
  checked: number[];
  handleIndividualCheck: (id: number, name: string, CategoryId: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 64
  },
  tableRowChecked: {
    height: 64,
    backgroundColor: '#F4F4F4'
  },
  actionIcon: {
    fontSize: 20
  },
  newIcon: {
    color: green[500],
    fontSize: 30,
    marginTop: theme.spacing(0.8)
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderRadius: 7
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  editIconForEmptyInvoice: {
    marginLeft: theme.spacing(5.5)
  },
  wrapper: {
    position: 'relative'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  icon: {
    fontSize: 20
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  },
  otherTextCell: {
    display: 'flex',
    flexDirection: 'column'
  },
  menuAvatar: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px',
    color: '#707070'
  },
  menuList: {
    minHeight: 30
  }
}));

  const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    productService,
    onEditProductService,
    handleSetMessageSuccess,
    handleSetMessageError,
    setOpenSnackbar,
    setSnackbarVarient,
    deleteIndividualProductService,
    checked,
    handleIndividualCheck,
    index
  } = props;
  
  const { id, categoryName, Category, CategoryId, name, barcode, sellingPrice, notes, new: isNew } = productService;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>, actionStatus: string) => {
    setProcessing(true);

    try {
      await action();
      handleSetMessageSuccess(`Berhasil ${actionStatus} produk & service!`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleSetMessageError(`Gagal menghapus ${actionStatus}`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setProcessing(false);
  };

  const deleteProductService: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_VEHICLE_URL(id));
    }, 'hapus');
    deleteIndividualProductService(index);
  };


  const checkedCheckbox = checked.filter(checkedValue => checkedValue === id).length;

  let isChecked;

  if (checkedCheckbox === 0) {
    isChecked = false;
  } else {
    isChecked = true;
  }
  
  return (
    <Fragment>
        <TableRow className={clsx({ [classes.tableRow]: !isChecked, [classes.tableRowChecked]: isChecked })}>

        <BodyCell cellWidth='5%' pL='10px' isComponent={true}>
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
            checkedIcon={<CheckBoxIcon className={classes.checkBoxSize} />}
            edge='start'
            color='primary'
            className={classes.checkBox}
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            onChange={event => handleIndividualCheck && handleIndividualCheck(id, name, CategoryId)}
          />
        </BodyCell>   

        <BodyCell cellWidth='15%' pL='10px'>
          {isLoadingData ? <Skeleton width={80} /> : Category && Category.name}
        </BodyCell>

        <BodyCell cellWidth='15%' pR='5px'>
        {isLoadingData ? <Skeleton width={80} /> : name}
        </BodyCell>

        <BodyCell cellWidth={'11%'} pL='10px' pR='10px'>
          {isLoadingData ? <Skeleton width={80} /> : barcode}
        </BodyCell>
        
        <BodyCell cellWidth={'9%'} pL='10px' pR='10px'>
        {isLoadingData ? <Skeleton width={80} /> : <NumberFormat value={sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />}
        </BodyCell>
       
        <BodyCell cellWidth='15%' pR='5px'>
          {isLoadingData ? <Skeleton width={80} /> : notes}
        </BodyCell>

        <BodyCell cellWidth='15%' pL='5px' isComponent={true}>
          {isLoadingData ? null : (
            <Fragment>
              <Tooltip title={'Edit'} placement='top'>
                <IconButton size='small' onClick={onEditProductService}>
                  <EditIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete'} placement='top'>
                <IconButton size='small' onClick={event => setOpenDialog(true)}>
                  <DeleteIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>
      <StandardConfirmationDialog
        variant={'warning'}
        message={'Anda yakin mau menghapus ini?'}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={deleteProductService}
      />
    </Fragment>
  );
};

export default BodyRow;


