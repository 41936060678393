import React, { FC, Fragment, useState, useContext, useEffect, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import { Button, Divider, Grid, makeStyles, Paper, Theme, Typography, Container } from '@material-ui/core';
import EmployeeGrid from './components/EmployeeGrid';
import { EMPLOYEE_SERVICE_BASE_URL } from 'constants/url';
import PaginationSection from './components/PaginationSection';
import ActionSnackbar from 'components/ActionSnackBar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import CreateEmployeeModal from './components/CreateEmployeeModal';


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    margin: 'auto'
  },
  extendedIcon: {
    paddingRight: theme.spacing(1)
  },
  addButton: {
    color: '#fff',
    backgroundColor: '#53A0BE',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));


const dummyEmployee: EmployeeModel = {
  id: 0,
  PayrollId: 0,
  name: '',
  dateOfBirth: new Date(),
  address: '',
  employeeNumber: '',
  EmployeeNumber: '',
  EmployeeName: '',
  joinDate: new Date(),
  identityNumber: '',
  contactNumber: '',
  contactNameEmergency: '',
  contactNumberEmergency: '',
  contactRelationEmergency: '',
  EmployeeSelectedJob: [],
  isCommissionSet: false,
  basicSalary: 0,
  bankAccount: '',
  createdAt: new Date(),
  updatedAt: new Date()
};

const dummyAllowance: AllowanceDeductionModel[] = [{
  id: 0,
  EmployeeId: 0,
  name: '',
  amount: 0,
  notes: '',
  isAllowance: false,
  createdAt: new Date()
}];

const Employee: FC = () => {
  useCurrentPageTitleUpdater('Karyawan');

  const classes = useStyles();

  const [employeeName, setEmployeeName] = useState<string>('');
  const [employeeDateOfBirth, setEmployeeDateOfBirth] = useState<Date>(new Date());
  const [employeeAddress, setEmployeeAddress] = useState<string>('');
  const [employeeNo, setEmployeeNumber] = useState<string>('');
  const [employeeJoinDate, setEmployeeJoinDate] = useState<Date>(new Date());
  const [employeeIdentityNumber, setEmployeeIdentityNumber] = useState<string>('');
  const [employeeContactNumber, setEmployeeContactNumber] = useState<string>('');
  const [employeeContactNameEmergency, setEmployeeContactNameEmergency] = useState<string>('');
  const [employeeContactNumberEmergency, setEmployeeContactNumberEmergency] = useState<string>('');
  const [employeeContactRelationEmergency, setEmployeeContactRelationEmergency] = useState<string>('');
  const [employeeBasicSalary, setEmployeBasicSalary] = useState<number>(0);

  const [isSearchingEmployee, setSearchingEmployee] = useState<boolean>(false);

  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [filterBy, setFilterBy] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(6);
  const [count, setCount] = useState<number>(0);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [isSearchEmployeeError, setSearchEmployeeError] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [openCreateEmployee, setOpenCreateEmployee] = useState<boolean>(false);

  const [openCreateEmployeeModal, setOpenCreateEmployeeModal] = useState<boolean>(false);

  const [employees, setEmployees] = useState<EmployeeModel[]>([]);

  const handleOpenCreateEmployee = () => {
    setOpenCreateEmployeeModal(true); 
    setOpenCreateEmployee(true);
  };

  const handleCancelCreateEmployee = () => {
    setOpenCreateEmployeeModal(false);
    setOpenCreateEmployee(false);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isLoadingData) {
      timeout = setTimeout(() => {
        setShowSkeleton(true);
      }, 500);
    }

    setShowSkeleton(false);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoadingData]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Search Employee whenever rowsPerPage, currentPage, queryString, contract, and filterby changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      if (filterBy) {
        params.append('fb', filterBy.toString());
      }

      if (columnFilter.length !== 0) {
        columnFilter.map(value => {
          if (value.columnName === 'clientId') {
            return params.append('ci', value.columnValue.toString());
          } else {
            return params.append('ei', value.columnValue.toString());
          }
        });
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchEmployee = async () => {
      setSearchingEmployee(true);
      setSearchEmployeeError(false);

      try {
        const url = `${EMPLOYEE_SERVICE_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        let employeesData: EmployeeModel[] = [...data.Employees];
        setCount(data.count);
        setEmployees(employeesData);
      } catch (err) {
        setSearchEmployeeError(true);
      }

      setSearchingEmployee(false);
      setDelete(false);
    };

    searchEmployee();
  }, [rowsPerPage, currentPage, columnFilter]);

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const deleteIndividualEmployee = (employeId: number) => {
    employees.splice(employeId, 1);
    setEmployees([...employees]);
    setCount(c => c - 1);
  };

  const addNewEmployee = (employee: EmployeeModel) => {
    employee.new = true;
    employees.unshift(employee);
    setEmployees([...employees]);
    setCount(c => c + 1);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderContent = () => {
    if (showSkeleton) {
      return [1, 2, 3, 4, 5, 6].map(index => {
        return (
          <EmployeeGrid
            employee={dummyEmployee}
            index={index}
            key={index}
            isLoadingData={isLoadingData}
            setIsLoadingData={setIsLoadingData}
            setMessage={setMessage}
            handleSetMessageSuccess={handleSetMessageSuccess}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarVarient={setSnackbarVarient}
            handleSetMessageError={handleSetMessageError}
            deleteIndividualEmployee={deleteIndividualEmployee}
          />
        );
      });
    } else {
      if (!count)
        return (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant='body1' color='textSecondary' align='left'>
                Tidak ada data
              </Typography>
            </Paper>
          </Grid>
        );

      return employees.map((employee, index) => {
        return (
          <EmployeeGrid
            employee={employee}
            index={index}
            key={employee.id}
            isLoadingData={isSearchingEmployee}
            setIsLoadingData={setIsLoadingData}
            setMessage={setMessage}
            handleSetMessageSuccess={handleSetMessageSuccess}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarVarient={setSnackbarVarient}
            handleSetMessageError={handleSetMessageError}
            deleteIndividualEmployee={deleteIndividualEmployee}
          />
        );
      });
    }
  };

  const [color, setColor] = useState("white");
  
  const handleMouseEnter = () =>{
    setColor("#53a0be");
  };

  const handleMouseLeave = () =>{
    setColor("white");
  };
 
  const renderCreateButton = () => {
    return (
      <Button color='primary' size='medium' variant='outlined' className={classes.addButton} onClick={handleOpenCreateEmployee} 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        color: color
      }}>
        <AddIcon className={classes.extendedIcon} />
          Tambah Baru
      </Button>
    );
  };

  return (
    <Fragment>
      <Grid container item xs={12} spacing={3} direction='row' justify='flex-end' alignItems='center'>
        {renderCreateButton()}
      </Grid>
        <Grid container spacing={4}>
          {renderContent()}
        </Grid>
        <PaginationSection
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={count}
        />
        <ActionSnackbar
          variant={snackbarVarient}
          message={snackbarVarient === 'success' ? messageSuccess : messageError}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
        />
      <CreateEmployeeModal
        open={openCreateEmployeeModal}
        handleCancel={handleCancelCreateEmployee}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        addNewEmployee={addNewEmployee}
        employeeData={dummyEmployee}
        allowancesData={dummyAllowance}
        employeeName={employeeName}
        setEmployeeName={setEmployeeName}
        employeeDateOfBirth={employeeDateOfBirth}
        setEmployeeDateOfBirth={setEmployeeDateOfBirth}
        employeeAddress={employeeAddress}
        setEmployeeAddress={setEmployeeAddress}
        employeeNo={employeeNo}
        setEmployeeNumber={setEmployeeNumber}
        employeeJoinDate={employeeJoinDate}
        setEmployeeJoinDate={setEmployeeJoinDate}
        employeeIdentityNumber={employeeIdentityNumber}
        setEmployeeContactNumber={setEmployeeContactNumber}
        employeeContactNameEmergency={employeeContactNameEmergency}
        setEmployeeContactNameEmergency={setEmployeeContactNameEmergency}
        employeeContactNumberEmergency={employeeContactNumberEmergency}
        setEmployeeContactNumberEmergency={setEmployeeContactNumberEmergency}
        employeeContactRelationEmergency={employeeContactRelationEmergency}
        setEmployeeContactRelationEmergency={setEmployeeContactRelationEmergency}
        employeeBasicSalary={employeeBasicSalary}
        setEmployeBasicSalary={setEmployeBasicSalary}
        openCreateEmployee={openCreateEmployee}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
      />
    </Fragment>
  );
};

export default Employee;
